import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ProductResize from '../../components/product-resize/product-resize.component.vue';
import { ProductService } from '../../product.service';
import ProductPreviewDrawingThumbnail from '../../../../../../../shared/modules/products/components/product-preview-drawing-thumbnail/product-preview-drawing-thumbnail.component.vue';
import { Routes } from '../../../../routes.enum';
import { Product } from '@shared/modules/products/models/product.model';
import { ProductTemplateMailRequest } from '../../models/product-template-mail-request.interface';
import { ProductDrawing } from '@shared/modules/products/models/product-drawing.model';
import ProductPreviewDrawing from '@shared/modules/products/components/product-preview-drawing/product-preview-drawing.component.vue';
import ProductSection from '@shared/modules/products/components/product-section/product-section.component.vue';
import { BreadcrumbHistoryItem } from '@shared/components/breadcrumbs/breadcrumb-history-item.interface';
import Breadcrumbs from '@shared/components/breadcrumbs/breadcrumbs.component.vue';
import CustomInput from '@shared/components/custom-input/custom-input.component.vue';
import LoadingButton from '@shared/components/loading-button/loading-button.component.vue';

@Component({
  components: {
    Breadcrumbs,
    ProductResize,
    ProductPreviewDrawing,
    ProductPreviewDrawingThumbnail,
    ProductSection,
    CustomInput,
    LoadingButton
  },
  beforeRouteEnter(to: any, from: any, next: (vm: any) => void) {
    next((vm: ProductSendResizeTemplate) => {
     vm.fromRoute = from;
    });
  }
})
export default class ProductSendResizeTemplate extends Vue {

  heightOfPreviewWindow = 0;
  fromRoute!: {name: Routes; query: any};
  breadcrumbs: BreadcrumbHistoryItem[] = [];
  resizeParams: any;
  drawing!: ProductDrawing;

  name = '';
  phone = '';
  from = '';
  subject = '';
  body = this.$i18n.t('products.sendResizeTemplate.messageBody');

  private readonly productService = new ProductService();

  get currentProduct(): Product {
    return this.$store.getters.getPreviewOfCurrentProduct;
  }

  async created() {
    if (!this.currentProduct?.id) {
      this.$router.replace({name: Routes.NewRequest});
      return;
    }

    this.initBreadcrumbs();

    this.resizeParams = this.$store.getters.getResizeParams(this.currentProduct.id);
    this.drawing = this.currentProduct.drawings.find(d => d.evDrawingId === this.resizeParams.drawingId) as ProductDrawing;

  }

  async send(stopLoading: () => void) {

    if (this.validate()) {
      const resizeParams = {...this.resizeParams as any};

      delete resizeParams.drawingId;

      const body = `${this.$i18n.t('products.sendResizeTemplate.labels.phone')}:${this.phone} \n ${this.body}`;

      const mailRequest: ProductTemplateMailRequest = {
        name: this.name,
        from: this.from,
        body,
        subject: this.subject
      };

      const {numSucceeded} = await this.$store.dispatch('sendEmail', {
        productId: this.currentProduct.id,
        resizeParams,
        mailRequest
      });

      stopLoading();

      if (numSucceeded > 0) {
        this.setSuccessMessage();
        this.clearResizeParams();
        this.goToHome();
      } else {
        this.setErrorMessage();
      }
    } else {
      stopLoading();
    }
  }

  mounted() {
    this.handlePreviewWindowResize();
    window.addEventListener('resize', this.handlePreviewWindowResize);
  }

  handlePreviewWindowResize() {
    this.heightOfPreviewWindow = window.innerHeight - 224;
  }

  goToCostEstimation() {
    this.$router.push({name: Routes.ProductCostEstimation, params: {id: this.currentProduct.id} as any});
  }

  destroyed() {
    window.removeEventListener('resize', this.handlePreviewWindowResize);
  }

  backToProducts() {
    this.$router.push(this.fromRoute);
  }

  private setErrorMessage() {
    this.$store.dispatch('notification/showError', {text: 'Error'});
  }

  private setSuccessMessage() {
    this.$store.dispatch('notification/showSuccess', {text: this.$i18n.t('products.sendResizeTemplate.messages.sent')});
  }

  private clearResizeParams() {
    this.$store.commit('clearResizeParams');
  }

  private goToHome() {
    this.$router.push({name: Routes.NewRequest});
  }

  private initBreadcrumbs() {

    this.breadcrumbs = [
      {
        plainText: this.currentProduct.name,
        disabled: false,
        to: {
          name: Routes.ProductPreview,
          params: {
            id: this.currentProduct.id
          }
        }
      },
      {
        text: 'products.breadcrumbs.send',
        disabled: true
      }
    ];
  }

  private validate() {
    return (this.$refs.form as any).validate();
  }
}
