import { Component, Inject, Prop, Vue, Watch } from 'vue-property-decorator';
import Pagination from '@shared/components/pagination/pagination.component.vue';
import { Config } from '../../../../config';
import { ProductService } from '../../product.service';
import { Routes } from '../../../../routes.enum';
import { ProductListItem } from '@shared/modules/products/models/product-list-item.model';
import { UtilService } from '@shared/utils/util.service';
import ProductPreviewDrawingThumbnail from '@shared/modules/products/components/product-preview-drawing-thumbnail/product-preview-drawing-thumbnail.component.vue';
import Breadcrumbs from '@shared/components/breadcrumbs/breadcrumbs.component.vue';
import { BreadcrumbHistoryItem } from '@shared/components/breadcrumbs/breadcrumb-history-item.interface';
import { ProductUrlSourceType } from '../../enums/product-url-source-type.enum';
import { Units } from '@shared/modules/diemaker/enums/units-enum';
import UnitsSelect from '../../components/units-select/units-select.component.vue';
import { Diemaker } from '@shared/modules/diemaker/models/diemaker.model';

@Component({
    components: {
        Breadcrumbs,
        Pagination,
        ProductPreviewDrawingThumbnail,
        UnitsSelect
    }
})
export default class ProductFavouriteList extends Vue {

    itemsPerPageArray = [12, 18, 24, 48, 96];
    page = 1;
    searchLabel = this.$i18n.t('products.list.search');
    search = '';
    totalPages = 0;
    totalCount = 0;
    materials: any[] = [];
    selectedMaterial: any = {};
    itemsPerPage = 24;
    products: ProductListItem[] = [];
    theme = Config.theme;
    breadcrumbs: BreadcrumbHistoryItem[] = [];
    measurementUnit = Units.MM;

    readonly ProductUrlSourceType = ProductUrlSourceType;
    readonly Routes = Routes;

    async created() {

        if (!UtilService.isEmptyOrNull(this.$route.query.page)) {
            this.page = +this.$route.query.page;
        }

        if (!UtilService.isEmptyOrNull(this.$route.query.size)) {
            this.itemsPerPage = +this.$route.query.size;
        }

        if (!UtilService.isEmptyOrNull(this.$route.query.keywords)) {
            this.search = this.$route.query.keywords as string;
        }

        if (!UtilService.isEmptyOrNull(this.$route.query.measurementUnit)) {
            this.measurementUnit = this.$route.query.measurementUnit as Units;
            this.getProducts();
        }

        this.initBreadcrumbs();
    }

    onPageChange(page: number) {
        this.page = page;
        this.updateSearch();
    }

    clearSearch() {
        this.$nextTick(() => {
            this.search = '';
            this.getProducts();
            this.updateUrl();
        });
    }

    updateSearch() {
        this.getProducts();
        this.updateUrl();
    }

    updateItemsPerPage(number: number) {
        this.itemsPerPage = number;
        this.getProducts();
        this.updateUrl();
    }

    async getProducts() {

        const {
            totalNumPages,
            totalCount,
            templates
        } = await ProductService.getFavoritesPage(this.page, this.itemsPerPage, this.getSearchData());

        this.products = templates;
        this.totalCount = totalCount;
        this.totalPages = totalNumPages;
    }

    get isWorkAs(): boolean {
        return this.$store.getters['auth/customerWorkAsId'];
    }

    get diemakerUnits(): Units {
        return this.$store.getters['diemakerUnits'];
    }

    get unitSelectVisible(): boolean {
        return this.diemakerUnits === Units.BOTH;
    }

    get diemaker(): Diemaker {
        return this.$store.getters['diemaker'];
    }

    @Watch('diemaker', {immediate: true})
    onDiemakerChange() {
        if (this.diemaker) {
            if (UtilService.isEmptyOrNull(this.$route.query.measurementUnit)) {
                if (this.diemaker.units !== Units.BOTH) {
                    this.measurementUnit = this.diemaker.units;
                }
                this.getProducts();
            }
        }
    }

    updateUnit(unit: Units) {
        this.measurementUnit = unit;
        this.getProducts();
        this.updateUrl();
    }

    private getSearchData() {

        const data: any = {
            page: this.page,
            size: this.itemsPerPage,
            measurementUnit: this.measurementUnit
        };

        if (!UtilService.isEmptyOrNull(this.search)) {
            data.keywords = this.search;
        }

        if (!UtilService.isEmptyOrNull(this.selectedMaterial.code)) {
            data.material = this.selectedMaterial.code;
        }

        return data;
    }

    private updateUrl() {
        this.$router.replace({name: Routes.ProductFavouriteList, query: this.getSearchData() as any});
    }

    private initBreadcrumbs() {
        if (!this.isWorkAs) {
            this.breadcrumbs = [
                {
                    text: 'breadcrumbs.newRequest',
                    disabled: false,
                    to: {
                        name: Routes.NewRequest
                    }
                },
                {
                    text: 'products.breadcrumbs.productFavouriteList',
                    disabled: true
                }
            ];
        } else {
            this.breadcrumbs = [
                {
                    text: 'products.breadcrumbs.productFavouriteList',
                    disabled: true
                }
            ];
        }
    }
}
