import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { SvgIcon } from '@shared/components/icon/svg-icon.enum';

@Component
export default class Icon extends Vue {
  @Prop() svgIcon!: SvgIcon;

  get svgFile() {
    return require(`!html-loader!../../assets/${this.svgIcon}.svg`);
  }
}
