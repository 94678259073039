import { Component, Prop, Vue } from 'vue-property-decorator';
import CustomerTableDefaultIndicator from '../customer-table-default-indicator/customer-table-default-indicator.component.vue';
import { CustomerDieboard } from '@shared/modules/products/models/customer-dieboard.model';
import { BaseConfig } from '@shared/base-config';
import { Units } from '@shared/modules/diemaker/enums/units-enum';

@Component({
    components: {
        CustomerTableDefaultIndicator
    }
})
export default class CustomerDieboards extends Vue {

    @Prop({default: []}) data!: CustomerDieboard[];
    @Prop() unit!: Units;

    get priceLabel(): string {
        return this.$i18n.t('flatDies.price') + ` (${this.currency}/${this.unit === Units.MM ? 'm' : 'ft'}^2)`;
    }

    get headers() {
        const headers: any = [
            {text: this.$i18n.t('flatDies.dieboards'), align: 'start', value: 'selectedForCustomer', width: '50px'},
            {text: this.$i18n.t('flatDies.default'), align: 'start', value: 'default', width: '50px'},
            {text: this.$i18n.t('flatDies.brand'), value: 'description'},
        ];

        if (BaseConfig.projectType !== 'customer') {
            headers.push({text: this.priceLabel, value: 'price', width: '130px'});
        }

        return headers;
    }

    get currency(): string {
        return this.$store.getters.diemaker?.internalCurrency || this.$store.getters.diemaker?.currency;
    }

    setSelected(dieboard: CustomerDieboard) {
        this.$emit('selectedChange', dieboard);
        if (!dieboard.selectedForCustomer && dieboard.default) {
            dieboard.default = false;
            const firstSelected = this.data.find((r) => r.selectedForCustomer);
            if (firstSelected) {
                this.setDefault(firstSelected);
            }
        }
    }

    setDefault(dieboard: CustomerDieboard) {
        const currentDefault = this.data.find((r) => r.default);
        if (currentDefault && currentDefault.dbId !== dieboard.dbId) {
            currentDefault.default = false;
        }
        dieboard.default = !dieboard.default;
        dieboard.selectedForCustomer = true;
        this.$emit('defaultChange', dieboard);
    }
}
