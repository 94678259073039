import { Config } from '../../../../projects/customer/src/config';
import { DiemakerTheme } from '../../../../projects/customer/src/modules/products/models/diemaker-theme.interface';
import { StrippingDieInfo } from '../../../../projects/customer/src/modules/products/models/stripping-die-info.interface';
import { Units } from '@shared/modules/diemaker/enums/units-enum';

export class Diemaker {
    uuid: string;
    name: string;
    strippingDieInfo: StrippingDieInfo;
    rules: any;
    boards: any;
    address: string;
    phone: string | null = null;
    email: string | null = null;
    disclaimer: string;
    logoUrl: string;
    website: string;
    isCurrent: boolean;
    theme: DiemakerTheme;
    primaryContactName: string | null = null;
    currency: string;
    internalToLocalCurrency?: number;
    supportsFlat: boolean;
    supportsRotary: boolean;
    discount: number | null;
    units: Units;

    constructor({ uuid, name, strippingDieInfo, supportsFlat, supportsRotary, units, discount, internalToLocalCurrency, default: isCurrent, currency, theme, rules, boards, address, website,
        primaryUser, disclaimer, logoUrl }: any) {
        this.uuid = uuid;
        this.name = name;
        this.currency = currency;
        this.strippingDieInfo = strippingDieInfo;
        this.rules = rules;
        this.boards = boards;
        this.address = address;
        this.disclaimer = disclaimer;
        this.logoUrl = logoUrl && Config.apiPortal + logoUrl;
        this.website = website;
        this.isCurrent = isCurrent;
        this.theme = theme;
        this.internalToLocalCurrency = internalToLocalCurrency;
        this.supportsFlat = supportsFlat;
        this.supportsRotary = supportsRotary;
        this.discount = discount;
        this.units = units;

        if (primaryUser) {
            this.phone = primaryUser.phone;
            this.email = primaryUser.email;
            this.primaryContactName = primaryUser.name;
        }
    }
}
