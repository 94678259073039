import { RequestService } from '@shared/request/request.service';
import { Config } from '../../../config';
import { Diemaker } from '@shared/modules/diemaker/models/diemaker.model';
import { ServerError } from '@shared/request/server-error.model';
import store from '../../../store/store';

export class DiemakerService {
    static getDiemakers(): Promise<Diemaker[]> {
        return RequestService.get('api/v1/customer/diemakers', {}, {apiPath: Config.apiPortal})
            .then(resp => resp.map((p: any) => new Diemaker(p)));
    }

    static async getDiemaker(id: string): Promise<Diemaker> {
        try {
            const diemaker = await RequestService.get(`api/v1/diemaker/${id}`,
                null, {apiPath: Config.apiPortal, logoutOnExpiredSession: false}).then(r => new Diemaker(r));
            return diemaker;
        } catch (error: ServerError | any) {
            if (error.code !== 19) {
                store.commit('auth/logoutSilent');
            }
            store.commit('setUserDefaultDiemaker', null);
            store.commit('setRegisterCostEstimationCalled', false);
            return RequestService.get(`api/v1/diemaker/${id}`,
                null, {apiPath: Config.apiPortal}).then(r => new Diemaker(r));
        }
    }

    static addDiemaker(id: string): Promise<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded'
        };
        const data = {
            diemaker_uuid: id
        };
        return RequestService.post('api/v1/customer/add-diemaker', data, {headers, apiPath: Config.apiPortal});
    }

    static getPublicDiemakers(): Promise<Diemaker[]> {
        return RequestService.get('api/v1/diemaker/public/diemakers', {}, {apiPath: Config.apiPortal})
            .then(resp => resp.map((p: any) => new Diemaker(p)));
    }

    static setAsCurrent(diemakerId: string): Promise<Diemaker> {
        const data = {
            diemaker_uuid: diemakerId
        };
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded'
        };

        return RequestService.post('api/v1/customer/current-diemaker', data, {
            apiPath: Config.apiPortal,
            headers
        }).then(r => new Diemaker(r));
    }

    static getRulesAndBoards(diemakerId: string): Promise<any> {
        return RequestService.get(`api/v1/customer/diemaker-info/${diemakerId}`, null, {apiPath: Config.apiPortal});
    }
}
