import { Component, Vue, Watch } from 'vue-property-decorator';
import FormErrorMessage from '@shared/components/form-error-message/form-error-message.component.vue';
import LoadingButton from '@shared/components/loading-button/loading-button.component.vue';
import CustomInput from '@shared/components/custom-input/custom-input.component.vue';
import { Validators } from '@shared/validation/validators';
import { UserService } from '@shared/modules/users/services/user.service';
import { Routes } from '../../../../routes.enum';

@Component({
  components: {
    FormErrorMessage,
    CustomInput,
    LoadingButton
  }
})
export default class UserSetPassword extends Vue {

  password = '';
  repeatPassword = '';
  isError = false;
  errorMessage = '';

  async submit(stopLoading: () => void) {
    this.isError = false;

    if (this.validate()) {
      try {
        const token = this.$route.params.token;
        const user = await UserService.resetPassword(token, this.password);

        this.$store.commit('auth/login', user);

        if (this.$store.getters['auth/language']) {
          this.$store.commit('setLanguage', this.$store.getters['auth/language']);
        }

        await this.$store.dispatch('loadDefaultDiemaker');

        if (!this.$store.getters.getUserDefaultDiemaker) {
          this.$router.push({name: Routes.UserChooseDefaultDiemaker});
        } else {
          this.$router.push({name: Routes.NewRequest});
        }

      } catch (e) {
        if (e.message !== 'redirect') {
          this.isError = true;
          this.errorMessage = `errors.${e.code}`;
        }
      }
    }

    stopLoading();
  }

  get repeatPasswordRule() {
    return [Validators.PasswordMatch(this.password)];
  }

  private validate() {
    return (this.$refs.form as any).validate();
  }
}
