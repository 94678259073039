import { Component, Vue } from 'vue-property-decorator';
import { BaseConfig } from '@shared/base-config';

@Component
export default class Footer extends Vue {

    theme = BaseConfig.theme;
    readonly copyrightUrl = 'https://www.engview.com/en/engview/home/';

    get links(): Array<{ label: string; to?: any, url?: string }> {
        const links = [];

        if (BaseConfig.termsAndConditionsLink && BaseConfig.termsAndConditionsLink !== '#') {
            links.push({label: 'footer.termsConditions', url: BaseConfig.termsAndConditionsLink});
        } else {
            links.push({label: 'footer.termsConditions', to: {name: 'tos'}});
        }

        if (BaseConfig.privacyPolicyLink && BaseConfig.privacyPolicyLink !== '#') {
            links.push({label: 'footer.privacyPolicy', url: BaseConfig.privacyPolicyLink});
        } else {
            links.push({label: 'footer.privacyPolicy', to: {name: 'privacy'}});
        }

        return links;
    }
}
