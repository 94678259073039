import { RequestService } from '@shared/request/request.service';
import { BaseConfig } from '@shared/base-config';

export class BaseOrderService {

    static export(id: string, format: string): Promise<string> {
        const data = {
            format
        };
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded'
        };
        return RequestService.post(`api/v1/order/${id}/export-evd`, data, {headers, apiPath: BaseConfig.apiPortal}).then(r => r.exportLinkUrl);
    }
}
