import { format } from 'date-fns';
import { Units } from '../modules/diemaker/enums/units-enum';

export class UtilService {

    private static uniqueNum = 1;

    static getUniqueNum(): number {
        return this.uniqueNum++;
    }

    static isEmptyOrNull(value: any): boolean {

        if (!value) {
            return true;
        }

        if (typeof value === 'string' && value.trim().length === 0) {
            return true;
        }

        return false;
    }

    static formatDateYYYYMMDD(date: Date): string {
        return format(date, 'yyyy-MM-dd');
    }

    static formatDate(date: Date, dateFormat: string): string {
        return format(date, dateFormat);
    }

    static isSvg(url: string): boolean {
        const parts = url.split('.');
        for (const p of parts) {
            if (p.includes('svg')) {
                return true;
            }
        }
        return false;
    }

    static lightenDarkenColor(hex: string, amt: number): string {
        const col = hex.slice(1);
        const num = parseInt(col, 16);
        // tslint:disable-next-line:no-bitwise
        let r = (num >> 16) + amt;

        if (r > 255) {
            r = 255;
        } else if  (r < 0) {
            r = 0;
        }

        // tslint:disable-next-line:no-bitwise
        let b = ((num >> 8) & 0x00FF) + amt;

        if (b > 255) {
            b = 255;
        } else if  (b < 0) {
            b = 0;
        }

        // tslint:disable-next-line:no-bitwise
        let g = (num & 0x0000FF) + amt;

        if (g > 255) {
            g = 255;
        } else if (g < 0) {
            g = 0;
        }

        // tslint:disable-next-line:no-bitwise
        return '#' + (g | (b << 8) | (r << 16)).toString(16);
    }

    static getLocalizedName(data: any, lang: string): string | undefined {
        const text = data?.localizedNames.find((n: any) => n.langid === lang)?.text;
        return text ?? data?.defaultName;
    }

    static getFileExtension(file: File): string {
        return file.name.split('.').pop() as string;
    }

    static fixSvgIds(svg: SVGElement) {

        const prefix = this.getUniqueNum();
        const ids: any = {};

        svg.querySelectorAll('defs > *').forEach((el) => {
            ids[`#${el.id}`] = `#${el.id}_${prefix}`;
            el.id = `${el.id}_${prefix}`;
        });

        svg.querySelectorAll(`use`).forEach((el) => {
            const href = el.getAttribute('xlink:href') as string;
            if (ids[href]) {
                el.setAttribute('xlink:href', ids[href]);
            }
        });
    }

    static isTouch(): boolean {
        return window.matchMedia('(hover: none)').matches;
    }

    static formatNumber(num: number, unit: Units ): number {
        if (unit === Units.INCH) {
            return Number(num.toFixed((3)));
        } else {
            return num;
        }
    }

    static encodeFilename(file: File): File {
        const blob = file.slice(0, file.size, file.type);
        return new File([blob], encodeURIComponent(file.name), {type: file.type});
    }
}
