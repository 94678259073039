import { Component, Vue, Watch } from 'vue-property-decorator';
import { ProductExportFormat } from '../../models/product-export-format.interface';
import { Product } from '@shared/modules/products/models/product.model';
import { ProductMaterial } from '../../models/product-material.interface';
import { ProductResizeParam } from '@shared/modules/products/models/product-resize-param.model';
import { FluteDirection } from '@shared/modules/products/enums/flute-direction.enum';
import { Routes } from '../../../../routes.enum';
import { Config } from '../../../../config';
import { ProductDrawing } from '@shared/modules/products/models/product-drawing.model';
import Breadcrumbs from '@shared/components/breadcrumbs/breadcrumbs.component.vue';
import { Validators } from '@shared/validation/validators';
import ProductResizeParams from '@shared/modules/products/components/product-resize-params/product-resize-params.component.vue';

@Component({
  components: {
    Breadcrumbs,
    ProductResizeParams
  }
})
export default class ProductResize extends Vue {

  readonly Validators = Validators;
  readonly hasSendResizeTemplatePage = Config.hasSendResizeTemplatePage;

  dirty = true;

  async backToPreview() {

    if (this.$store.getters.hasLayoutParams() || this.$store.getters.hasResizeParams() || this.$store.getters.hasCostEstimationParams()) {
      const answer = window.confirm(this.$i18n.t('messages.unsavedChanges') as string);
      if (!answer) {
        return;
      }
    }

    await this.$store.dispatch('restoreCurrentProduct');
    this.$emit('back');
  }

  goToLayout() {
    this.$router.push({name: Routes.ProductLayout, params: {id: this.currentProduct.id} as any});
  }

  goToSend() {
    this.$router.push({name: Routes.ProductSendResizeTemplate, params: {id: this.currentProduct.id} as any});
  }

  get currentProduct(): Product {
    return this.$store.getters.getPreviewOfCurrentProduct;
  }
}
