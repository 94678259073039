import { Component, Vue, Watch } from 'vue-property-decorator';
import { Validators } from '@shared/validation/validators';
import { ProductResizeParam } from '../../../models/product-resize-param.model';
import { Product } from '../../../models/product.model';
import { ProductDrawing } from '@shared/modules/products/models/product-drawing.model';
import { UtilService } from '@shared/utils/util.service';
import CustomEdit from '@shared/modules/products/components/product-resize-params/custom-edit/custom-edit.component.vue';
import ParamEditDialogComponent from '@shared/modules/products/components/product-resize-params/param-edit-dialog/param-edit-dialog.component.vue';

@Component({
    components: {
        CustomEdit,
        ParamEditDialogComponent
    }
})
export default class ProductResizeAdvancedParams extends Vue {

    openedInnerPanel: number | null = null;

    mainParamMap: ProductResizeParam[] = [];
    othersParamMap: ProductResizeParam[] = [];
    othersParametersArray = [];
    openGroup: any = '';

    loadingOverlayVisible = false;
    isTouch = UtilService.isTouch();

    otherParamsHeaders = [
        {
            text: this.$i18n.t('products.resize.paramName'),
            align: 'start',
            sortable: false,
            divider: true,
            value: 'paramName'
        },
        {text: this.$i18n.t('products.resize.value'), value: 'value', width: '300px'}
    ];

    readonly Validators = Validators;

    mounted() {
        if (this.isTouch) {
            document.addEventListener('click', this.onClickOutside);
        }
    }

    destroyed() {
        if (this.isTouch) {
            document.removeEventListener('click', this.onClickOutside);
        }
    }

    onClickOutside() {
        const tooltips = this.$refs?.tooltip as any[] || [];

        for (const g of tooltips) {
            g.deactivate();
        }
    }

    getOthersParamsMap(group: any) {

        if (!group) {
            this.openGroup = null;
            this.othersParamMap = [];
            this.$store.commit('changeDrawingVisibleParams', this.groupsArray[0].paramKeys);
            return;
        }

        this.openGroup = group;
        this.othersParamMap = [];

        if (group.paramKeys != null) {
            for (const key of group.paramKeys) {
                this.othersParamMap.push(
                    new ProductResizeParam(this.currentProduct.params.paramMap[key], key)
                );
            }

            this.$store.commit('changeDrawingVisibleParams', group.paramKeys.concat(this.groupsArray[0].paramKeys));
        }
    }

    getOthersParamsMapOnDrawingChange() {
        if (this.openGroup) {
            this.othersParamMap = [];
            for (let i = 0; i < this.groupsArray.length; i++) {
                if (this.groupsArray[i].paramKeys != null) {
                    if (
                        this.openGroup.groupName && this.openGroup.groupName.defaultName === this.groupsArray[i].groupName.defaultName
                    ) {
                        for (let y = 0; y < this.groupsArray[i].paramKeys.length; y++) {
                            this.othersParamMap.push(
                                new ProductResizeParam(this.currentProduct.params.paramMap[
                                    this.groupsArray[i].paramKeys[y]
                                    ], this.groupsArray[i].paramKeys[y])
                            );
                        }
                    }
                }
            }
        }
    }

    toggleAdvancedParams() {
        this.openedInnerPanel = null;
    }

    setDirty() {
        this.$emit('update:dirty', true);
    }

    @Watch('openedInnerPanel')
    onOpenedInnerPanelChange(openedInnerPanel: number) {
        this.getOthersParamsMap(openedInnerPanel !== null ? this.groupsArray[(this.openedInnerPanel as number) + 1] : null);
    }

    @Watch('chosenDrawing')
    chosenDrawingChange(drawing: ProductDrawing) {
        this.getOthersParamsMapOnDrawingChange();
    }

    get chosenDrawing(): ProductDrawing {
        return this.$store.getters.getChosenDrawing;
    }

    get currentProduct(): Product {
        return this.$store.getters.getPreviewOfCurrentProduct;
    }

    get drawInfos(): any {
        return this.currentProduct.params.drawInfos;
    }

    get groupsArray() {
        return this.$store.getters.getGroupsArray.groups;
    }
}
