import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Icon from '@shared/components/icon/icon.component.vue';
import { SvgIcon } from '@shared/components/icon/svg-icon.enum';

@Component({
  components: {
    Icon
  }
})
export default class UploadNewFileButton extends Vue {

  readonly SvgIcon = SvgIcon;

  @Prop({default: '.jpg,.jpeg,.png'}) accepts!: string;
  @Prop({default: []}) rules!: any[];

  onChange(e: any) {
    const file: any = this.$refs.fileInput;
    if (file.files[0] && this.isFileValid(file.files[0])) {
      this.$emit('change', file.files[0]);
    }
  }

  openFileDialog() {
    (this.$refs.fileInput as any).click();
  }

  private isFileValid(file: File) {

    const errors: string[] = [];

    for (const rule of this.rules) {
      const result: any | boolean = rule(file);
      if (result !== true) {
        errors.push(result as any);
      }
    }

    if (errors.length) {
      this.$emit('error', errors[0]);
      this.removeFile();
      return false;
    }

    return true;
  }

  private removeFile() {
    const fileInput = this.$refs.fileInput as any;
    fileInput.value = null;
  }
}
