import { render, staticRenderFns } from "./rules-info.component.vue?vue&type=template&id=a3f5c5b6&scoped=true&"
import script from "./rules-info.component.ts?vue&type=script&lang=ts&"
export * from "./rules-info.component.ts?vue&type=script&lang=ts&"
import style0 from "./rules-info.component.scss?vue&type=style&index=0&id=a3f5c5b6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a3f5c5b6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VCol,VIcon,VSimpleTable})
