import { Component, Vue } from 'vue-property-decorator';
import { Routes } from '../../../../routes.enum';

@Component
export default class Profile extends Vue {

    readonly Routes = Routes;

    get isWorkAs(): boolean {
        return this.$store.getters['auth/customerWorkAsId'];
    }
}
