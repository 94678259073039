import store from '~shared/store/store';
import { UtilService } from '@shared/utils/util.service';
import { MathService } from '@shared/utils/math.service';
import { Units } from '../../diemaker/enums/units-enum';

export class ProductResizeParam {

    expression: string;
    paramName: string;
    defaultName: string;
    value: number;
    originalValue: number;
    values?: Array<{name: string; value: number, description: any}> = undefined;
    valuesNameMap?: {[key: string]: string};
    hasMultipleValues = false;
    key: string;

    constructor({expression, paramName, value, values, description}: any, key: string) {

        const language = store.getters['language'];

        if (expression !== value.toString() && store.getters['templateUnit'] === Units.INCH && MathService.validate(expression)) {
            this.value = expression;
        } else {
            this.value = value;
        }

        this.key = key;
        this.expression = expression;
        this.paramName = paramName;
        this.defaultName = UtilService.getLocalizedName(description, language) ?? paramName;
        this.originalValue = value;
        this.values = values?.map((v: any) => {
            v.text = UtilService.getLocalizedName(v.description, language) ?? v.name;
            return v;
        });

        if (this.values) {
            this.hasMultipleValues = true;
            this.valuesNameMap = {};
            for (const v of this.values) {
                this.valuesNameMap[v.value] = UtilService.getLocalizedName(v.description, language) ?? v.name;
            }
        } else {
            this.hasMultipleValues = false;
        }
    }
}
