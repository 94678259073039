import { ProductUrlSourceType } from '../../modules/products/enums/product-url-source-type.enum';
import { DrawingType } from '@shared/modules/products/enums/drawing-type.enum';
import { ProductService } from '../../modules/products/product.service';
import { OrderBaseRequest } from '../../modules/products/models/order/order-base.request';
import { SheetSizeInfo } from '../../modules/products/models/sheet-size-info.interface';
import { DieboardInfo } from '../../modules/products/models/dieboard-info.interface';
import { CostEstimationBaseRequest } from '../../modules/products/models/cost-estimation/cost-estimation-base.request';
import { LayoutCalcSheetInfo } from '../../modules/products/models/layout-calc-sheet-info.interface';
import { ProductCostEstimationState } from './product-cost-estimation.store';
import { ProductLayoutState } from './product-layout.store';

function createInitialState(): ProductMakeOrderState {
    return {
        test: null,
        deliveryDate: null,
        requestTitle: null,
        notes: null,
        estimatedCost: null,
        rules: []
    };
}

export interface ProductMakeOrderState {
    test: any;
    deliveryDate: Date | null;
    requestTitle: string | null;
    notes: string | null;
    estimatedCost: number | null;
    rules: Array<{ selectedThickness: string; selectedBrand: string; type: string }>;
}

export const ProductMakeOrder = {
    namespaced: true,
    state: createInitialState(),
    actions: {
        async makeOrder({commit, state, getters, rootGetters}: any, {
            urlSourceType,
            productId,
            orderData
        }: any): Promise<any> {

            const {paramMap, material, fluteDir, grammageNote} = rootGetters.getResizeParams(productId);
            const {calcInfo, rotate} = rootGetters.getLayoutParams(productId);

            const copyCalfInfo = JSON.parse(JSON.stringify(calcInfo));

            const template = rootGetters['productLayout/template'];

            if (template !== '') {
                copyCalfInfo[0].layoutPatternId = template;
            }

            const dieboard = rootGetters['productCostEstimation/dieboardInfo'];

            const {
                hasMaleStrippingDie, hasFemaleStrippingDie, hasFrontStripper,
                maleStrippingDieCostPercentage, femaleStrippingDieCostPercentage, frontStripperCostPercentage,
                estimatedCost, strippingRulesInfo, hasCompensatingRules, cuttingDieSide, discount
            } = rootGetters.getCostEstimationParams(productId);

            const data = {
                notes: orderData.notes,
                deliveryDate: orderData.deliveryDate,
                layoutDrawingName: orderData.layoutDrawingName,
                diemakerUuid: orderData.diemakerUuid,
                hasMaleStrippingDie,
                hasFemaleStrippingDie,
                hasFrontStripper,
                maleStrippingDieCostPercentage,
                femaleStrippingDieCostPercentage,
                frontStripperCostPercentage,
                ...(rootGetters['productCostEstimation/getRulesCosts']),
                strippingRulesInfo,
                boardId: rootGetters['productCostEstimation/boardId'],
                boardCost: rootGetters['productCostEstimation/boardCost'],
                totalCost: estimatedCost,
                title: orderData.layoutDrawingName,
                grammageNote,
                rules: orderData.rules.map((r: any) => {
                    return {
                        type: r.type,
                        thickness: r.selectedThickness,
                        brand: r.selectedBrand,
                        rotary: false,
                    };
                })
            } as OrderBaseRequest & CostEstimationBaseRequest;

            if (discount) {
                data.discount = discount;
            }

            if (rootGetters['diemaker']?.internalToLocalCurrency) {
                data.targetCurrencyRate = rootGetters['diemaker'].internalToLocalCurrency;
            }

            const layoutCalcSheetInfo = copyCalfInfo[0].sheets[0] as LayoutCalcSheetInfo;
            layoutCalcSheetInfo.dieboard = dieboard;

            const cuttingSide = rootGetters['productCostEstimation/cuttingDieSide'];

            if (urlSourceType === ProductUrlSourceType.File) {

                const type = rootGetters['uploadFile/type'];

                if (type === DrawingType.OneUp) {
                    return ProductService.makeOrderForFromFile(productId, {
                        ...data,
                        calcInfo: [
                            {
                                sheets: [layoutCalcSheetInfo],
                                oneups: copyCalfInfo[0].oneups,
                                layoutPatternId: copyCalfInfo[0].layoutPatternId,
                                id: rootGetters['uploadFile/drawingId'],
                                gapX: copyCalfInfo[0].gapX,
                                gapY: copyCalfInfo[0].gapY,
                                fixedCounts: rootGetters['productLayout/fixedCount']
                            }
                        ],
                        material: { ...material, cuttingSide },
                        fluteDir,
                        force90DegRotate: rotate,
                        paramMap,
                        hasCompensatingRules,
                        oneupUrl: rootGetters['productCostEstimation/topPreview']
                    });
                } else if (type === DrawingType.Layout) {
                    return ProductService.makeOrderForFromLayoutFile(productId, {
                        ...data,
                        drawingInfo: {
                            dieboard,
                            sheet: copyCalfInfo[0].sheets[0].sheet as SheetSizeInfo,
                            id: rootGetters['uploadFile/drawingId'],
                        },
                        material: { ...material, cuttingSide },
                        fluteDir,
                        force90DegRotate: rotate,
                        hasCompensatingRules,
                        strippingRulesInfo,
                        layoutUrl: rootGetters['productCostEstimation/topPreview']
                    });
                } else {
                    return ProductService.makeOrderForFromDieboardFile(productId, {
                        ...data,
                        drawingInfo: {
                            dieboard,
                            sheet: copyCalfInfo[0].sheets[0].sheet as SheetSizeInfo,
                            id: rootGetters['uploadFile/drawingId'],
                        },
                        material: { ...material, cuttingSide },
                        fluteDir,
                        force90DegRotate: rotate,
                        hasCompensatingRules,
                        strippingRulesInfo,
                        layoutUrl: rootGetters['productCostEstimation/topPreview'],
                        dieboardUrl: rootGetters['productCostEstimation/bottomPreview'],
                    });
                }

            } else {
                const params = {
                    ...data,
                    material: { ...material, cuttingSide },
                    fluteDir,
                    force90DegRotate: rotate,
                    paramMap,
                    hasCompensatingRules,
                    templateId: productId,
                    calcInfo: [
                        {
                            sheets: [layoutCalcSheetInfo],
                            oneups: copyCalfInfo[0].oneups,
                            id: copyCalfInfo[0].id,
                            gapX: copyCalfInfo[0].gapX,
                            gapY: copyCalfInfo[0].gapY,
                            layoutPatternId: copyCalfInfo[0].layoutPatternId,
                            fixedCounts: rootGetters['productLayout/fixedCount']
                        }
                    ],
                };

                return ProductService.makeOrder(params);
            }
        },
    },
    mutations: {
        setDeliveryDate(state: ProductMakeOrderState, deliveryDate: Date): void {
            state.deliveryDate = deliveryDate;
        },
        setRequestTitle(state: ProductMakeOrderState, requestTitle: string): void {
            state.requestTitle = requestTitle;
        },
        setNotes(state: ProductMakeOrderState, notes: string): void {
            state.notes = notes;
        },
        setEstimatedCost(state: ProductMakeOrderState, estimatedCost: number): void {
            state.estimatedCost = estimatedCost;
        },
        setRules(state: ProductMakeOrderState, rules: Array<{ selectedThickness: string; selectedBrand: string; type: string }>): void {
            state.rules = rules;
        },
        reset(state: ProductCostEstimationState): void {
            const _state = createInitialState();

            for (const _key of Object.keys(state)) {
                const key = _key as keyof ProductLayoutState;
                // @ts-ignore
                state[key] = _state[key];
            }
        },
    },
    getters: {
        requestTitle: (state: ProductMakeOrderState): string => state.requestTitle as string,
        deliveryDate: (state: ProductMakeOrderState): Date => state.deliveryDate as Date,
        notes: (state: ProductMakeOrderState): string => state.notes as string,
        estimatedCost: (state: ProductMakeOrderState): number => state.estimatedCost as number,
        rules: (state: ProductMakeOrderState): Array<{ selectedThickness: string; selectedBrand: string; type: string }> => state.rules
    }
};
