import { Component, Prop, Vue } from 'vue-property-decorator';
import { RequestService } from '@shared/request/request.service';
import { Diemaker } from '../../../diemaker/models/diemaker.model';

@Component
export default class LibrarySelect extends Vue {
    @Prop() value!: any;
    @Prop() diemaker!: Diemaker;

    materials: any[] = [];
    hasCustomLibrary = false;

    async created() {
        this.materials = await RequestService.get('api/v1/config/materials', {customLibraryUUID: this.diemaker?.uuid});

        for (const m of this.materials) {
            if (m.isCustomLibrary) {
                this.hasCustomLibrary = true;
                this.materials = this.materials.filter(_m => !_m.isCustomLibrary);
                break;
            }
        }

        const material = this.$route.query.material;

        if (material) {
            for (const m of this.materials) {
                if (m.code === material) {
                    this.updateMaterial(m);
                    break;
                }
            }

            if (material === this.diemaker?.uuid) {
                this.updateMaterial({name: this.diemaker.name, code: this.diemaker.uuid});
            }
        }
    }

    updateMaterial(material: { name: string, code: string }) {
        this.$emit('input', material);
    }
}
