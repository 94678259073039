import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Theme } from '../../themes/theme.enum';
import { BaseConfig } from '@shared/base-config';
import HelpNavigation from '@shared/components/base-header/help-navigation/help-navigation.component.vue';

@Component({
    components: {
        HelpNavigation
    }
})
export default class BaseHeader extends Vue {

    @Prop() logo!: string;
    @Prop({default: null}) logoClickUrl!: string;
    @Prop() theme!: Theme;
    @Prop({default: () => []}) mainNavigation!: any[];
    @Prop() loginRouteName!: string;
    @Prop({default: null}) profileRouteName!: string;
    @Prop({default: true}) hasLogout!: boolean;
    @Prop({default: false}) hideLogin!: boolean;
    @Prop({default: true}) showHelpPage!: boolean;

    Themes = Theme;
    helpPage: any = null;

    getLogo() {
        return this.logo || require(`@/themes/${this.theme}/logo.png`);
    }

    @Watch('$route', {immediate: true})
    onRouteChange(to: any) {
        if (BaseConfig.projectType === 'diemaker' && !this.isLogged) {
            return;
        }

        if (BaseConfig.projectType === 'superadmin') {
            return;
        }

        this.helpPage = {name: to?.meta?.helpRoute ?? 'Help', query: {backUrl: location.hash.replace('#', '')}};
    }

    async logout() {

        if (BaseConfig.projectType === 'customer') {
            this.$store.commit('clearResizeParams');
            this.$store.commit('clearLayoutParams');
            this.$store.commit('uploadFile/clearData');
            this.$store.commit('clearCostEstimationParams');
            this.$store.commit('productCostEstimation/reset');
            this.$store.commit('productLayout/reset');
            this.$store.commit('productMakeOrder/reset');
            this.$store.commit('clearOrderRequestParams');
            this.$store.commit('changePreviewOfCurrentProduct', {});
        }

        await this.$store.dispatch('auth/logout');
    }

    get isLogged(): boolean {
        return this.$store.getters['auth/isAuthenticated'];
    }

    get loggedUsername(): string {
        return this.$store.getters['auth/loggedUser'].user.name;
    }

    get isEmailConfirmed(): boolean {
        if (this.isLogged && BaseConfig.projectType === 'customer') {
            return this.$store.getters['auth/isEmailConfirmed'];
        } else {
            return true;
        }
    }

    get hasDefaultDiemaker(): boolean {
        if (this.isLogged && BaseConfig.projectType === 'customer') {
            return this.$store.getters['getUserDefaultDiemaker'];
        } else {
            return true;
        }
    }
}
