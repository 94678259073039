import { DiemakerRule } from '../../../modules/products/models/diemaker-rule.interface';
import { RuleType } from '@shared/modules/products/models/rule-type.enum';

const rulesMapping = {
    [RuleType.Cut]: 'cuttingRuleLength',
    [RuleType.Crease]: 'creasingRuleLength',
    [RuleType.CutCrease]: 'cutCreaseRuleLength',
    [RuleType.Perfo]: 'perfoRuleLength'
};

export function filterRulesWithStatsLength(rules: any[], layoutStats: any): DiemakerRule[] {
    return rules
        .filter(({type}: {type: RuleType}) => !layoutStats || layoutStats[rulesMapping[type]] > 0)
        .filter((obj: DiemakerRule) => {
            filterEnabled(obj);
            return obj.thicknesses.length > 0;
        })
        .map((obj: DiemakerRule) => {
            const {thickness: defaultThickness, brand: defaultBrand} = findDefaultBrand(obj) || {};
            obj.selectedThickness = defaultThickness;
            obj.selectedBrand = defaultBrand;
            return obj;
        });
}

export function getRulePrice(rule: DiemakerRule): number | undefined {
    if (rule.selectedThickness) {
        const selectedThickness = rule.thicknesses.find(t => t.thickness === rule.selectedThickness);

        if (selectedThickness && rule.selectedBrand) {
            const selectedBrand = selectedThickness.brands.find(b => b.brand === rule.selectedBrand);

            if (selectedBrand) {
                return selectedBrand.price;
            }
        }
    }
}

function filterEnabled(obj: DiemakerRule) {
    obj.thicknesses = obj.thicknesses.filter(t => {
        t.brands =  t.brands.filter(b => b.enabled);
        return t.brands.length > 0;
    });
}

function findDefaultBrand(obj: DiemakerRule) {
    for (const thickness of obj.thicknesses) {
        for (const brand of thickness.brands) {
            if (brand.default) {
                return {
                    brand: brand.brand,
                    thickness: thickness.thickness
                };
            }
        }
    }
}
