import Component from 'vue-class-component';
import ProductSection from '@shared/modules/products/components/product-section/product-section.component.vue';
import Vue from 'vue';
import { FluteDirection } from '@shared/modules/products/enums/flute-direction.enum';
import { i18n } from '@shared/plugins/i18n';
import { Product } from '@shared/modules/products/models/product.model';

@Component({
    components: {
        ProductSection
    }
})
export default class MaterialsTable extends Vue {

    get cuttingDieSide() {
        return this.$i18n.t(`dropdowns.cuttingSides.${this.$store.getters['productCostEstimation/cuttingDieSide']}`);
    }

    get resizeParams(): any {
        return this.$store.getters.getResizeParams(this.currentProduct.id);
    }

    get grainDirection(): string {
        const dir = this.resizeParams.fluteDir as FluteDirection;

        if (dir === FluteDirection.X) {
            return i18n.t(`dropdowns.fluteDirections.X`) as string;
        } else if (dir === FluteDirection.Y) {
            return i18n.t(`dropdowns.fluteDirections.Y`) as string;
        }

        return '';
    }
    get currentProduct(): Product {
        return this.$store.getters.getPreviewOfCurrentProduct;
    }
}
