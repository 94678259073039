import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { RequestService } from '@shared/request/request.service';
import { UtilService } from '@shared/utils/util.service';

@Component
export default class ProductPreviewDrawingThumbnail extends Vue {

  @Prop() url!: string;
  @Prop({default: null}) preview3d!: string;
  @Prop() productId!: string;

  preview3dBase64: string | null = null;

  private svgContent = '';
  private imageUrl: string | null = null;

  async mounted() {
    if (UtilService.isSvg(this.url)) {
      await this.loadSvgContent();
    } else {
      this.setPlainImage(this.url);
    }
  }

  @Watch('url', {immediate: false})
  async urlChange() {
    if (UtilService.isSvg(this.url)) {
      await this.loadSvgContent();
    } else {
      this.setPlainImage(this.url);
    }
  }

  @Watch('preview3d', {immediate: true})
  async onPreview3dChange(preview3d: string) {
    if (this.preview3d) {
      this.preview3dBase64 = await RequestService.getFile(this.preview3d);
    } else {
      this.preview3dBase64 = null;
    }

  }

  private async loadSvgContent() {
    this.svgContent = await RequestService.get(this.url);
  }

  private async setPlainImage(url: string) {
    this.imageUrl = await RequestService.getFile(url);
  }
}
