import { Component, Vue, Watch } from 'vue-property-decorator';
import FormErrorMessage from '@shared/components/form-error-message/form-error-message.component.vue';
import LoadingButton from '@shared/components/loading-button/loading-button.component.vue';
import CustomInput from '@shared/components/custom-input/custom-input.component.vue';
import { UserService } from '../../services/user.service';
import { Validators } from '@shared/validation/validators';
import { Routes } from '../../../../../projects/customer/src/routes.enum';

@Component({
  components: {
    FormErrorMessage,
    CustomInput,
    LoadingButton
  }
})
export default class UserResetPassword extends Vue {

  password = '';
  repeatPassword = '';
  isError = false;
  errorMessage = '';
  token = '';

  readonly Validators = Validators;

  async created() {
    try {
      this.token = await UserService.exchangePasswordToken(this.$route.params.token);
    } catch (e) {
      this.$router.push({name: 'userLogin', query: {error: e.code}});
    }
  }

  async submit(stopLoading: () => void) {
    this.isError = false;

    if (this.validate()) {
      try {
        const user = await UserService.resetPassword(this.token, this.password);
        this.$store.commit('auth/login', user);

        if (this.$store.getters['auth/language']) {
          this.$store.commit('setLanguage', this.$store.getters['auth/language']);
        }

        await this.$store.dispatch('loadDefaultDiemaker');

        if (!this.$store.getters.getUserDefaultDiemaker) {
          this.$router.push({name: Routes.UserChooseDefaultDiemaker});
          return;
        } else {
          this.$router.push({name: Routes.NewRequest});
        }
      } catch (e) {
        if (e.message !== 'redirect') {
          this.isError = true;
          this.errorMessage = `errors.${e.code}`;
        }
      }
    }

    stopLoading();
  }

  get repeatPasswordRule() {
    return [Validators.PasswordMatch(this.password), Validators.MinLength(6)];
  }

  private validate() {
    return (this.$refs.form as any).validate();
  }
}
