import { Component, Vue } from 'vue-property-decorator';
import CustomInput from '@shared/components/custom-input/custom-input.component.vue';
import { Person } from '../../models/person.model';
import LoadingButton from '@shared/components/loading-button/loading-button.component.vue';
import ChangePasswordModal from '@shared/modules/profile/components/change-password-modal/change-password-modal.component.vue';
import { SelectFieldItem } from '@shared/components/select-field/select-field-item.interface';
import { BaseConfig } from '@shared/base-config';
import SelectField from '@shared/components/select-field/select-field.component.vue';
import FormErrorMessage from '@shared/components/form-error-message/form-error-message.component.vue';
import { CompanyService } from '../../services/company.service';

@Component({
    components: {
        CustomInput,
        ChangePasswordModal,
        LoadingButton,
        SelectField,
        FormErrorMessage
    }
})
export default class ContactPerson extends Vue {
    dialog = false;
    loggedUser = this.$store.getters['auth/loggedUser'].user;
    language = this.$store.getters['auth/language'];
    name = this.loggedUser.name;
    email = this.loggedUser.email;
    phone = this.loggedUser.phone;
    errorMessage: string | null = null;
    id = '';

    readonly languages: SelectFieldItem[] = Object.keys(BaseConfig.availableLanguages).map((l: any) => {
        return {
            value: l,
            text: (BaseConfig.availableLanguages as any)[l],
        };
    });

    async created() {
        const { primaryContact } = await CompanyService.getDetails();
        this.name = primaryContact.name;
        this.email = primaryContact.email;
        this.phone = primaryContact.phone;
        this.language = primaryContact.locale;
        this.id = primaryContact.guid;
    }

    async updatePerson(stopLoading: FunctionConstructor) {
        this.errorMessage = null;
        if (this.validate()) {
            try {
                const data = new Person({
                    name: this.name,
                    email: this.email,
                    phone: this.phone,
                    locale: this.language,
                    id: this.id
                });
                await this.$store.dispatch('updatePersonDetails', data);
                this.$store.dispatch('notification/showSuccess',
                    {text: this.$i18n.t('messages.saved')});
            } catch (e) {
                this.errorMessage = `errors.${e.code}`;
            }

            stopLoading();
        } else {
            stopLoading();
        }
    }

    get isWorkAs(): boolean {
        return this.$store.getters['auth/customerWorkAsId'];
    }

    private validate(): boolean {
        return (this.$refs.form as any).validate();
    }
}
