import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { RuleType } from '@shared/modules/products/models/rule-type.enum';
import { CustomerDieboard } from '@shared/modules/products/models/customer-dieboard.model';
import { CustomerRule } from '@shared/modules/products/models/customer-rule.interface';
import CustomerRules from '@shared/modules/products/components/customer-rules/customer-rules.component.vue';
import CustomerDieboards from '@shared/modules/products/components/customer-dieboards/customer-dieboards.component.vue';
import { ProfileService } from '../../profile.service';
import CustomerDiecutters from '@shared/modules/products/components/customer-diecutters/customer-diecutters.component.vue';
import { DiecutterTableItem } from '../../models/diecutter-table-item.model';
import SelectField from '@shared/components/select-field/select-field.component.vue';
import { Diemaker } from '@shared/modules/diemaker/models/diemaker.model';
import { Units } from '@shared/modules/diemaker/enums/units-enum';

@Component({
    components: {
        CustomerRules,
        CustomerDieboards,
        CustomerDiecutters,
        SelectField
    }
})
export default class FlatDiesComponent extends Vue {

    groups: Array<{ type: RuleType, rules: CustomerRule[] }> = [];
    dieboards: CustomerDieboard[] = [];
    diecutters: DiecutterTableItem[] = [];
    hasBothUnits = false;
    selectableUnits: Units[] = [Units.MM, Units.INCH];
    selectedUnit: Units = Units.MM;

    async created() {
        const [groups, dieboards] = await Promise.all([
            ProfileService.getRulesByType(false, this.$store.getters['getDiemakerContext']),
            ProfileService.getDieboards(false, this.$store.getters['getDiemakerContext']),
        ]);
        this.groups = groups;
        this.dieboards = dieboards;
    }

    @Watch('diemaker', {immediate: true})
    async onDiemakerChange() {
        if (this.diemaker) {
            this.hasBothUnits = this.diemaker.units === Units.BOTH;
            if (!this.hasBothUnits) {
                this.selectedUnit = this.diemaker.units;
            }
            this.diecutters = await this.getDiecutters(this.selectedUnit);
        }
    }

    @Watch('selectedUnit')
    async onUnitChange() {
        this.diecutters = await this.getDiecutters(this.selectedUnit);
    }

    get diemaker(): Diemaker {
        return this.$store.getters['diemaker'];
    }

    async saveRule(rule: CustomerRule) {
        await ProfileService.saveRule(rule, this.$store.getters['getDiemakerContext']);
    }

    async getDiecutters(measurementUnit: Units): Promise<DiecutterTableItem[]> {
        return ProfileService.getDiecutters(measurementUnit, false);
    }

    async saveDieboard(dieboard: CustomerDieboard) {
        await ProfileService.saveDieboard(dieboard, this.$store.getters['getDiemakerContext']);
    }

    async handleCreateDeleteCustomerDiecutter(item: DiecutterTableItem): Promise<void> {
        await ProfileService.handleCreateDeleteCustomerDiecutter(item, this.selectedUnit);
        this.loadDiecutters();
    }

    async handleStatusChange(item: DiecutterTableItem) {
        await ProfileService.enableCustomerDiecutter(item.id as number, item.enabled);
        this.loadDiecutters();
    }

    async loadDiecutters() {
        this.diecutters = await ProfileService.getDiecutters(this.selectedUnit, false);
    }

    async deleteDiecutter(item: DiecutterTableItem) {
        await ProfileService.deleteCustomerDiecutter(item.id as number);
        this.loadDiecutters();
    }

}
