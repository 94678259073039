import { Component, Prop, Vue } from 'vue-property-decorator';
import { FluteDirection } from '@shared/modules/products/enums/flute-direction.enum';
import { ProductDrawing } from '@shared/modules/products/models/product-drawing.model';
import ProductPreviewDrawing from '@shared/modules/products/components/product-preview-drawing/product-preview-drawing.component.vue';

@Component({
    components: {
        ProductPreviewDrawing
    }
})

export default class PreviewDrawingCard extends Vue {

    @Prop() heightOfPreviewWindow!: number;

    private onDrawingError(): void {
        this.$emit('onDrawingError');
    }

    get previewUrl(): string {
        return this.$store.getters['productLayout/previewUrl'];
    }

    get fluteDir(): FluteDirection | null {
        const rotate: boolean = this.$store.getters['productLayout/rotate'];
        const drawing: ProductDrawing = this.$store.getters['productLayout/drawing'];
        if (rotate) {
            if (drawing.fluteDirection === FluteDirection.X) {
                return FluteDirection.Y;
            } else if (drawing.fluteDirection === FluteDirection.Y) {
                return FluteDirection.X;
            }
        }

        return drawing.fluteDirection;
    }

    get drawing(): ProductDrawing {
        return this.$store.getters['productLayout/drawing'];
    }

}
