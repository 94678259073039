import { Routes } from '../../routes.enum';
import store from '../../store/store';

export function isAuthenticatedResolver(to: any, from: any, next: any) {
    if (!store.getters['auth/isAuthenticated']) {
        next({name: Routes.UserLogin, query: {redirectTo: location.hash.replace('#', '')}});
        return;
    }
    next();
}
