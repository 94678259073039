import { Component, Prop, Vue } from 'vue-property-decorator';
import LoadingButton from '@shared/components/loading-button/loading-button.component.vue';
import { BaseConfig } from '@shared/base-config';
import { ProjectType } from '@shared/enums/project-type.enum';
import { OrderStatus } from '@shared/modules/orders/models/order-status.enum';
import { BaseOrder } from '@shared/modules/orders/models/base-order.model';
import { Validators } from '@shared/validation/validators';
import { BaseOrderService } from '@shared/modules/orders/base-order.service';
import { Customer } from '../../../../../../projects/diemaker/src/modules/customers/models/customer.model';

@Component({
    components: {
        LoadingButton
    }
})
export default class Dialogs extends Vue {
    @Prop() order!: BaseOrder;
    @Prop() customer!: Customer;

    formatValue = 'EVD';
    dialogAccept = false;
    dialogDecline = false;
    dialogExport = false;
    notes = '';

    readonly formatsArray = [
        {id: 'EVD', name: 'EngView Design Project (.evd)'},
        {id: 'DXF', name: 'Autocad DXF (.dxf)'},
        {id: 'CF2', name: 'Common File Format CFF2 (.cf2)'},
        {id: 'DDES', name: 'DDES (.dde)'},
        {id: 'PDF', name: 'Adobe PDF (.pdf)'}
    ];

    readonly Validators = Validators;

    closeAcceptDialog() {
        this.notes = '';
        this.dialogAccept = false;
        (this.$refs.acceptForm as any).resetValidation();
    }

    closeDeclineDialog() {
        this.notes = '';
        this.dialogDecline = false;
        (this.$refs.declineForm as any).resetValidation();
    }

    showAcceptDialog() {
        this.dialogAccept = true;
    }

    showDeclineDialog() {
        this.dialogDecline = true;
    }

    acceptOrder() {
        if (this.validateAcceptForm()) {
            this.$emit('accept-order', {
                notes: this.notes,
                onCompleted: () => {
                    this.closeAcceptDialog();
                }
            });
        }
    }

    declineOrder() {
        if (this.validateDeclineForm()) {
            this.$emit('decline-order', {
                notes: this.notes,
                onCompleted: () => {
                    this.closeDeclineDialog();
                }
            });
        }
    }

    get showOrderActions(): boolean {
        return BaseConfig.projectType === ProjectType.diemaker && (this.canAccept || this.canAccept);
    }

    get canAccept(): boolean {
        return this.order?.status === OrderStatus.New || this.order?.status === OrderStatus.Denied;
    }

    get isSuperadmin(): boolean {
        return BaseConfig.projectType === ProjectType.diemaker && this.$store.getters['auth/isAuthenticated'] && this.$store.getters['auth/organizationUuid'];
    }

    get showExport(): boolean {
        return this.order.status === OrderStatus.Accepted && BaseConfig.projectType === ProjectType.diemaker;
    }

    get canDecline(): boolean {
        return this.order?.status === OrderStatus.New;
    }

    validateAcceptForm() {
        return (this.$refs.acceptForm as any).validate();
    }

    validateDeclineForm() {
        return (this.$refs.declineForm as any).validate();
    }

    async exportOrder(stopLoading: () => void): Promise<void> {
        if (this.customer.demo) {
            this.$store.dispatch('notification/showError', {text: this.$i18n.t('customers.demoCustomer.cannotDownloadDemoCustomerFile')});
            stopLoading();
            return;
        }
        const url = await BaseOrderService.export(this.order.id, this.formatValue);
        stopLoading();
        window.location.href = BaseConfig.apiPortal + url;

    }

}
