import { ProductService } from '../../modules/products/product.service';
import drawingUrl from '@shared/filters/drawing-url.filter';
import { BaseConfig } from '@shared/base-config';
import { ViewSide } from '@shared/modules/products/enums/view-side.enum';
import { DrawingType } from '@shared/modules/products/enums/drawing-type.enum';
import { PrepareEvdResponse } from '../../modules/products/models/prepare-evd-response.interface';
import { PrepareEvdRequest } from '../../modules/products/models/prepare-evd-request.interface';
import { ProductMaterialCuttingSide } from '../../modules/products/models/product-material-cutting-side.enum';
import { LayoutStatistics } from '../../modules/products/models/layout-statistics.interface';
import { Units } from '@shared/modules/diemaker/enums/units-enum';

export interface UploadFileMaterialInfo {
    cuttingSide: ProductMaterialCuttingSide;
    grammage: number;
    name: string;
    thickness: number;
    types: string[];
}

function createInitialState(): UploadFileState {
    return {
        uploadFileResponse: null,
        drawingsTabs: [],
        type: DrawingType.OneUp,
        materialType: null,
        viewSide: ViewSide.Front,
        original: {
            viewSide: ViewSide.Front,
            type: DrawingType.OneUp,
            drawingId: null
        },
        drawingId: null,
        svgURL: '',
        flippedSVGUrl: '',
        layoutStatistics: null,
        unit: null
    };
}

export interface UploadFileState {
    uploadFileResponse: any;
    drawingsTabs: Array<{ svgUrl: string, layout?: any, drawing?: any }>;
    type: DrawingType;
    materialType: string | null;
    viewSide: ViewSide;
    original: {
        viewSide: ViewSide;
        type: DrawingType;
        drawingId: number | null
    };
    drawingId: number | null;
    svgURL: string;
    flippedSVGUrl: string;
    layoutStatistics: LayoutStatistics | null;
    unit: Units | null;
}

export const UploadFileStore = {
    namespaced: true,
    state: createInitialState(),
    actions: {
        async upload({ state, commit, rootGetters }: { state: UploadFileState, commit: any, rootGetters: any }, file: File): Promise<any> {

            const resp = await ProductService.importProduct(file, {measurementUnit: state.unit as Units});
            commit('setDrawingsTabs', resp);
            commit('setUploadFileResponse', resp);
            return resp;
        },
        async prepareEvd({ state, commit }: { state: UploadFileState, commit: any }, uuid: string): Promise<PrepareEvdResponse> {

            const data: PrepareEvdRequest = {
                originalDrawingType: state.original.type,
                drawingType: state.type,
                originalViewSide: state.original.viewSide,
                viewSide: state.viewSide,
                drawingId: state.original.drawingId as number,
            };

            const response = await ProductService.prepareEvd(uuid, data);

            commit('handlePrepareEvdResponse', response);
            return response;
        },
    },
    mutations: {
        setUploadFileResponse(state: UploadFileState, uploadFileResponse: any): void {
            state.uploadFileResponse = uploadFileResponse;
        },
        setDrawingsTabs(state: UploadFileState, uploadFileResponse: any): void {
            uploadFileResponse.layouts = uploadFileResponse.layouts || [];
            uploadFileResponse.oneUps = uploadFileResponse.oneUps || [];

            state.drawingsTabs = uploadFileResponse.layouts.concat(uploadFileResponse.oneUps).map((item: any) => {
                item.svgUrl = drawingUrl(item.svgUrl, BaseConfig.apiPortal);
                return item;
            });
        },
        clearData(state: UploadFileState): void {
            const _state = createInitialState();

            for (const _key of Object.keys(state)) {
                const key = _key as keyof UploadFileState;
                state[key] = _state[key];
            }
        },
        type(state: UploadFileState, type: DrawingType): void {
            state.type = type;
        },
        materialType(state: UploadFileState, materialType: string): void {
            state.materialType = materialType;
        },
        viewSide(state: UploadFileState, viewSide: ViewSide): void {
            state.viewSide = viewSide;
        },
        setOriginals(state: UploadFileState, { viewSide, type, drawingId }: { viewSide: ViewSide; type: DrawingType, drawingId: number }): void {
            state.original.viewSide = viewSide;
            state.original.type = type;
            state.original.drawingId = drawingId;
        },
        setDrawingId(state: UploadFileState, drawingId: number): void {
            state.drawingId = drawingId;
        },
        setSvgUrl(state: UploadFileState, svgURL: string): void {
            state.svgURL = svgURL;
        },
        setFlippedSvgUrl(state: UploadFileState, flippedSVGUrl: string): void {
            state.flippedSVGUrl = flippedSVGUrl;
        },
        handlePrepareEvdResponse(state: UploadFileState, response: PrepareEvdResponse): void {
            const { drawingID, svgURL, flippedSVGUrl, statistics } = response;
            state.drawingId = drawingID;
            state.svgURL = drawingUrl(svgURL, BaseConfig.apiPortal);
            state.flippedSVGUrl = drawingUrl(flippedSVGUrl, BaseConfig.apiPortal);
            state.layoutStatistics = statistics;
        },
        revertOriginalDrawingId(state: UploadFileState) {
            state.drawingId = state.original.drawingId;
        },
        setUnit(state: UploadFileState, unit: Units) {
            state.unit = unit;
        }
    },
    getters: {
        uploadFileResponse: (state: UploadFileState): any => state.uploadFileResponse,
        drawingsTabs: (state: UploadFileState): Array<{ svgUrl: string, layout?: any, drawing?: any }> => state.drawingsTabs,
        type: (state: UploadFileState): DrawingType => state.type,
        materialType: (state: UploadFileState): string | null => state.materialType,
        viewSide: (state: UploadFileState): ViewSide => state.viewSide,
        originals: (state: UploadFileState): Record<string, any> => state.original,
        drawingId: (state: UploadFileState): number | null => state.drawingId,
        svgUrl: (state: UploadFileState): string => state.svgURL,
        flippedSvgUrl: (state: UploadFileState): string => state.flippedSVGUrl,
        materialInfoResponse: (state: UploadFileState): UploadFileMaterialInfo => state.uploadFileResponse.materialInfo,
        layoutStatistics: (state: UploadFileState): LayoutStatistics | null => state.layoutStatistics,
        hasUploadData: (state: UploadFileState): boolean => !!state.uploadFileResponse,
        unit: (state: UploadFileState): Units | null => state.unit
    }
};
