import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Icon from '@shared/components/icon/icon.component.vue';
import { Validators } from '@shared/validation/validators';
import { ProductService } from '../../product.service';
import { DrawingType } from '@shared/modules/products/enums/drawing-type.enum';
import { UtilService } from '@shared/utils/util.service';
import { Units } from '@shared/modules/diemaker/enums/units-enum';
import { MathService } from '@shared/utils/math.service';

@Component({
    components: {
        Icon
    }
})
export default class UploadFileSheet extends Vue {

    @Prop() selectedDrawing!: any;

    readonly Validators = Validators;

    margins: { left: number | null; right?: number | null; top?: number | null; bottom?: number | null } = {
        left: null,
        right: null,
        top: null,
        bottom: null
    };
    catalog: any = [];
    selectedCatalog = null;
    width: undefined | string = '';
    height: undefined | string = '';

    async created() {

        await this.loadCatalog();

        if (this.importResponse) {
            this.loadSavedParams();
        }
    }

    get importResponse() {
        return this.$store.getters['uploadFile/uploadFileResponse'];
    }

    get isDieboard(): boolean {
        return this.$store.getters['uploadFile/type'] === DrawingType.Dieboard;
    }

    get drawingsTabs() {
        return this.$store.getters['uploadFile/drawingsTabs'];
    }

    get unit() {
        return this.$store.getters['uploadFile/unit'];
    }

    get marginsValidators() {
        if (this.unit === Units.MM) {
            return [Validators.MaxLength(5)];
        } else {
            return [Validators.InchParam];
        }
    }

    get minWidth() {
        if (this.isDieboard) {
            return 0;
        }
        const obj = this.selectedDrawing?.layout ?? this.selectedDrawing?.drawing;
        const min = obj?.sheet?.width ?? obj?.designobjrect.w;

        if (this.unit === Units.INCH) {
            return min ? Number(min.toFixed((3))) : 0;
        } else {
            return parseInt(min ? min + 1 : 0, 10);
        }
    }

    get minHeight() {
        if (this.isDieboard) {
            return 0;
        }
        const obj = this.selectedDrawing?.layout ?? this.selectedDrawing?.drawing;
        const min = obj?.sheet?.height ?? obj?.designobjrect.h;
        if (this.unit === Units.INCH) {
            return min ? Number(min.toFixed((3))) : 0;
        } else {
            return parseInt(min ? min + 1 : 0, 10);
        }
    }

    get widthValidators() {
        if (this.unit === Units.INCH) {
            return [Validators.Min(this.minWidth)];
        } else {
            return [Validators.MaxLength(5), Validators.Min(this.minWidth)];
        }
    }

    get heightValidators() {
        if (this.unit === Units.INCH) {
            return [Validators.Min(this.minHeight)];
        } else {
            return [Validators.MaxLength(5), Validators.Min(this.minHeight)];
        }
    }

    @Watch('unit')
    onUnitChange() {
        if (this.unit) {
            this.loadCatalog();
            this.width = undefined;
            this.height = undefined;
            this.margins.left = null;
            this.margins.top = null;
            this.margins.right = null;
            this.margins.bottom = null;
        }
    }

    @Watch('importResponse')
    onImportResponseChange() {
        this.margins.left = null;
        this.margins.top = null;
        this.margins.right = null;
        this.margins.bottom = null;
    }

    @Watch('selectedDrawing')
    onSelectedDrawingChange(selectedDrawing: any) {
        this.selectedCatalog = null;
        if (selectedDrawing?.layout?.sheet) {
            if (this.unit === Units.INCH) {
                this.width = UtilService.formatNumber(selectedDrawing.layout.sheet.width, this.unit) + '';
                this.height =  UtilService.formatNumber(selectedDrawing.layout.sheet.height, this.unit) + '';
                this.margins.left = UtilService.formatNumber(selectedDrawing.layout.sheet.marginL, this.unit);
                this.margins.right =  UtilService.formatNumber(selectedDrawing.layout.sheet.marginR, this.unit);
                this.margins.top = UtilService.formatNumber(selectedDrawing.layout.sheet.marginT, this.unit);
                this.margins.bottom =  UtilService.formatNumber(selectedDrawing.layout.sheet.marginB, this.unit);
            } else {
                this.width = Math.round(selectedDrawing.layout.sheet.width).toString();
                this.height = Math.round(selectedDrawing.layout.sheet.height).toString();
                this.margins.left = Math.round(selectedDrawing.layout.sheet.marginL);
                this.margins.right = Math.round(selectedDrawing.layout.sheet.marginR);
                this.margins.top = Math.round(selectedDrawing.layout.sheet.marginT);
                this.margins.bottom = Math.round(selectedDrawing.layout.sheet.marginB);
            }
        } else {
            this.width = '';
            this.height = '';
            this.onImportResponseChange();
        }
    }

    @Watch('selectedCatalog')
    onSelectedCatalogChange(id: any) {
        if (id) {
            const {
                bottomMargin, leftMargin, rightMargin,
                topMargin, maxHeight, maxWidth
            } = this.catalog.find((c: { sheetId: string }) => c.sheetId === id.toString()) || {};

            this.width = UtilService.formatNumber(maxWidth, this.unit) + '';
            this.height = UtilService.formatNumber(maxHeight, this.unit) + '';

            this.margins = {
                left: UtilService.formatNumber(leftMargin, this.unit),
                top: UtilService.formatNumber(topMargin, this.unit),
                bottom: UtilService.formatNumber(bottomMargin, this.unit),
                right: UtilService.formatNumber(rightMargin, this.unit)
            };
        }
    }

    setWidth(width: any) {
        this.width = width;
    }

    setHeight(height: any) {
        this.height = height;
    }

    setMarginLeft(margin: any) {
        this.margins.left = margin;
    }

    setMarginRight(margin: any) {
        this.margins.right = margin;
    }

    setMarginTop(margin: any) {
        this.margins.top = margin;
    }

    setMarginBottom(margin: any) {
        this.margins.bottom = margin;
    }

    formatField($event: any, setter: (value: any) => void) {
        if (this.unit === Units.INCH) {
            try {
                $event.target.value = MathService.calculate($event.target.value);
                setter($event.target.value);
            } catch (ex) {}
        }
    }

    private loadSavedParams() {
        const uuid = this.importResponse.uuid;

        const layoutParams = this.$store.getters.getLayoutParams(uuid);

        this.selectedCatalog = layoutParams.calcInfo[0].sheets[0]?.id !== -1 ? layoutParams.calcInfo[0].sheets[0]?.id : null;

        this.$nextTick(() => {
            if (layoutParams.calcInfo[0].sheets[0].sheet?.width) {
                this.width = layoutParams.calcInfo[0].sheets[0].sheet?.width;
            }

            if (layoutParams.calcInfo[0].sheets[0].sheet?.height) {
                this.height = layoutParams.calcInfo[0].sheets[0].sheet?.height;
            }

            this.margins.left = layoutParams.calcInfo[0].sheets[0].sheet?.marginL || '';
            this.margins.top = layoutParams.calcInfo[0].sheets[0].sheet?.marginT || '';
            this.margins.right = layoutParams.calcInfo[0].sheets[0].sheet?.marginR || '';
            this.margins.bottom = layoutParams.calcInfo[0].sheets[0].sheet?.marginB || '';
        });
    }

    private async loadCatalog() {
        if (this.unit) {
            this.catalog = await ProductService.getSheetCatalog(this.unit);
            this.catalog.unshift({name: this.$i18n.t('products.costEstimation.labels.userDefined'), sheetId: null});
        }
    }
}
