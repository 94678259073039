import { Component, Vue, Watch } from 'vue-property-decorator';
import { Routes } from '../../../../routes.enum';
import FormErrorMessage from '@shared/components/form-error-message/form-error-message.component.vue';
import LoadingButton from '@shared/components/loading-button/loading-button.component.vue';
import CustomInput from '@shared/components/custom-input/custom-input.component.vue';
import { UtilService } from '@shared/utils/util.service';
import { UserService } from '@shared/modules/users/services/user.service';

@Component({
  components: {
    FormErrorMessage,
    CustomInput,
    LoadingButton
  }
})
export default class UserResendActivation extends Vue {

  readonly loggedUser = this.$store.getters['auth/loggedUser'].user;
  readonly Routes = Routes;

  email = this.loggedUser.email;
  errorMessage: string | null = null;

  async created() {
    await UserService.onEmailConfirmed();
    this.$store.commit('auth/emailConfirmed');

    await this.$store.dispatch('loadDefaultDiemaker');
    if (!this.$store.getters.getUserDefaultDiemaker) {
      this.$router.push({name: Routes.UserChooseDefaultDiemaker, query: {redirectTo: this.$route.query.redirectTo}});
      return;
    } else {
      this.redirect();
    }

  }

  async submit(stopLoading: () => void) {
    this.errorMessage = null;

    if (this.validate()) {
      try {
        await UserService.resendActivationEmail(this.email);
      } catch (e) {
        this.errorMessage = `errors.${e.code}`;
      }
      stopLoading();
    } else {
      stopLoading();
    }
  }

  private redirect() {
    if (this.$route.query.redirectTo) {
      const redirectTo = this.$route.query.redirectTo as string;
      this.$router.push({path: redirectTo});
    } else {
      this.$router.push({name: Routes.NewRequest});
    }
  }

  private validate() {
    return (this.$refs.form as any).validate();
  }
}
