import { ProductMaterialCuttingSide } from '../../../../projects/customer/src/modules/products/models/product-material-cutting-side.enum';

export enum ViewSide {
    Unknown = 'DVS_Unknown',
    Front = 'DVS_ViewFront',
    Back = 'DVS_ViewBack'
}

export function viewSideFromIndex(index: number): ViewSide {
    if (index === 1) {
        return ViewSide.Front;
    } else if (index === 2) {
        return ViewSide.Back;
    }

    return ViewSide.Unknown;
}

export function viewSideToCuttingDieSide(viewSide: ViewSide): string {
    if (viewSide === ViewSide.Back) {
        return 'DCS_BackCuttingDie';
    }

    return 'DCS_FrontCuttingDie';
}

export function cuttingDieSideToViewSide(cuttingSide: ProductMaterialCuttingSide): ViewSide {
    if (cuttingSide === ProductMaterialCuttingSide.DCS_FrontCuttingDie) {
        return ViewSide.Front;
    }

    return ViewSide.Back;
}
