import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';

@Component
export default class Pagination extends Vue {

    @Prop({default: 0}) totalCount!: number;
    @Prop({default: 0}) totalPages!: number;
    @Prop({default: 1}) page!: number;

    goToPage = null;

    setPage(page: string) {
        const pageNum = parseInt(page, 10);
        if (!isNaN(pageNum) && pageNum > 0 && pageNum <= this.totalPages) {
            this.$emit('page-change', pageNum);
            this.goToPage = null;
        }
    }

    pageChange(page: number) {
        this.$emit('page-change', page);
    }

}
