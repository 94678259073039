import { Component, Vue, Watch } from 'vue-property-decorator';
import BaseOrderDetails from '@shared/modules/orders/base-order-details/base-order-details.component.vue';
import { Routes } from '../../../../routes.enum';
import { BreadcrumbHistoryItem } from '@shared/components/breadcrumbs/breadcrumb-history-item.interface';
import { OrderService } from '../../order.service';
import { CustomerOrder } from 'projects/customer/src/modules/orders/models/customer-order.model';

@Component({
    components: {
        BaseOrderDetails
    }
})
export default class OrderDetails extends Vue {

    order: CustomerOrder | null = null;
    breadcrumbs: BreadcrumbHistoryItem[] = [];

    readonly Routes = Routes;

    async created() {
        const orderService = new OrderService();
        this.order = await orderService.getOrder(this.$route.params.id);
        this.initBreadcrumbs();
    }

    private initBreadcrumbs() {
        this.breadcrumbs = [
            {
                text: 'orders.order.breadcrumb.orders',
                disabled: false,
                to: {
                    name: Routes.OrderList
                }
            },
            {
                plainText: (this.order as CustomerOrder).name,
                disabled: true
            }
        ];
  }
}
