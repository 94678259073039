import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ProductPreviewDrawingThumbnail from '@shared/modules/products/components/product-preview-drawing-thumbnail/product-preview-drawing-thumbnail.component.vue';
import { RequestService } from '@shared/request/request.service';
import { BaseConfig } from '@shared/base-config';
import { Product } from '@shared/modules/products/models/product.model';
import { DrawingThumbnailItem } from '@shared/modules/products/components/drawing-thumbnail-list/drawing-thumbnail-item.interface';

@Component({
    components: {
        ProductPreviewDrawingThumbnail
    }
})
export default class DrawingThumbnailList extends Vue {

    @Prop() reloadOnCreate!: boolean;
    @Prop() currentProduct!: Product;
    @Prop() is3dSelected = false;

    activeTab = 0;
    serverAndApiURL = 'api/v1/files/';
    readonly scrollOptions = {
        rail: {
            background: '#f5f5f5',
            opacity: 1,
            size: '8px',
            specifyBorderRadius: false,
            gutterOfEnds: null,
            gutterOfSide: '2px',
            keepShow: true
        },
        bar: {
            showDelay: 500,
            onlyShowBarOnScroll: true,
            keepShow: true,
            background: '#c1c1c1',
            opacity: 1,
            hoverStyle: false,
            specifyBorderRadius: false,
            minSize: 0,
            size: '8px',
            disable: false
        },
        scrollPanel: {
            scrollingX: false
        }
    };

    thumbnails: DrawingThumbnailItem[] = [];

    created() {
        if (this.currentProduct?.id === +this.$route.params.id || this.reloadOnCreate) {
            this.currentProductChange();
        }
    }

    getGroupsAccordingToChosenDrawing(index: number) {
        this.activeTab = index;
        const drawing = this.thumbnails[index].drawing;
        if (drawing) {
            this.$emit('changeChosenDrawing', drawing);
            for (let i = 0; i < this.drawInfos.length; i++) {
                if ((drawing?.name || 'Global') === this.drawInfos[i].drawingName) {
                    this.$emit('changeGroupsArray', this.drawInfos[i]);
                }
            }
        }

        this.$emit('3dSelectionChange', !drawing);
        this.$emit('selectionChange', this.thumbnails[index]);
    }

    async createTabs(activeTab: number) {

        this.thumbnails = [];

        for (const drawing of this.currentProduct.drawings) {
            this.thumbnails.push({
                thumbnailUrl: drawing.svgURL,
                drawing
            });
        }

        if (this.currentProduct.preview3DUrl) {
            const content = await RequestService.getFile(this.serverAndApiURL + encodeURI(this.currentProduct.preview3DUrl) + '?t=' + Date.now());

            this.thumbnails.push({
                thumbnailUrl: content,
                preview3DUrl: '3D.html?url=' +
                    encodeURIComponent(BaseConfig.apiWebStandardsLibrary + this.serverAndApiURL + this.currentProduct.daeURL) +
                    '&Animations3D=' +
                    encodeURIComponent(this.currentProduct.colladaAnimations)
            });
        }

        this.activeTab = activeTab;

        // Handle the case when the prev page was layout
        this.setSelectedTabFromSavedResizeParams();

        this.getGroupsAccordingToChosenDrawing(this.activeTab);
    }

    @Watch('activeTab')
    activeTabChange(tabIndex: number) {
        this.getGroupsAccordingToChosenDrawing(tabIndex);
    }

    setActiveTab(index: number) {
        this.activeTab = index;
    }

    @Watch('currentProduct')
    currentProductChange() {
        const lastActiveTab = this.activeTab;
        this.createTabs(lastActiveTab);
    }

    get drawInfos() {
        return this.currentProduct.params.drawInfos;
    }

    private setSelectedTabFromSavedResizeParams() {
        if (typeof this.$store.getters.getResizeParams === 'function') {
            const {drawingId = null} = this.$store.getters.getResizeParams(this.currentProduct.id) || {};
            if (!this.is3dSelected) {
                if (drawingId) {
                    const drawingTabIndex = this.thumbnails.findIndex((d) => d.drawing?.evDrawingId === drawingId);
                    if (drawingTabIndex > -1) {
                        this.activeTab = drawingTabIndex;
                    }
                }
            } else {
                this.activeTab = this.thumbnails.length - 1;
            }
        }
    }
}
