import { Component, Vue, Watch } from 'vue-property-decorator';
import { UserService } from '../../services/user.service';
import { Routes } from '../../../../../projects/customer/src/routes.enum';

@Component
export default class UserConfirmEmail extends Vue {

  loadingOverlayVisible = true;

  async created() {
    const token = this.$route.params.token;
    try {
      await UserService.confirmEmail(token);
      this.$store.commit('auth/emailConfirmed');

      if (!this.$store.getters['auth/isAuthenticated']) {
        this.$router.push({name: 'userLogin', query: {confirmed: 1} as any});
      } else {
        this.$store.dispatch('notification/showSuccess', {text: this.$i18n.t('confirmEmail.messages.success')});

        await this.$store.dispatch('loadDefaultDiemaker');

        if (!this.$store.getters.getUserDefaultDiemaker) {
          this.$router.push({name: Routes.UserChooseDefaultDiemaker});
          return;
        } else {
          this.$router.push({path: '/'});
        }
      }
    } catch (e) {
      this.$router.push({name: 'userLogin', query: {error: 'expiredCode'} as any});
    }
  }
}
