import { RequestService } from '@shared/request/request.service';
import { Config } from '../../../config';
import { Company } from '../models/company.model';

export class CompanyService {

    static getDetails(): Promise<Company> {
        return RequestService.get(`api/v1/customer`, null, {apiPath: Config.apiPortal}).then(
            resp => new Company(resp)
        );
    }

    static update(company: Company): Promise<any> {
        const data: any = {
            customer: JSON.stringify({
                companyName: company.name,
                website: company.website,
                uuid: company.uuid
            })
        };

        if (company.logo) {
            data.logo = company.logo;
        }

        return RequestService.put(`api/v1/customer/update-company`, data, {multipart: true, apiPath: Config.apiPortal});
    }
}
