import { Component, Prop, Vue } from 'vue-property-decorator';
import Breadcrumbs from '@shared/components/breadcrumbs/breadcrumbs.component.vue';
import { BreadcrumbHistoryItem } from '@shared/components/breadcrumbs/breadcrumb-history-item.interface';

@Component({
    components: {
        Breadcrumbs
    }
})
export default class Navigation extends Vue {
    @Prop() breadcrumbs!: BreadcrumbHistoryItem[];

    get hasGeneratedLayout(): boolean {
        return this.$store.getters['productLayout/hasGeneratedLayout'];
    }

    get previewUrl(): string {
        return this.$store.getters['productLayout/previewUrl'];
    }

    private goToCostEstimation() {
        this.$emit('goToCostEstimation');
    }

    private backToProducts() {
        this.$emit('backToProducts');
    }

    get dirty(): boolean {
        return this.$store.getters['productLayout/dirty'];
    }
}
