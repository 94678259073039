import { Config } from '../../../config';

export class Company {
    uuid: string;
    name: string;
    website: string;
    logo: string | File;
    logoUrl: string;
    primaryContact: {
        email: string,
        guid: string,
        locale: string,
        name: string,
        phone: string,
        username: string,
    };

    constructor({uuid, companyName, primaryContact, website, logo, logoUrl}: any) {
        this.uuid = uuid;
        this.name = companyName;
        this.website = website;
        this.logo = logo;
        this.logoUrl = logoUrl && Config.apiPortal + logoUrl;
        this.primaryContact = primaryContact;
    }
}
