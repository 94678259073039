import { render, staticRenderFns } from "./sub-navigation.component.vue?vue&type=template&id=35a3d52e&scoped=true&"
import script from "./sub-navigation.component.ts?vue&type=script&lang=ts&"
export * from "./sub-navigation.component.ts?vue&type=script&lang=ts&"
import style0 from "./sub-navigation.component.scss?vue&type=style&index=0&id=35a3d52e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35a3d52e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
installComponents(component, {VBtn,VBtnToggle})
