import { render, staticRenderFns } from "./base-header.component.vue?vue&type=template&id=e45f1104&scoped=true&"
import script from "./base-header.component.ts?vue&type=script&lang=ts&"
export * from "./base-header.component.ts?vue&type=script&lang=ts&"
import style0 from "./base-header.component.scss?vue&type=style&index=0&id=e45f1104&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e45f1104",
  null
  
)

export default component.exports