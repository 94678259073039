import { Component, Vue, Prop } from 'vue-property-decorator';
import { Validators } from './validators';

@Component
export default class BaseValidationInput extends Vue {

    @Prop({default: ''}) value!: string;
    @Prop({default: true}) required!: boolean;
    @Prop({default: null}) rules!: any[] | any;
    @Prop({default: 255}) maxLength!: number;

    get validationRules() {
        let rules = [Validators.MaxLength(this.maxLength)];

        if (this.required) {
            rules.push(Validators.Required);
        }

        if (this.rules && Array.isArray(this.rules)) {
            rules = [...rules, ...this.rules];
        }

        return rules;
    }

}
