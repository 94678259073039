import { RequestService } from '@shared/request/request.service';
import { ProductExportFormat } from './models/product-export-format.interface';
import { Product } from '@shared/modules/products/models/product.model';
import { ProductMaterial } from './models/product-material.interface';
import { ProductListItem } from '@shared/modules/products/models/product-list-item.model';
import { FluteDirection } from '@shared/modules/products/enums/flute-direction.enum';
import { Config } from '../../config';
import { ProductTemplateMailRequest } from './models/product-template-mail-request.interface';
import { ProductUrlSourceType } from './enums/product-url-source-type.enum';
import { Routes } from '../../routes.enum';
import { BreadcrumbHistoryItem } from '@shared/components/breadcrumbs/breadcrumb-history-item.interface';
import { BaseConfig } from '@shared/base-config';
import { PrepareEvdRequest } from './models/prepare-evd-request.interface';
import { PrepareEvdResponse } from './models/prepare-evd-response.interface';
import { CostEstimationImportedRequest } from './models/cost-estimation/cost-estimation-imported.request';
import { CostEstimationExistingLayoutRequest } from './models/cost-estimation/cost-estimation-existing-layout.request';
import { CostEstimationExistingDieboardRequest } from './models/cost-estimation/cost-estimation-existing-dieboard.request';
import { OrderImportedRequest } from './models/order/order-imported.request';
import { OrderExistingLayoutRequest } from './models/order/order-existing-layout.request';
import { OrderExistingDieboardRequest } from './models/order/order-existing-dieboard.request';
import store from '../../store/store';
import { Units } from '@shared/modules/diemaker/enums/units-enum';
import { filterParamsMap } from '@shared/modules/products/utils/product.utils';
import { UtilService } from '@shared/utils/util.service';

export class ProductService {

    static getRoute(route: any, type: ProductUrlSourceType): BreadcrumbHistoryItem {

        if (type === ProductUrlSourceType.Favourite) {
            return {
                text: 'products.breadcrumbs.productFavouriteList',
                disabled: false,
                to: route ? route : {
                    name: Routes.ProductFavouriteList
                }
            };
        } else if (type === ProductUrlSourceType.File) {
            return {
                text: 'products.breadcrumbs.uploadFile',
                disabled: false,
                to: {
                    name: Routes.UploadFile
                }
            };
        } else {
            return {
                text: 'products.breadcrumbs.productList',
                disabled: false,
                to: route ? route : {
                    name: Routes.ProductList
                }
            };
        }
    }

    static importProduct(file: File, additionalData: any): Promise<any> {
        const data = {
            ...additionalData,
            file: UtilService.encodeFilename(file)
        };
        return RequestService.post('api/v1/customer/import', data, { multipart: true, apiPath: Config.apiPortal });
    }

    static getLayoutByUuid(id: string, calcInfo: any, currentFluteDir: any, rotate: boolean, extra: any): Promise<any> {
        const data = { fluteDir: currentFluteDir, force90DegRotate: rotate, calcInfo, ...extra };
        return RequestService.post(`api/v1/customer/layout/${id}`, data, { apiPath: Config.apiPortal });
    }

    static getMaterials(): Promise<ProductMaterial[]> {
        return RequestService.get('api/v1/config/materials', {unit: this.getSelectedUnits()});
    }

    static getProductsPage(page: number, itemsPerPage: number, params: any): Promise<{ templates: ProductListItem[]; totalNumPages: number; totalCount: number }> {
        delete params.page;
        delete params.size;
        return RequestService.get(`api/v1/templates/${page}/${itemsPerPage}`, params)
            .then(resp => {
                return {
                    totalNumPages: resp.totalNumPages,
                    templates: resp.templates.map(((p: any) => new ProductListItem(p))),
                    totalCount: resp.totalCount
                };
            });
    }

    static getProductsPageForCustomLibrary(page: number, itemsPerPage: number, params: any): Promise<{ templates: ProductListItem[]; totalNumPages: number; totalCount: number }> {
        delete params.page;
        delete params.size;
        params.customLibraryUUID = params.material;
        delete params.material;
        return RequestService.get(`api/v1/custom-library/${page}/${itemsPerPage}`, params)
            .then(resp => {
                return {
                    totalNumPages: resp.totalNumPages,
                    templates: resp.customTemplates.map(((p: any) => new ProductListItem(p))),
                    totalCount: resp.totalCount
                };
            });
    }

    static resize(id: number, paramMap: any, material: ProductMaterial, fluteDir: FluteDirection): Promise<Product> {
        return RequestService.post(`api/v1/templates/resize/${id}`, { paramMap: filterParamsMap(paramMap), material, fluteDir }).then(resp => new Product(resp));
    }

    static getExportFormats(): Promise<ProductExportFormat[]> {
        return RequestService.get(`api/v1/config/export-formats`);
    }

    static export(id: number, paramMap: any, exportFormat: string, material: ProductMaterial, fluteDir: FluteDirection): Promise<any> {
        return RequestService.post(`api/v1/templates/export/${id}`, { paramMap: filterParamsMap(paramMap), exportFormat, material, fluteDir });
    }

    static getSheetLayout(id: number, calcInfo: any, currentFluteDir: any, rotate: boolean, resizeParams: any) {
        resizeParams.paramMap = filterParamsMap(resizeParams.paramMap);
        return RequestService.post(`api/v1/templates/layout/${id}`, { fluteDir: currentFluteDir, force90DegRotate: rotate, calcInfo, ...resizeParams });
    }

    static costEstimation(id: number, resizeParams: any, layoutParams: any, costEstimationParams: any) {
        resizeParams.paramMap = filterParamsMap(resizeParams.paramMap);
        return RequestService.post(`api/v1/customer/cost-estimation-from-template/${id}`, { ...resizeParams, ...layoutParams, ...costEstimationParams }, {
            apiPath: BaseConfig.apiPortal
        });
    }

    static costEstimationFromOneUpFile(id: number, request: CostEstimationImportedRequest) {
        return RequestService.post(`api/v1/customer/cost-estimation/${id}`, request, {
            apiPath: BaseConfig.apiPortal
        });
    }

    static costEstimationFromLayoutFile(id: number, request: CostEstimationExistingLayoutRequest) {
        return RequestService.post(`api/v1/customer/cost-estimation/from-existing-layout/${id}`, request, {
            apiPath: BaseConfig.apiPortal
        });
    }

    static costEstimationFromDiebiardFile(id: number, request: CostEstimationExistingDieboardRequest) {
        return RequestService.post(`api/v1/customer/cost-estimation/from-existing-dieboard/${id}`, request, {
            apiPath: BaseConfig.apiPortal
        });
    }

    static makeOrder(data: any): Promise<any> {
        data.paramMap = filterParamsMap(data.paramMap);
        return RequestService.post(`api/v1/order`, data, { apiPath: Config.apiPortal });
    }

    static makeOrderForFromFile(uuid: string, data: OrderImportedRequest): Promise<any> {
        return RequestService.post(`api/v1/order/from-import/${uuid}`, data, { apiPath: Config.apiPortal });
    }

    static makeOrderForFromLayoutFile(uuid: string, data: OrderExistingLayoutRequest): Promise<any> {
        return RequestService.post(`api/v1/order/from-import/existing-layout/${uuid}`, data, { apiPath: Config.apiPortal });
    }

    static makeOrderForFromDieboardFile(uuid: string, data: OrderExistingDieboardRequest): Promise<any> {
        return RequestService.post(`api/v1/order/from-import/existing-dieboard/${uuid}`, data, { apiPath: Config.apiPortal });
    }

    static getSheetCatalog(unit: Units): Promise<any> {
        return RequestService.get(`api/v1/config/sheet-catalog`, {measurementUnit: unit});
    }

    static registerCostEstimation(diemakerId: string): Promise<any> {

        const data = {
            diemaker_uuid: diemakerId
        };

        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded'
        };

        return RequestService.post('api/v1/customer/register-cost-estimation', data, { apiPath: Config.apiPortal, headers });
    }

    static getMaterialTypes(unit: Units): Promise<any> {
        return RequestService.get(`api/v1/config/materials-by-type`, {unit});
    }

    static startCADSession(): Promise<any> {
        const params = new URLSearchParams();
        params.append('start', 'true');

        return RequestService.post('api/v1/templates/start-session', params, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
            }
        });
    }

    static endCADSession(unit: Units): Promise<any> {
        const options = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
            }
        };
        return RequestService.post('api/v1/templates/end-session', { measurementUnit: unit }, options);
    }

    static getDieboardCatalog(measurementUnit: Units): Promise<any> {
        return RequestService.get('api/v1/config/dieboard-catalog', {measurementUnit});
    }

    static async getCustomerDieboards(measurementUnit: Units, rotary: boolean): Promise<any> {
        const resp = await RequestService.get('api/v1/customer/customer-diecutters', {measurementUnit, isRotary: rotary}, { apiPath: Config.apiPortal });
        return resp.filter((r: {enabled: boolean, custom: boolean}) => (r.custom && r.enabled) || !r.custom);
    }

    static sendEmail(id: string, mailRequest: ProductTemplateMailRequest, data: any): Promise<Product> {
        data.paramMap = filterParamsMap(data.paramMap || {});
        return RequestService.post(`api/v1/templates/resize/send/email/${id}`, { ...data, ...mailRequest });
    }

    static getFavoritesPage(page: number, itemsPerPage: number, params: any): Promise<{ templates: ProductListItem[]; totalNumPages: number; totalCount: number }> {
        delete params.page;
        delete params.size;
        return RequestService.get(`api/v1/customer/favorites/${page - 1}/${itemsPerPage}`, params, { apiPath: Config.apiPortal })
            .then(resp => {
                return {
                    totalNumPages: resp.totalSize > resp.pageSize ? Math.floor(resp.totalSize / resp.pageSize) + 1 : 1,
                    templates: resp.favorites.map(((p: any) => new ProductListItem(p))),
                    totalCount: resp.totalSize
                };
            });
    }

    static addFavorite(id: string, measurementUnit: Units): Promise<any> {
        const data = {
            template_library_id: id,
            measurementUnit
        };
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded'
        };
        return RequestService.post(`api/v1/customer/add-favorite`, data, { apiPath: Config.apiPortal, headers });
    }

    static removeFavorite(id: string, measurementUnit: Units): Promise<any> {
        return RequestService.delete(`api/v1/customer/delete-favorite?template_library_id=${encodeURIComponent(id)}&measurementUnit=${measurementUnit}`, { apiPath: Config.apiPortal });
    }

    static isFavorite(id: string, measurementUnit: Units): Promise<boolean> {
        const data = {
            template_library_id: id,
            measurementUnit
        };
        return RequestService.get(`api/v1/customer/is-favorite`, data, { apiPath: Config.apiPortal }).then(r => r.value === 'true');
    }

    static prepareEvd(uuid: string, data: PrepareEvdRequest): Promise<PrepareEvdResponse> {
        return RequestService.post(`api/v1/customer/prepare/${uuid}`, data, { apiPath: Config.apiPortal });
    }

    private static getSelectedUnits(): Units {
        if (store.getters['diemakerUnits'] === Units.BOTH) {
            return store.getters['templateUnit'];
        } else {
            return store.getters['diemakerUnits'];
        }
    }

}
