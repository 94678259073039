import { Component, Vue } from 'vue-property-decorator';
import { Routes } from '../../../../routes.enum';
import FormErrorMessage from '@shared/components/form-error-message/form-error-message.component.vue';
import LoadingButton from '@shared/components/loading-button/loading-button.component.vue';
import CustomInput from '@shared/components/custom-input/custom-input.component.vue';
import { Validators } from '@shared/validation/validators';

@Component({
    components: {
        FormErrorMessage,
        CustomInput,
        LoadingButton
    }
})
export default class UserRegister extends Vue {

    email = '';
    companyName = '';
    contactPerson = '';
    password = '';
    isError = false;
    errorMessage = '';
    userAlreadyRegisteredError = false;

    readonly Validators = Validators;
    readonly Routes = Routes;

    async submit(stopLoading: () => void) {
        this.isError = false;
        this.userAlreadyRegisteredError = false;

        if (this.validate()) {
            try {
                await this.$store.dispatch('register', {
                    email: this.email,
                    companyName: this.companyName,
                    contactPerson: this.contactPerson,
                    password: this.password
                });

                this.$router.push({name: Routes.UserResendActivation, query: {redirectTo: this.redirectTo}});

            } catch (e) {
                if (e.code === 9) {
                    this.userAlreadyRegisteredError = true;
                } else {
                    this.isError = true;
                    this.errorMessage = `errors.${e.code}`;
                }
            }
            stopLoading();
        } else {
            stopLoading();
        }
    }

    get redirectTo(): string {
        return this.$route.query.redirectTo as string;
    }

    private validate() {
        return (this.$refs.form as any).validate();
    }
}
