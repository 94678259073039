import { Component, Vue } from 'vue-property-decorator';
import ProductSection from '@shared/modules/products/components/product-section/product-section.component.vue';
import { Validators } from '@shared/validation/validators';
import { ProductUrlSourceType } from '../../../../enums/product-url-source-type.enum';
import { Units } from '@shared/modules/diemaker/enums/units-enum';
import { MathService } from '@shared/utils/math.service';

@Component({
    components: {
        ProductSection
    }
})

export default class GapOptions extends Vue {
    readonly Validators = Validators;

    setGapX(gapX: number): void {
        this.$store.commit('productLayout/setGapX', gapX);
        this.setDirty();
    }

    setGapY(gapY: number): void {
        this.$store.commit('productLayout/setGapY', gapY);
        this.setDirty();
    }

    setRotate(rotate: boolean): void {
        this.$store.commit('productLayout/setRotate', rotate);
        this.setDirty();
    }

    setDirty() {
        this.$store.commit('productLayout/setDirty', true);
    }

    get rotate(): boolean {
        return this.$store.getters['productLayout/rotate'];
    }

    get gapX(): number {
        return this.$store.getters['productLayout/gapX'];
    }

    get gapY(): number {
        return this.$store.getters['productLayout/gapY'];
    }

    get isFile(): boolean {
        return this.$route.params.type === ProductUrlSourceType.File;
    }

    get selectedUnit(): Units {
        return !this.isFile ? this.$store.getters['templateUnit'] : this.$store.getters['uploadFile/unit'];
    }

    formatField($event: any, setter: (value: any) => void) {
        if (this.selectedUnit === Units.INCH) {
            try {
                $event.target.value = MathService.calculate($event.target.value);
                setter($event.target.value);
            } catch (ex) {}
        }
    }

    get validators() {
        if (this.selectedUnit === Units.MM) {
            return [Validators.MaxLength(5)];
        } else {
            return [Validators.InchParam];
        }
    }
}
