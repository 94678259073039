import { MeasLine } from './meas-line.type';
import { FluteDirection } from '@shared/modules/products/enums/flute-direction.enum';
import { BaseConfig } from '@shared/base-config';
import store from '../../../../projects/customer/src/store/store';
import { UtilService } from '@shared/utils/util.service';
import { i18n } from '@shared/plugins/i18n';

export class ProductDrawing {

    id: number;
    description: string;
    evDrawingId: number;
    name: string;
    svgURL: string;
    measlines: MeasLine[];
    fluteDirection: FluteDirection;
    layoutPatterns: Array<{id: string; name: string}>;

    constructor({dbId, description, evDrawingId, name, svgURL, measlines, fluteDir, layoutPatterns}: any = {}) {

        const language = store.getters['language'];

        this.id = dbId;
        this.fluteDirection = fluteDir;
        this.description = description;
        this.evDrawingId = evDrawingId;
        this.name = name;
        this.svgURL = `${BaseConfig.apiWebStandardsLibrary}api/v1/files/${encodeURI(svgURL)}?t=${Date.now()}`;
        this.measlines = measlines;

        if (layoutPatterns) {
            this.layoutPatterns = layoutPatterns.map((p: any) => {
                return {
                  id: p.id,
                  name: UtilService.getLocalizedName(p.names, language)
                };
            });

        } else {
            this.layoutPatterns = [];
        }

        this.layoutPatterns.unshift({name: i18n.t('common.default') as string, id: ''});
    }
}
