import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class CustomerTableDefaultIndicator extends Vue {
    @Prop() isDefault!: boolean;

    setDefault() {
        this.$emit('setDefault');
    }
}
