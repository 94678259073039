import { Component, Vue } from 'vue-property-decorator';

import { Diemaker } from '@shared/modules/diemaker/models/diemaker.model';
import { DiemakerService } from '../../services/diemakers.service';
import { Routes } from '../../../../routes.enum';
import DiemakerDetails from '../diemaker-details/diemaker-details.component.vue';
import LoadingButton from '@shared/components/loading-button/loading-button.component.vue';

@Component({
    components: {
        DiemakerDetails,
        LoadingButton
    }
})
export default class MyDiemakers extends Vue {
    diemakers: Diemaker[] = [];
    selectedDiemaker: Diemaker | null = null;
    selectedDiemakerVisible = false;

    readonly Routes = Routes;

    created() {
        this.getDiemakers();
    }

    async getDiemakers() {
        this.diemakers = await DiemakerService.getDiemakers();
    }

    showDiemakerInfo(diemaker: Diemaker) {
        this.selectedDiemaker = diemaker;
        this.selectedDiemakerVisible = true;
    }

    async setAsCurrent(stopLoading: () => void) {
        await this.$store.dispatch('setUserCurrentDiemaker', this.selectedDiemaker?.uuid as string);
        stopLoading();
        this.$store.dispatch('notification/showSuccess', {
            text: this.$i18n.t('profile.diemakerDetails.messages.setAsCurrent', {name: this.selectedDiemaker?.name})});

        if (this.selectedDiemaker) {
            const currentDiemaker = this.diemakers.find(d => d.isCurrent);
            if (currentDiemaker) {
                currentDiemaker.isCurrent = false;
            }
            this.selectedDiemaker.isCurrent = true;
        }
        this.closeDiemakerInfo();

    }

    private closeDiemakerInfo() {
        this.selectedDiemaker = null;
        this.selectedDiemakerVisible = false;
    }
}
