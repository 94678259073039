import { render, staticRenderFns } from "./diecutter-info.component.vue?vue&type=template&id=07c1ef74&scoped=true&"
import script from "./diecutter-info.component.ts?vue&type=script&lang=ts&"
export * from "./diecutter-info.component.ts?vue&type=script&lang=ts&"
import style0 from "./diecutter-info.component.scss?vue&type=style&index=0&id=07c1ef74&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07c1ef74",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VCol,VSimpleTable})
