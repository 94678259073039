import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Config } from '../../config';
import { TranslateResult } from 'vue-i18n';
import BaseHeader from '@shared/components/base-header/base-header.component.vue';
import { Routes } from '../../routes.enum';
import { BaseConfig } from '@shared/base-config';
import { SelectFieldItem } from '@shared/components/select-field/select-field-item.interface';
import { Theme } from '@shared/themes/theme.enum';

@Component({
    components: {
        BaseHeader
    }
})
export default class Header extends Vue {

    theme = Config.theme;

    readonly Route = Routes;

    onLangChange(lang: string) {
        this.$store.commit('setLanguage', lang);
    }

    get language(): string {
        return this.$store.getters['language'];
    }

    get items(): Array<{ tab: TranslateResult; name: string }> {

        let tabs: Array<{tab: TranslateResult, name: Routes}> = [];

        if (!this.isWorkAs) {
            tabs = [{tab: this.$i18n.t('header.products'), name: Routes.NewRequest}];

            if (this.isLogged && !this.isWorkAs) {
                tabs.push({tab: this.$i18n.t('header.myOrders'), name: Routes.OrderRoot});
            }
        } else {
            tabs = [
                {tab: this.$i18n.t('header.workAs.library'), name: Routes.ProductList},
                {tab: this.$i18n.t('header.workAs.favorites'), name: Routes.ProductFavouriteList}
            ];
        }

        return tabs;
    }

    get languages(): SelectFieldItem[] {
        return Object.keys(BaseConfig.availableLanguages).map((l: any) => {
            return {
                value: l,
                text: (BaseConfig.availableLanguages as any)[l],
            };
        });
    }

    get isLogged(): boolean {
        return this.$store.getters['auth/isAuthenticated'];
    }

    get logoClickUrl(): string | null {
        return this.theme !== Theme.PoligrafBG ? this.$store.getters.diemaker?.website : null;
    }

    get logoUrl(): string | null {
        if (this.theme !== Theme.PoligrafBG) {
            return this.$store.getters.diemaker?.logoUrl;
        } else {
            return null;
        }
    }

    get isWorkAs(): boolean {
        return this.$store.getters['auth/isAuthenticated'] && this.$store.getters['auth/customerWorkAsId'];
    }

    goToDiemaker() {
        this.$store.dispatch('auth/revertWorkAsCustomer');
    }
}
