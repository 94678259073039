import { FluteDirection } from '@shared/modules/products/enums/flute-direction.enum';
import { i18n } from '@shared/plugins/i18n';
import { CuttingRuleInterface } from './cutting-rule.interface';
import { BaseConfig } from '@shared/base-config';
import { OrderExistingLayoutRequest } from '../../../../projects/customer/src/modules/products/models/order/order-existing-layout.request';
import { CostEstimationImportedRequest } from '../../../../projects/customer/src/modules/products/models/cost-estimation/cost-estimation-imported.request';
import { CostEstimationBaseRequest } from '../../../../projects/customer/src/modules/products/models/cost-estimation/cost-estimation-base.request';
import { CostEstimationExistingLayoutRequest } from '../../../../projects/customer/src/modules/products/models/cost-estimation/cost-estimation-existing-layout.request';
import { Customer } from '../../../../projects/diemaker/src/modules/customers/models/customer.model';

export class BaseOrder {

    id: string;
    deliveryDate: Date;
    title: string;
    totalCost: string;
    status: string;
    notes: string;
    layoutSvgFileUrl: string;
    oneupSvgFileUrl: string;
    dieCutter: any;
    material: any;
    name: string;
    rules: CuttingRuleInterface[];
    statusNote: string;
    currency: string;
    grammageNote: string;
    fluteDir!: FluteDirection;
    strippingRulesInfo: any;
    hasCompensatingRules!: boolean;
    hasMaleStrippingDie!: boolean;
    hasFemaleStrippingDie!: boolean;
    hasFrontStripper!: boolean;
    boardName: string;

    constructor({deliveryDate, uuid, boardName, title, totalCost, costEstimationRequestType,
                    name, status, currency, grammageNote, rules, notes, costEstimationRequestFromLayout, costEstimationRequestFromDieboard,
                    layoutSvgFileUrl, oneupSvgFileUrl, costEstimationRequest, statusNote}: any = {}) {
        this.id = uuid;
        this.title = title;
        this.totalCost = totalCost?.toFixed(2);
        this.deliveryDate = deliveryDate;
        this.status = status;
        this.notes = notes;
        this.layoutSvgFileUrl = `${BaseConfig.apiPortal}files/${encodeURI(layoutSvgFileUrl)}`;
        this.oneupSvgFileUrl = `${BaseConfig.apiPortal}files/${encodeURI(oneupSvgFileUrl)}`;
        this.name = name;
        this.rules = rules;
        this.statusNote = statusNote;
        this.currency = currency;
        this.grammageNote = grammageNote;
        this.boardName = boardName;

        if (costEstimationRequestType === 'FromLayoutImport') {
            const request = costEstimationRequestFromLayout as OrderExistingLayoutRequest;
            this.dieCutter = request.drawingInfo?.dieboard;
            this.material = request.material;
            this.fluteDir = request.fluteDir;
            this.strippingRulesInfo = request.strippingRulesInfo;
            this.hasCompensatingRules = request.hasCompensatingRules;
            this.hasMaleStrippingDie = request.hasMaleStrippingDie;
            this.hasFemaleStrippingDie = request.hasFemaleStrippingDie;
            this.hasFrontStripper = request.hasFrontStripper;
        } else if (costEstimationRequestType === 'FromTemplate' || costEstimationRequestType === 'FromOneUpImport') {
            const request = costEstimationRequest as CostEstimationImportedRequest;
            this.dieCutter = request.calcInfo[0]?.sheets[0]?.dieboard;
            this.material = request.material;
            this.fluteDir = request.fluteDir;
            this.strippingRulesInfo = request.strippingRulesInfo;
            this.hasCompensatingRules = request.hasCompensatingRules;
            this.hasMaleStrippingDie = request.hasMaleStrippingDie;
            this.hasFemaleStrippingDie = request.hasFemaleStrippingDie;
            this.hasFrontStripper = request.hasFrontStripper;
        } else if (costEstimationRequestType === 'FromDieboardImport') {
            const request = costEstimationRequestFromDieboard as CostEstimationExistingLayoutRequest;
            this.dieCutter = request.drawingInfo.dieboard;
            this.material = request.material;
            this.fluteDir = request.fluteDir;
            this.hasCompensatingRules = request.hasCompensatingRules;
            this.hasMaleStrippingDie = request.hasMaleStrippingDie;
            this.hasFemaleStrippingDie = request.hasFemaleStrippingDie;
            this.hasFrontStripper = request.hasFrontStripper;
        }
    }

    get grainDirection(): string {
        const dir = this.fluteDir as FluteDirection;

        if (dir === FluteDirection.X) {
            return i18n.t(`dropdowns.fluteDirections.X`) as string;
        } else if (dir === FluteDirection.Y) {
            return i18n.t(`dropdowns.fluteDirections.Y`) as string;
        }

        return '';
    }
}
