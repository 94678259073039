import { Component, Vue } from 'vue-property-decorator';
import Pagination from '@shared/components/pagination/pagination.component.vue';
import { OrderService } from '../../order.service';
import { OrderListItem } from '../../models/order-list-item.model';
import { UtilService } from '@shared/utils/util.service';
import { Routes } from '../../../../routes.enum';
import { BreadcrumbHistoryItem } from '@shared/components/breadcrumbs/breadcrumb-history-item.interface';
import Breadcrumbs from '@shared/components/breadcrumbs/breadcrumbs.component.vue';

@Component({
    components: {
      Breadcrumbs,
      Pagination
    }
})

export default class OrderList extends Vue {

    readonly Routes = Routes;

    page = 1;
    tableLoading = true;
    itemsPerPage = 10;
    totalPages = 0;
    status = 'New';
    search = '';
    breadcrumbs: BreadcrumbHistoryItem[] = [];
    orderService = new OrderService();
    orders: OrderListItem[] = [];
    totalCount = 0;
    filterOrders = [ 
        { name: this.$i18n.t('orders.list.filters.all'), value: '' },
        { name: this.$i18n.t('orders.list.filters.last30Days'), value: this.formatDate(30) },
        { name: this.$i18n.t('orders.list.filters.last7Days'), value: this.formatDate(7) }
    ];
    startDate = '';
    tableHeaders = [
        {
            text: this.$i18n.t('orders.list.table.date'),
            align: 'left',
            value: 'createdDate',
            width: '200px'
        },
        { 
            text: this.$i18n.t('orders.list.table.id'),
            align: 'left',
            value: 'id',
            width: '180px'
        },
        {
            text: this.$i18n.t('orders.list.table.estimatedCost'),
            align: 'right',
            value: 'estimatedCost',
            width: '120px'
        },
        {
            text: this.$i18n.t('orders.list.table.diemaker'),
            value: 'diemakerName',
            width: '220px'
        },
        { 
            text: this.$i18n.t('orders.list.table.title'),
            align: 'left',
            value: 'title'
        }
    ];

    created() {
        this.initBreadcrumbs();

        if (!UtilService.isEmptyOrNull(this.$route.query.page)) {
            this.page = +this.$route.query.page;
        }
      
        if (!UtilService.isEmptyOrNull(this.$route.query.size)) {
            this.itemsPerPage = +this.$route.query.size;
        }
      
        if (!UtilService.isEmptyOrNull(this.$route.query.name)) {
            this.search = this.$route.query.name as string;
        }
        
        if (!UtilService.isEmptyOrNull(this.$route.query.status)) {
            this.status = this.$route.query.status as string;
        }
      
        if (!UtilService.isEmptyOrNull(this.$route.query.startDate)) {
            this.startDate = this.$route.query.startDate as string;
        } else {
            this.startDate = this.filterOrders[0].value;
        }

        this.getOrders();
    }

    async getOrders() {
        const data = this.getSearchData();

        this.tableLoading = true;

        const { orders, totalCount, pageSize, startPage } = await this.orderService.getOrders(this.page, 10, data);

        this.tableLoading = false;

        orders.forEach(order => {
            order.createdDate = UtilService.formatDate(new Date(order.createdDate), this.$store.getters['auth/loggedUser'].dateFormat + ' HH:mm');
        });

        this.orders = orders;
        this.totalCount = totalCount;
        this.itemsPerPage = pageSize;
        this.page = startPage + 1;
        this.totalPages = totalCount === 0 ? 0 : Math.ceil(totalCount / pageSize);
    }

    updateOrders(event: string) {
        this.startDate = event;

        this.getOrders();
        this.updateUrl();
    }

    goToOrderDetails(order: OrderListItem) {
        this.$router.push({name: Routes.OrderDetails, params: {id: order.uuid}});
    }

    updateSearch() {
        this.getOrders();
        this.updateUrl();
    }

    onPageChange(page: number) {
        this.page = page;
        this.updateSearch();
    }

    changeStatus(event: any) {
        this.status = event.currentTarget.value;
        this.page = 1;
        this.getOrders();
        this.updateUrl();
    }

    showPreview(event: any) {
        console.dir(event);
    }

    private getSearchData() {
        const data: any = {
          page: this.page,
          size: this.itemsPerPage,
          startDate: this.startDate
        };
    
        if (!UtilService.isEmptyOrNull(this.search)) {
            data.name = this.search;
        }

        if (!UtilService.isEmptyOrNull(this.status)) {
            data.status = this.status;
        }
    
        return data;
    }

    private updateUrl() {
        this.$router.replace({ name: Routes.OrderList, query: this.getSearchData() as any });
    }

    private formatDate(date: number) {
        const now = new Date();
        now.setDate(now.getDate() - date);

        return UtilService.formatDateYYYYMMDD(now);
    }

    private initBreadcrumbs() {
        this.breadcrumbs = [
          {
            text: 'orders.list.breadcrumbs.orders',
            disabled: false
          }
        ];
      }
}
