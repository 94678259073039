import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import diecutterFilter from '../../filters/die-cutter.filter';
import CustomInput from '@shared/components/custom-input/custom-input.component.vue';
import { Validators } from '@shared/validation/validators';
import ProductCostEstimationRuleRow from '../../components/product-cost-estimation-rule-row/product-cost-estimation-rule-row.component.vue';
import { ProductService } from '../../product.service';
import { Dieboard, DieboardSizeType, DieboardType } from '../../../../store/modules/product-cost-estimation.store';
import { DrawingType } from '@shared/modules/products/enums/drawing-type.enum';
import { ProductUrlSourceType } from '../../enums/product-url-source-type.enum';
import { DiemakerRuleMainType } from '@shared/modules/products/enums/diemaker-rule-main-type.enum';
import { Units } from '@shared/modules/diemaker/enums/units-enum';
import { UtilService } from '@shared/utils/util.service';
import { MathService } from '@shared/utils/math.service';

@Component({
    components: {
        CustomInput,
        ProductCostEstimationRuleRow
    },
    filters: {
        diecutterFilter,
    }
})
export default class CostEstimationDieboard extends Vue {
    @Prop() ruleType!: DiemakerRuleMainType;

    readonly Validators = Validators;
    readonly DieboardSizeType = DieboardSizeType;
    readonly DieboardType = DieboardType;
    readonly input = this.selectedUnit === Units.INCH ? 'decimal' : 'numeric';

    dieboardCatalog = [];

    async created() {
        this.loadDiecutters();
    }

    setDirty() {
        this.$store.commit('productCostEstimation/setDirty', true);
    }

    validate() {
        return (this.$refs.form as any).validate();
    }

    setDieboardWidth(width: number) {
        this.setDieboard({width});
    }

    setDieboardHeight(height: number) {
        this.setDieboard({height});
    }

    setDieboardMarginL(marginL: number) {
        this.setDieboard({marginL});
    }

    setDieboardMarginR(marginR: number) {
        this.setDieboard({marginR});
    }

    setDieboardMarginB(marginB: number) {
        this.setDieboard({marginB});
    }

    setDieboardMarginT(marginT: number) {
        this.setDieboard({marginT});
    }

    setSizeType(sizeType: DieboardSizeType) {
        this.setDieboard({sizeType});
    }

    setUserDefined(userDefined: string) {
        this.setDieboard({userDefined});
    }

    setDieboardType(type: DieboardType) {
        this.setDieboard({type});
        this.onDieboardTypeChange();
    }

    setDieCutter(dieCutter: string) {
        this.setDieboard({dieCutter});
        if (dieCutter && dieCutter.length > 0) {
            this.handleDieCutterChange(dieCutter);
            this.$emit('calculateCost');
        } else {
            this.$store.commit('productCostEstimation/clearDieboard');
        }

    }

    get formValid() {
        return this.$store.getters['productCostEstimation/diecutterFormValid'];
    }

    set formValid(valid: boolean) {
        this.$store.commit('productCostEstimation/setDiecutterFormValid', valid);
    }

    get marginRules() {
        const rules = [];

        if (this.dieboardType === DieboardType.UserDefined || (this.dieboardType === DieboardType.Diecutter && this.dieCutter)) {
            rules.push(Validators.Required);
        }
        if (this.selectedUnit !== Units.INCH) {
            rules.push(Validators.MaxLength(5));
        } else {
            rules.push(Validators.InchParam);
        }

        return rules;
    }

    get dieCutter() {
        return this.dieboard.dieCutter;
    }

    get userDefined() {
        return this.dieboard.userDefined;
    }

    get isAutoSize(): boolean {
        return this.dieboard.sizeType === DieboardSizeType.Auto;
    }

    get sizeType(): DieboardSizeType {
        return this.dieboard.sizeType;
    }

    get dieboardType(): DieboardType {
        return this.dieboard.type;
    }

    get dieboard(): Dieboard {
        return this.$store.getters['productCostEstimation/dieboard'];
    }

    get isDieboardFile() {
        return this.isFile && this.$store.getters['uploadFile/type'] === DrawingType.Dieboard;
    }

    get isFile(): boolean {
        return this.$route.params.type === ProductUrlSourceType.File;
    }

    get widthHeightRules(): any[] {
        const rules = [
            (v: any) => {
                if (this.isAutoSize || this.isDieCutterSelected) {
                    return true;
                }
                return (v !== null && v >= 0 && v !== '') || this.$i18n.t('validation.required');
            }
        ];
        if (this.selectedUnit !== Units.INCH) {
            rules.push(Validators.MaxLength(5));
        } else {
            rules.push(Validators.InchParam);
        }
        return rules;
    }

    get isDieCutterSelected(): boolean {
        return this.dieboardType === 'diecutter';
    }

    get selectedUnit(): Units {
        return !this.isFile ? this.$store.getters['templateUnit'] : this.$store.getters['uploadFile/unit'];
    }

    @Watch('ruleType')
    onRuleTypeChange() {
        this.loadDiecutters();
    }

    formatField($event: any, setter: (value: any) => void) {
        if (this.selectedUnit === Units.INCH) {
            try {
                $event.target.value = MathService.calculate($event.target.value);
                setter($event.target.value);
            } catch (ex) {}
        }
    }

    private onDieboardTypeChange() {
        this.$store.commit('productCostEstimation/clearDieboardDiecutter');

        if (this.isDieCutterSelected) {
            this.$store.commit('productCostEstimation/clearDieboard');
        }
    }

    private handleDieCutterChange(dieCutter: string) {
        const {
            bottomMargin,
            leftMargin,
            rightMargin,
            topMargin,
            maxWidth,
            maxHeight,
            dieboardType,
            custom
        } = this.dieboardCatalog.find((item: any) => item.sheetId === dieCutter) as any;

        this.setDieboard({
            width: UtilService.formatNumber(maxWidth, this.selectedUnit),
            height: UtilService.formatNumber(maxHeight, this.selectedUnit),
            marginL: UtilService.formatNumber(leftMargin, this.selectedUnit),
            marginR: UtilService.formatNumber(rightMargin, this.selectedUnit),
            marginB: UtilService.formatNumber(bottomMargin, this.selectedUnit),
            marginT: UtilService.formatNumber(topMargin, this.selectedUnit),
            custom,
            sizeType: dieboardType.includes('FixedSize') ? DieboardSizeType.Fixed : DieboardSizeType.Auto
        });
    }

    private setDieboard(value: Partial<Dieboard>) {
        this.$store.commit('productCostEstimation/setDieboard', value);
        this.setDirty();
    }

    private async loadDiecutters() {
        if (this.ruleType === DiemakerRuleMainType.Flat) {
            this.dieboardCatalog = await ProductService.getCustomerDieboards(this.selectedUnit, false);
        } else {
            this.dieboardCatalog = await ProductService.getCustomerDieboards(this.selectedUnit, true);
        }

        if (!this.dieboardCatalog.length) {
            this.dieboardCatalog = await ProductService.getDieboardCatalog(this.selectedUnit);
        } else if (!this.dieboard.dieCutter) {
            const defaultCutter: { sheetId: string } | any = this.dieboardCatalog.find((d: { default: boolean }) => d.default);

            if (defaultCutter) {
                this.setDieboard({dieCutter: defaultCutter.sheetId});
                this.handleDieCutterChange(defaultCutter.sheetId);
                this.$emit('defaultSet');
            }
        }
    }

}
