import { RuleType } from '@shared/modules/products/models/rule-type.enum';
import { CustomerRule } from '@shared/modules/products/models/customer-rule.interface';
import { RequestService } from '@shared/request/request.service';
import { BaseConfig } from '@shared/base-config';
import { CustomerDieboard } from '@shared/modules/products/models/customer-dieboard.model';
import { Config } from '../../config';
import { CustomerDiecutter } from '@shared/modules/products/models/customer-diecutter.model';
import { DiecutterTableItem } from './models/diecutter-table-item.model';
import { CreateDiecutterRequest } from './models/create-diecutter.model';
import { UpdateDiecutterRequest } from './models/update-diecutter.model';
import { DiecutterBase } from './models/diecutter-base.model';
import { DieboardType } from '@shared/modules/products/models/dieboard-type.enum';
import { Units } from '@shared/modules/diemaker/enums/units-enum';

export class ProfileService {

    private static diecutterCatalogResponse: DiecutterBase[] | null = null;

    static async getRulesByType(rotary = false, diemakerId: string): Promise<Array<{ type: RuleType, rules: CustomerRule[] }>> {

        const {rulesByType} = await RequestService.get(`api/v1/customer/customer-rules`, {
            diemakerUuid: diemakerId,
            rotary
        }, {apiPath: BaseConfig.apiPortal});

        const grouped = [
            {type: RuleType.Cut, rules: []},
            {type: RuleType.Crease, rules: []},
            {type: RuleType.Perfo, rules: []},
            {type: RuleType.CutCrease, rules: []},
        ];

        for (const {type, rules} of rulesByType) {
            const index = grouped.findIndex((g) => g.type === type);

            const rule = (rules as CustomerRule[]).find((r) => !r.selectedForCustomer && r.default);

            if (rule) {
                rule.default = false;
            }

            grouped[index].rules = rules;
        }

        return grouped;
    }

    static saveRule(rule: CustomerRule, diemakerId: string): Promise<any> {
        const data = {
            diemakerUuid: diemakerId,
            ruleId: rule.id,
            default: rule.default
        };

        if (rule.selectedForCustomer) {
            return RequestService.post(`api/v1/customer/customer-rule`, data, {apiPath: Config.apiPortal});
        } else {
            return RequestService.delete(`api/v1/customer/customer-rule?diemakerUuid=${diemakerId}&ruleId=${rule.id}`, {apiPath: Config.apiPortal});
        }
    }

    static async getDieboards(rotary = false, diemakerId: string): Promise<CustomerDieboard[]> {
        const dieboards = await RequestService.get(`api/v1/customer/customer-dieboards`, {
            diemakerUuid: diemakerId,
            rotary
        }, {apiPath: BaseConfig.apiPortal});

        const dieboard = dieboards.find((d: CustomerDieboard) => !d.selectedForCustomer && d.default);

        if (dieboard) {
            dieboard.default = false;
        }

        return dieboards;
    }

    static saveDieboard(dieboard: CustomerDieboard, customerId: string): Promise<any> {
        if (dieboard.selectedForCustomer) {
            const data = {
                diemakerUuid: customerId,
                boardId: dieboard.dbId,
                default: dieboard.default
            };
            return RequestService.post(`api/v1/customer/customer-dieboard`, data, {apiPath: Config.apiPortal});
        } else {
            return RequestService.delete(`api/v1/customer/customer-dieboard?diemakerUuid=${customerId}&boardId=${dieboard.dbId}`, {apiPath: Config.apiPortal});
        }
    }

    static async getDiecutters(measurementUnit: Units, rotary: boolean): Promise<DiecutterTableItem[]> {
        const mergedCutters: DiecutterTableItem[] = [];
        const customerDiecuttersPromise: Promise<CustomerDiecutter[]> = RequestService.get(`api/v1/customer/customer-diecutters`, {
            measurementUnit,
            isRotary: rotary
        }, {apiPath: Config.apiPortal});
        const dieCutterCatalogPromise: Promise<DiecutterBase[]> = !this.diecutterCatalogResponse ?
            RequestService.get('api/v1/config/dieboard-catalog', {measurementUnit}) :
            Promise.resolve(this.diecutterCatalogResponse);

        const [customerDiecutters, dieCutterCatalog]: [CustomerDiecutter[], DiecutterBase[]] = await Promise.all([customerDiecuttersPromise, dieCutterCatalogPromise]);

        this.diecutterCatalogResponse = dieCutterCatalog;

        const filteredDiecutters = dieCutterCatalog.filter((d: DiecutterBase) => {
            if (rotary) {
                return d.dieboardType === DieboardType.RotaryFixedSize || d.dieboardType === DieboardType.RotaryAutoSize;
            } else {
                return d.dieboardType === DieboardType.FixedSize || d.dieboardType === DieboardType.AutoSize;
            }
        });

        for (const catalogItem of filteredDiecutters) {

            const foundSameItem = customerDiecutters.find(item => item.sheetId === catalogItem.sheetId);
            if (!foundSameItem) {
                mergedCutters.push({
                    sheetId: catalogItem.sheetId,
                    default: false,
                    name: catalogItem.name,
                    selectedForCustomer: false,
                    bottomMargin: catalogItem.bottomMargin,
                    leftMargin: catalogItem.leftMargin,
                    maxHeight: catalogItem.maxHeight,
                    maxWidth: catalogItem.maxWidth,
                    rightMargin: catalogItem.rightMargin,
                    topMargin: catalogItem.topMargin,
                    dieboardType: catalogItem.dieboardType,
                    enabled: true,
                    rotary
                });
            } else {
                mergedCutters.push({
                    id: foundSameItem.id,
                    sheetId: foundSameItem.sheetId,
                    default: foundSameItem.default,
                    name: foundSameItem.name,
                    selectedForCustomer: true,
                    bottomMargin: foundSameItem.bottomMargin,
                    leftMargin: foundSameItem.leftMargin,
                    maxHeight: foundSameItem.maxHeight,
                    maxWidth: foundSameItem.maxWidth,
                    rightMargin: foundSameItem.rightMargin,
                    topMargin: foundSameItem.topMargin,
                    dieboardType: foundSameItem.dieboardType,
                    custom: foundSameItem.custom,
                    enabled: foundSameItem.enabled,
                    rotary
                });
            }
        }

        for (const item of customerDiecutters.filter(d => d.custom)) {
            mergedCutters.push({
                id: item.id,
                sheetId: item.sheetId,
                default: item.default,
                name: item.name,
                selectedForCustomer: true,
                bottomMargin: item.bottomMargin,
                leftMargin: item.leftMargin,
                maxHeight: item.maxHeight,
                maxWidth: item.maxWidth,
                rightMargin: item.rightMargin,
                topMargin: item.topMargin,
                dieboardType: item.dieboardType,
                custom: true,
                enabled: item.enabled,
                rotary: item.rotary
            });
        }

        return mergedCutters;
    }

    static async handleCreateDeleteCustomerDiecutter(item: DiecutterTableItem, unit: Units): Promise<void> {
        if (item.id) {
            if (item.custom) {

                if (!item.enabled && item.default) {
                    item.enabled = true;
                    item.selectedForCustomer = true;
                }

                const updateDto = {...item, selectedForCustomer: undefined, measurementUnit: unit} as UpdateDiecutterRequest;
                await ProfileService.updateCustomerDiecutter(updateDto);
            } else {
                if (item.selectedForCustomer) {
                    const updateDto = {
                        ...item,
                        selectedForCustomer: undefined,
                        enabled: true,
                        measurementUnit: unit
                    } as UpdateDiecutterRequest;
                    await ProfileService.updateCustomerDiecutter(updateDto);
                } else {
                    await ProfileService.deleteCustomerDiecutter(item.id as number);
                }
            }
        } else {
            const requestItem: CreateDiecutterRequest = {
                bottomMargin: item.bottomMargin,
                leftMargin: item.leftMargin,
                maxHeight: item.maxHeight,
                maxWidth: item.maxWidth,
                name: item.name,
                rightMargin: item.rightMargin,
                topMargin: item.topMargin,
                sheetId: item.sheetId,
                default: item.default,
                custom: false,
                dieboardType: item.dieboardType,
                enabled: true,
                rotary: item.rotary,
                measurementUnit: unit
            };
            await ProfileService.createCustomerDiecutter(requestItem);
        }
    }

    static createCustomerDiecutter(item: CreateDiecutterRequest): Promise<{ value: string }> {
        return RequestService.post('api/v1/customer/create-customer-diecutter', item, {apiPath: Config.apiPortal});
    }

    static deleteCustomerDiecutter(diecutterId: number): Promise<void> {
        return RequestService.delete(`api/v1/customer/delete-customer-diecutter?cutomerDiecutterID=${diecutterId}`, {apiPath: Config.apiPortal});
    }

    static updateCustomerDiecutter(updatedDieCutter: UpdateDiecutterRequest): Promise<void> {
        return RequestService.put('api/v1/customer/update-customer-diecutter', updatedDieCutter, {apiPath: Config.apiPortal});
    }

    static enableCustomerDiecutter(diecutterId: number, enable = true): Promise<void> {
        const data = {
            customerDiecutterID: diecutterId,
        };
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded'
        };
        if (enable) {
            return RequestService.post('api/v1/customer/enable-customer-diecutter', data, {
                apiPath: Config.apiPortal,
                headers
            });
        } else {
            return RequestService.post('api/v1/customer/disable-customer-diecutter', data, {
                apiPath: Config.apiPortal,
                headers
            });
        }
    }
}
