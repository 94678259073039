import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Icon from '@shared/components/icon/icon.component.vue';
import { Validators } from '@shared/validation/validators';
import { FluteDirection } from '@shared/modules/products/enums/flute-direction.enum';
import { SelectFieldItem } from '@shared/components/select-field/select-field-item.interface';
import { ProductService } from '../../product.service';
import { UploadFileMaterialInfo } from '../../../../store/modules/upload-file.store';
import { UtilService } from '@shared/utils/util.service';

@Component({
    components: {
        Icon
    }
})
export default class UploadFileMaterial extends Vue {
    @Prop() selectedDrawing!: any;

    availableMaterials = [];
    grammageNote = '';
    thickness: string | undefined = '';
    fluteDirection: string | undefined = '';
    material: string | undefined = '';
    materialType: string | undefined = '';
    materialTypes: SelectFieldItem[] = [];

    readonly Validators = Validators;
    readonly fluteDirections: Array<{ text: string; value: FluteDirection }> = [
        {text: this.$i18n.t(`dropdowns.fluteDirections.FD_unknown`) as string, value: FluteDirection.Unknown},
        {text: this.$i18n.t(`dropdowns.fluteDirections.X`) as string, value: FluteDirection.X},
        {text: this.$i18n.t(`dropdowns.fluteDirections.Y`) as string, value: FluteDirection.Y},
    ];

    private allMaterialTypes: any = {};

    async created() {
        await this.loadMaterials();
        if (this.importResponse) {
            this.loadSavedParams();
        }
    }

    get importResponse() {
        return this.$store.getters['uploadFile/uploadFileResponse'];
    }

    get drawingsTabs() {
        return this.$store.getters['uploadFile/drawingsTabs'];
    }

    get unit() {
        return this.$store.getters['uploadFile/unit'];
    }

    @Watch('importResponse')
    onImportResponseChange(importResponse: any) {
        if (!importResponse) {
            return;
        }
        const materialInfo = importResponse.materialInfo as UploadFileMaterialInfo;
        this.material = '';
        this.thickness = '';
        this.grammageNote = '';
        this.fluteDirection = this.setFluteDirection((this.drawingsTabs[0].layout ?? this.drawingsTabs[0].drawing).flutedir);

        const type = materialInfo.types[materialInfo.types.length - 1];

        if (!this.materialTypes.find((t) => t.value === type)) {
            this.materialTypes.push({
                text: type,
                value: type
            });

            this.allMaterialTypes[type] = [
                {
                    value: type,
                    text: type,
                    grammage: materialInfo.grammage,
                    thickness: materialInfo.thickness
                }
            ];
        }

        this.materialType = type;
        Vue.nextTick(() => {
            this.material = materialInfo.name;
        });
    }

    @Watch('selectedDrawing', {immediate: true})
    onSelectedDrawingChange(selectedDrawing: any) {
        if (!this.fluteDirection && selectedDrawing) {
            this.fluteDirection = this.setFluteDirection((selectedDrawing.layout ?? selectedDrawing.drawing).flutedir);
        }
    }

    @Watch('material')
    onMaterialChange(material: string) {
        const _material: any = this.availableMaterials.find(((m: any) => m.value === material));
        this.grammageNote = _material?.grammage ?? '';
        this.thickness = _material?.thickness ? UtilService.formatNumber(_material?.thickness, this.unit) + '' : '';
    }

    @Watch('materialType')
    onMaterialTypeChange(materialType: string) {
        this.availableMaterials = this.allMaterialTypes[materialType];
    }

    @Watch('unit')
    async onUnitChange() {
        if (this.unit) {
            await this.loadMaterials();
            this.material = undefined;
            this.thickness = undefined;
            this.fluteDirection = undefined;
        }
    }

    private setFluteDirection(index: number): FluteDirection {
        const fd = [FluteDirection.Unknown, FluteDirection.X, FluteDirection.Y, FluteDirection.Any];
        return fd[index];
    }

    private loadSavedParams() {
        const uuid = this.importResponse.uuid;
        const materialInfo = this.importResponse.materialInfo as UploadFileMaterialInfo;
        const resizeParams = this.$store.getters.getResizeParams(uuid);

        const type = this.$store.getters['uploadFile/materialType'];

        if (!this.materialTypes.find((t) => t.value === type)) {
            this.materialTypes.push({
                text: type,
                value: type
            });

            this.allMaterialTypes[type] = [
                {
                    value: type,
                    text: type,
                    grammage: materialInfo.grammage,
                    thickness: materialInfo.thickness
                }
            ];
        }

        this.materialType = this.$store.getters['uploadFile/materialType'];
        this.material = resizeParams.material.name;
        this.fluteDirection = this.$store.getters.getSelectedFluteDirection;

        this.$nextTick(() => {
            this.thickness = resizeParams.material.thickness;
            this.grammageNote = resizeParams.grammageNotes;
        });
    }

    private async loadMaterials() {
        if (this.unit) {
            const types = await ProductService.getMaterialTypes(this.$store.getters['uploadFile/unit']);
            this.allMaterialTypes = types.reduce((obj: any, curr: any) => {
                obj[curr.type] = curr.materials.map((m: any) => {
                    return {
                        value: m.name,
                        text: m.name,
                        grammage: m.grammage,
                        thickness: m.d
                    } as SelectFieldItem;
                });
                return obj;
            }, {});

            this.materialTypes = types.map(({type}: any) => {
                return {
                    value: type,
                    text: type
                } as SelectFieldItem;
            });
        }
    }
}
