import { ProductLayoutStats } from '../../modules/products/models/product-layout-stats.interface';
import { ProductService } from '../../modules/products/product.service';
import { ProductDrawing } from '@shared/modules/products/models/product-drawing.model';
import { ProductLayoutMargins } from '../../modules/products/models/product-layout-margins.interface';
import { ProductUrlSourceType } from '../../modules/products/enums/product-url-source-type.enum';
import { ProductCostEstimationState } from './product-cost-estimation.store';
import store from '../store';
import { Units } from '@shared/modules/diemaker/enums/units-enum';
import { ProductDimensions } from '../../modules/products/models/product-dimensions.interface';

function createInitialState(): ProductLayoutState {
    return {
        template: '',
        catalog: [],
        selectedCatalog: null,
        width: null,
        height: null,
        margins: {left: null, right: null, top: null, bottom: null},
        gapX: null,
        gapY: null,
        rows: null,
        cols: null,
        rotate: false,
        previewUrl: null,
        drawing: null,
        stats: null,
        hasGeneratedLayout: false,
        dirty: true,
        sheetType: 'sheetSize'
    };
}

export interface ProductLayoutState {
    template: string;
    catalog: any[];
    selectedCatalog: any;
    width: number | null;
    height: number | null;
    margins: ProductLayoutMargins;
    gapX: number | null;
    gapY: number | null;
    rows: number | null;
    cols: number | null;
    rotate: boolean;
    previewUrl: string | null;
    drawing: ProductDrawing | null;
    stats?: ProductLayoutStats | null;
    hasGeneratedLayout: boolean;
    dirty: boolean;
    sheetType: 'sheetSize' | 'rowsCols';
}

export const ProductLayoutStore = {
    namespaced: true,
    state: createInitialState(),
    actions: {
        async getCatalog({state, commit}: { state: ProductLayoutState, commit: any }, unit: Units): Promise<any> {
            const catalog = await ProductService.getSheetCatalog(unit);
            commit('setCatalog', catalog);
        },
        async layout({dispatch, commit, getters, rootGetters}: any, {
            urlSourceType,
            productId,
            calcInfo,
            curFluteDir,
            rotate,
            resizeParams
        }: any): Promise<any> {

            delete resizeParams.initial;
            delete resizeParams.grammageNote;

            const extra = {} as any;

            if (urlSourceType === ProductUrlSourceType.File) {
                extra.originalDrawingType = rootGetters['uploadFile/originals'].type;
                extra.drawingType = rootGetters['uploadFile/type'];
                extra.originalViewSide = rootGetters['uploadFile/originals'].viewSide;
                extra.viewSide = rootGetters['uploadFile/viewSide'];
                calcInfo[0].oneups[0].oneupId = rootGetters['uploadFile/drawingId'];
            }

            const layout = urlSourceType === ProductUrlSourceType.File ?
                await ProductService.getLayoutByUuid(productId, calcInfo, curFluteDir, rotate, extra) :
                await ProductService.getSheetLayout(productId, calcInfo, curFluteDir, rotate, resizeParams);

            commit('saveLayoutParams', {
                id: productId,
                drawingId: rootGetters['getChosenDrawing'].evDrawingId,
                params: {
                    calcInfo,
                    rotate,
                    layoutResponse: layout
                }
            }, {root: true});

            return layout;
        }
    },
    mutations: {
        setTemplate(state: ProductLayoutState, template: string): void {
            state.template = template;
        },
        unshiftCatalog(state: ProductLayoutState, {name, sheetId}: { name: string, sheetId: any }): void {
            state.catalog.unshift({name, sheetId});
        },
        setCatalog(state: ProductLayoutState, catalog: any[]): void {
            state.catalog = catalog;
        },
        setSelectedCatalog(state: ProductLayoutState, selectedCatalog: any): void {
            state.selectedCatalog = selectedCatalog;
        },
        setWidth(state: ProductLayoutState, width: number): void {
            state.width = width;
        },
        setHeight(state: ProductLayoutState, height: number): void {
            state.height = height;
        },
        setMargins(state: ProductLayoutState, margins: ProductLayoutMargins): void {
            state.margins = margins;
        },
        setGapX(state: ProductLayoutState, gapX: number): void {
            state.gapX = gapX;
        },
        setGapY(state: ProductLayoutState, gapY: number): void {
            state.gapY = gapY;
        },
        setRows(state: ProductLayoutState, rows: number): void {
            state.rows = rows;
        },
        setCols(state: ProductLayoutState, cols: number): void {
            state.cols = cols;
        },
        setRotate(state: ProductLayoutState, rotate: boolean): void {
            state.rotate = rotate;
        },
        setPreviewUrl(state: ProductLayoutState, previewUrl: string): void {
            state.previewUrl = previewUrl;
        },
        setDrawing(state: ProductLayoutState, drawing: ProductDrawing): void {
            state.drawing = drawing;
        },
        setStats(state: ProductLayoutState, stats: ProductLayoutStats): void {
            state.stats = stats;
        },
        setHasGeneratedLayout(state: ProductLayoutState, hasGeneratedLayout: boolean): void {
            state.hasGeneratedLayout = hasGeneratedLayout;
        },
        setDirty(state: ProductLayoutState, dirty: boolean): void {
            state.dirty = dirty;
        },
        setMarginsLeft(state: ProductLayoutState, marginsLeft: number): void {
            state.margins.left = marginsLeft;
        },
        setMarginsRight(state: ProductLayoutState, marginsRight: number): void {
            state.margins.right = marginsRight;
        },
        setMarginsTop(state: ProductLayoutState, marginsTop: number): void {
            state.margins.top = marginsTop;
        },
        setMarginsBottom(state: ProductLayoutState, marginsBottom: number): void {
            state.margins.bottom = marginsBottom;
        },
        setSheetType(state: ProductLayoutState, sheetType: any): void {
            state.sheetType = sheetType;
        },
        reset(state: ProductCostEstimationState): void {
            const _state = createInitialState();

            for (const _key of Object.keys(state)) {
                const key = _key as keyof ProductLayoutState;
                // @ts-ignore
                state[key] = _state[key];
            }
        },
    },
    getters: {
        template(state: ProductLayoutState): string {
            return state.template;
        },
        catalog(state: ProductLayoutState): any[] {
            return state.catalog;
        },
        selectedCatalog(state: ProductLayoutState): any {
            return state.selectedCatalog;
        },
        width(state: ProductLayoutState): number {
            return state.width as number;
        },
        height(state: ProductLayoutState): number {
            return state.height as number;
        },
        margins(state: ProductLayoutState): ProductLayoutMargins {
            return state.margins;
        },
        dimensions(state: ProductLayoutState): ProductDimensions {
            const dimensions = {
                width: state.width as number,
                height: state.height as number,
                margins: state.margins,
                gapX: state.gapX,
                gapY: state.gapY,
                rows: state.rows,
                cols: state.cols
            };
            return dimensions;
        },
        rows(state: ProductLayoutState): number {
            return state.rows as number;
        },
        cols(state: ProductLayoutState): number {
            return state.cols as number;
        },
        rotate(state: ProductLayoutState): boolean {
            return state.rotate;
        },
        previewUrl(state: ProductLayoutState): string {
            return state.previewUrl as string;
        },
        drawing(state: ProductLayoutState): ProductDrawing {
            return state.drawing as ProductDrawing;
        },
        stats(state: ProductLayoutState): ProductLayoutStats {
            return state.stats as ProductLayoutStats;
        },
        hasGeneratedLayout(state: ProductLayoutState): boolean {
            return state.hasGeneratedLayout;
        },
        dirty(state: ProductLayoutState): boolean {
            return state.dirty;
        },
        gapX(state: ProductLayoutState): number {
            return state.gapX as number;
        },
        gapY(state: ProductLayoutState): number {
            return state.gapY as number;
        },
        areWidthHeightSet(state: ProductLayoutState): boolean {
            return !!state.width && !!state.height;
        },
        sheetType: (state: ProductLayoutState) => state.sheetType,
        fixedCount: (state: ProductLayoutState) => {
            return state.sheetType !== 'sheetSize' ? {
                rows: state.rows,
                columns: state.cols
            } : undefined;
        },
    }
};
