import { Component, Prop } from 'vue-property-decorator';
import { UtilService } from '../../utils/util.service';
import BaseValidationInput from '../../validation/base-validation-input.component';
import { RadioFieldItem } from '@shared/components/radio-field/radio-field-item.interface';

@Component({
    inheritAttrs: false
})
export default class RadioField extends BaseValidationInput {
    @Prop({default: null}) label!: string;
    @Prop({default: ''}) value!: string;
    @Prop({
        default: () => {
            return `input-${UtilService.getUniqueNum()}`;
        }
    }) name!: string;

    @Prop({default: []}) items!: RadioFieldItem[];
    @Prop({default: 'col-4'}) labelClass!: string;
    @Prop({default: 'col-8'}) inputClass!: string;

    handleChange(value: string) {
        this.$emit('input', value);
    }
}
