import { Product } from '@shared/modules/products/models/product.model';
import { CustomField } from '../../../../projects/printhouse-customer/src/modules/products/models/custom-field.interface';
import { CustomFieldDTO } from '../../../../projects/printhouse-customer/src/modules/products/models/custom-field-dto.interface';
import { CustomFieldType } from '../../../../projects/printhouse-customer/src/modules/products/models/custom-field.enum';

export function copyMeasLines(source: Product, dest: Product) {
    for (const oldDrawing of source.drawings) {
        const newDrawing = dest.drawings.find(nd => nd.id === oldDrawing.id);
        if (newDrawing) {
            newDrawing.measlines = oldDrawing.measlines;
        }
    }
}

export function copyParamsTexts(source: Product, dest: Product) {
    for (const key in source.params.paramMap) {

        dest.params.paramMap[key].description = source.params.paramMap[key].description;

        if (source.params.paramMap[key].values) {
            const values = source.params.paramMap[key].values;
            for (let i = 0; i < values.length; i++) {
                dest.params.paramMap[key].values[i].description = source.params.paramMap[key].values[i].description;
            }
        }
    }
}

export function copyLayoutPatterns(source: Product, dest: Product) {
    for (const oldDrawing of source.drawings) {
        const newDrawing = dest.drawings.find(nd => nd.id === oldDrawing.id);
        if (newDrawing) {
            newDrawing.layoutPatterns = oldDrawing.layoutPatterns;
        }
    }
}

export function filterParamsMap(params: any): any {
    const filtered: any = {};
    for (const key in params) {
        filtered[key] = {
            paramName: params[key].paramName,
            value: params[key].value,
            expression: params[key].expression
        };
    }

    return filtered;
}

export function mapToCustomFieldDTO(customField: CustomField): CustomFieldDTO {
    const dto: CustomFieldDTO = {
        id: customField.id,
    };
    if (customField.customFieldType === CustomFieldType.Dropdown) {
        dto.choiceID = Number(customField.value);
    } else if (customField.customFieldType === CustomFieldType.Checkbox) {
        dto.value = !!customField.value;
    } else {
        dto.value = customField.value;
    }
    return dto;
}
