import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import FormErrorMessage from '@shared/components/form-error-message/form-error-message.component.vue';
import CustomInput from '@shared/components/custom-input/custom-input.component.vue';
import LoadingButton from '@shared/components/loading-button/loading-button.component.vue';
import { CustomerDiecutter } from '../../../models/customer-diecutter.model';
import { DieboardType } from '../../../models/dieboard-type.enum';
import { ProfileService } from '../../../../../../projects/customer/src/modules/profile/profile.service';
import { CreateDiecutterRequest } from '../../../../../../projects/customer/src/modules/profile/models/create-diecutter.model';
import { DiecutterBase } from '../../../../../../projects/customer/src/modules/profile/models/diecutter-base.model';
import { UpdateDiecutterRequest } from '../../../../../../projects/customer/src/modules/profile/models/update-diecutter.model';
import { Validators } from '@shared/validation/validators';
import { Units } from '../../../../diemaker/enums/units-enum';

@Component({
    components: {
        FormErrorMessage,
        CustomInput,
        LoadingButton
    }
})
export default class DiecutterModal extends Vue {
    @Prop() unit!: Units;
    @Prop() diecutter!: CustomerDiecutter;
    @Prop() rotary!: boolean;
    dialog = true;

    name: string | null = null;
    size = DieboardType.FixedSize;
    width: number | null = null;
    height: number | null = null;
    marginLeft: number | null = null;
    marginRight: number | null = null;
    marginTop: number | null = null;
    marginBottom: number | null = null;

    errorMessage: string | null = null;

    readonly DieboardType = DieboardType;
    readonly Validators = Validators;

    created() {
        if (this.diecutter) {
            this.name = this.diecutter.name;
            this.size = this.diecutter.dieboardType;
            this.width = this.diecutter.maxWidth;
            this.height = this.diecutter.maxHeight;
            this.marginLeft = this.diecutter.leftMargin;
            this.marginRight = this.diecutter.rightMargin;
            this.marginBottom = this.diecutter.bottomMargin;
            this.marginTop = this.diecutter.topMargin;
        }
    }

    close() {
        this.$emit('close');
    }

    async save(stopLoading: () => void) {

        this.errorMessage = null;

        if (this.validate()) {
            try {
                const baseRequest = {
                    bottomMargin: this.marginBottom,
                    leftMargin: this.marginLeft,
                    maxHeight: this.height,
                    maxWidth: this.width,
                    name: this.name,
                    rightMargin: this.marginRight,
                    topMargin: this.marginTop,
                    dieboardType: this.size,
                    sheetId: this.name,
                    rotary: this.rotary
                } as DiecutterBase;

                if (this.isNew) {
                    await ProfileService.createCustomerDiecutter({
                        ...baseRequest,
                        default: false,
                        custom: true,
                        enabled: true,
                        measurementUnit: this.unit
                    } as CreateDiecutterRequest);
                } else {
                    await ProfileService.updateCustomerDiecutter({
                        ...baseRequest,
                        default: this.diecutter.default,
                        custom: true,
                        id: this.diecutter.id,
                        enabled: this.diecutter.enabled,
                        measurementUnit: this.unit
                    } as UpdateDiecutterRequest);
                }

                this.$emit('saved');

                this.$store.dispatch('notification/showSuccess', {text: this.$i18n.t('messages.saved')});

                this.close();

            } catch (e) {
                console.log(e);
                this.errorMessage = `errors.${e.code}`;
            }
        }

        stopLoading();
    }

    get isNew(): boolean {
        return !this.diecutter?.id;
    }

    @Watch('dialog')
    onDialogChange() {
        if (!this.dialog) {
            this.$nextTick(() => {
                this.$emit('close');
            });
        }
    }

    @Watch('size')
    onSizeChange() {
        if (this.size === DieboardType.AutoSize || this.size === DieboardType.RotaryAutoSize) {
            this.width = null;
            this.height = null;
        }
    }

    private validate() {
        return (this.$refs.form as any).validate();
    }
}
