import { i18n } from '../plugins/i18n';
import { UtilService } from '@shared/utils/util.service';
import { MathService } from '@shared/utils/math.service';

const decimalRegex = /^([0-9]+\.?[0-9]*|\.[0-9]+)$/;

export class Validators {
    static Required = (v: any) => {
        return (v !== '' && v !== null) || i18n.t('validation.required');
    }

    static MinLength = (min: number) => {
        return (v: any) => {
            return (v || '').length >= min || i18n.t('validation.minLength', {num: min});
        };
    }

    static MaxLength = (max: number) => {
        return (v: any) => {
            if (v === undefined) {
                return true;
            }
            return ((v + '') || '').length <= max || i18n.t('validation.maxLength', {num: max});
        };
    }

    static Max = (max: number) => {
        return (v: any) => {
            if (v === undefined) {
                return true;
            }
            return (v === '' || v <= max) || i18n.t('validation.max', {num: max});
        };
    }

    static Min = (max: number) => {
        return (v: any) => {
            if (v === undefined) {
                return true;
            }
            return (v === '' || v >= max) || i18n.t('validation.min', {num: max});
        };
    }

    static PasswordMatch = (toMatch: string) => {
        return (v: any) => {
            return (v !== '' && v !== null && v === toMatch) || i18n.t('validation.passwordNotMatch');
        };
    }

    static FileFormat = (allowedFormats: string[]) => {
        const checkExt = (file: File) => {
            return file instanceof File ? allowedFormats.includes(UtilService.getFileExtension(file)) : file;
        };

        return (v: File) => {
            return (v && checkExt(v)) || i18n.t('validation.invalidFileFormat', {formats: allowedFormats.join(', ')});
        };
    }

    static Decimal(input: string) {
        if (UtilService.isEmptyOrNull(input)) {
            return true;
        }

        return decimalRegex.test(input) || i18n.t('validation.invalidDecimal');
    }

    static InchParam(input: string) {
        if (UtilService.isEmptyOrNull(input)) {
            return true;
        }

        return MathService.validate(input) || i18n.t('validation.invalidInput');
    }
}
