import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { DiemakerRuleThickness } from '../../models/diemaker-rule-thickness.interface';
import { DiemakerRuleBrand } from '../../models/diemaker-rule-brand.interface';
import { Validators } from '@shared/validation/validators';

@Component
export default class ProductCostEstimationRuleRow extends Vue {

    @Prop({default: ''}) type!: string;
    @Prop({default: []}) thicknesses!: DiemakerRuleThickness[];
    @Prop({default: null}) defaultThickness!: string;
    @Prop({default: null}) defaultBrand!: string;

    selectedThickness: string | null = null;
    selectedBrand: string | null = null;

    readonly Validators = Validators;

    created() {
        this.selectedThickness = this.defaultThickness;
        this.selectedBrand = this.defaultBrand;
    }

    get brands() {
        const t = this.thicknesses.find((thick: DiemakerRuleThickness) => thick.thickness === this.selectedThickness);
        if (t && !this.selectedBrand) {
            this.selectedBrand = this.findDefaultBrand(t.brands);
        }
        return t ? t.brands : [];
    }

    @Watch('selectedThickness')
    onSelectedThicknessChange() {
        this.$emit('update:thickness', this.selectedThickness);
    }

    @Watch('selectedBrand')
    onSelectedBrandChange() {
        this.$emit('update:brand', this.selectedBrand);
    }

    private findDefaultBrand(brands: DiemakerRuleBrand[]): string | null {
        return brands.find(b => b.default)?.brand || null;
    }

}
