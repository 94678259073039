import { ProductMaterial } from '../../../../projects/customer/src/modules/products/models/product-material.interface';
import { ProductParams } from './product-params.interface';
import { FluteDirection } from '@shared/modules/products/enums/flute-direction.enum';
import { ProductDrawing } from '@shared/modules/products/models/product-drawing.model';
import { ProductMaterialCuttingSide } from '../../../../projects/customer/src/modules/products/models/product-material-cutting-side.enum';
import { BaseConfig } from '@shared/base-config';
import { Units } from '../../diemaker/enums/units-enum';

export class Product {

    id: number;
    colladaAnimations: string;
    daeURL: string;
    drawings: ProductDrawing[];
    libraryCode: string;
    materialInfo: ProductMaterial;
    name: string;
    params: ProductParams;
    possibleMaterials: ProductMaterial[];
    preview3DUrl: string;
    fluteDirection: FluteDirection;
    libraryId: string;
    measurementUnit: Units;

    constructor({dbId, colladaAnimations, daeURL, libraryId, fluteDir, drawings, libraryCode, measurementUnit, materialInfo, name, params, possibleMaterials, preview3DUrl}: any = {}) {
        this.id = dbId;
        this.colladaAnimations = colladaAnimations;
        this.daeURL = daeURL;
        this.drawings = drawings?.map((d: any) => new ProductDrawing(d));
        this.libraryCode = libraryCode;
        this.materialInfo = materialInfo;
        this.name = name;
        this.params = params;
        this.possibleMaterials = possibleMaterials;
        this.preview3DUrl = preview3DUrl;
        this.fluteDirection = fluteDir;
        this.libraryId = libraryId;
        this.measurementUnit = measurementUnit;

        if (this.materialInfo?.cuttingSide === ProductMaterialCuttingSide.DCS_Unknown) {
            this.materialInfo.cuttingSide = ProductMaterialCuttingSide.DCS_FrontCuttingDie;
        }
    }
}
