import { Component, Vue, Watch } from 'vue-property-decorator';
import CustomImageInput from '@shared/components/custom-image-input/custom-image-input.component.vue';
import { BreadcrumbHistoryItem } from '@shared/components/breadcrumbs/breadcrumb-history-item.interface';
import { Routes } from '../../../../routes.enum';
import Breadcrumbs from '@shared/components/breadcrumbs/breadcrumbs.component.vue';
import RadioField from '@shared/components/radio-field/radio-field.component.vue';
import { Validators } from '@shared/validation/validators';
import { FluteDirection } from '@shared/modules/products/enums/flute-direction.enum';
import ProductPreviewDrawing from '@shared/modules/products/components/product-preview-drawing/product-preview-drawing.component.vue';
import ProductPreviewDrawingThumbnail from '../../../../../../../shared/modules/products/components/product-preview-drawing-thumbnail/product-preview-drawing-thumbnail.component.vue';
import { Product } from '@shared/modules/products/models/product.model';
import { ProductDrawing } from '@shared/modules/products/models/product-drawing.model';
import { ProductUrlSourceType } from '../../enums/product-url-source-type.enum';
import ProductSection from '@shared/modules/products/components/product-section/product-section.component.vue';
import UploadNewFileButton from '../../components/upload-new-file-button/upload-new-file-button.component.vue';
import FormErrorMessage from '@shared/components/form-error-message/form-error-message.component.vue';
import Toggle from '@shared/components/toggle/toggle.component.vue';
import UploadFileMaterial from '../../components/upload-file-material/upload-file-material.component.vue';
import UploadFileSheet from '../../components/upload-file-sheet/upload-file-sheet.component.vue';
import {
    cuttingDieSideToViewSide,
    ViewSide,
    viewSideFromIndex,
    viewSideToCuttingDieSide
} from '@shared/modules/products/enums/view-side.enum';
import { DrawingType } from '@shared/modules/products/enums/drawing-type.enum';
import { ServerError } from '@shared/request/server-error.model';
import { Units } from '@shared/modules/diemaker/enums/units-enum';
import { SelectFieldItem } from '@shared/components/select-field/select-field-item.interface';
import { Diemaker } from '@shared/modules/diemaker/models/diemaker.model';
import UploadFileGuidelinesModal from './upload-file-guidelines-modal/upload-file-guidelines-modal.component.vue';
import { Dieboard, DieboardSizeType, DieboardType } from '../../../../store/modules/product-cost-estimation.store';
import { UtilService } from '@shared/utils/util.service';

@Component({
    components: {
        CustomImageInput,
        Breadcrumbs,
        RadioField,
        ProductPreviewDrawing,
        ProductPreviewDrawingThumbnail,
        ProductSection,
        UploadNewFileButton,
        FormErrorMessage,
        Toggle,
        UploadFileMaterial,
        UploadFileSheet,
        UploadFileGuidelinesModal
    }
})
export default class UploadFile extends Vue {

    readonly Routes = Routes;
    readonly DrawingType = DrawingType;
    readonly allowedFormats = ['evd', 'dxf', 'dwg', 'cf2', 'dde', 'dd3', 'ai', 'pdf'];
    readonly Validators = Validators;

    breadcrumbs: BreadcrumbHistoryItem[] = [];
    errorMessage: string | null = null;

    readonly types = [
        { value: DrawingType.OneUp, text: 'uploadFile.types.OneUp' },
        { value: DrawingType.Layout, text: 'uploadFile.types.Layout' },
        { value: DrawingType.Dieboard, text: 'uploadFile.types.Dieboard' }
    ];

    file: any = null;

    nextIsLoading = false;
    loadingOverlayVisible = false;
    heightOfPreviewWindow = 0;
    activeTab = 0;
    guideLinesModalVisible = false;

    readonly units: SelectFieldItem[] = [
        {text: Units.INCH, value: Units.INCH},
        {text: Units.MM, value: Units.MM},
    ];

    scrollOptions = {
        rail: {
            background: '#f5f5f5',
            opacity: 1,
            size: '8px',
            specifyBorderRadius: false,
            gutterOfEnds: null,
            gutterOfSide: '2px',
            keepShow: true
        },
        bar: {
            showDelay: 500,
            onlyShowBarOnScroll: true,
            keepShow: true,
            background: '#c1c1c1',
            opacity: 1,
            hoverStyle: false,
            specifyBorderRadius: false,
            minSize: 0,
            size: '8px',
            disable: false
        },
        scrollPanel: {
            scrollingX: false
        }
    };

    async created() {
        window.addEventListener('resize', this.handlePreviewWindowResize);
        this.handlePreviewWindowResize();

        if (!this.$store.getters['auth/isAuthenticated']) {
            const uploadFileRoute = this.$router.resolve({
                name: Routes.UploadFile
            });
            this.$router.push({ name: Routes.UserLogin, query: { redirectTo: uploadFileRoute.route.path } as any });
        }

        this.initBreadcrumbs();

        if (this.importResponse) {
            this.loadSavedParams();
            this.$store.commit('uploadFile/revertOriginalDrawingId');
        }
    }

    destroyed() {
        window.removeEventListener('resize', this.handlePreviewWindowResize);
    }

    async nextStep() {
        if ((this.$refs.formParams as any).validate()) {

            this.loadingOverlayVisible = true;

            this.$store.commit('changePreviewOfCurrentProduct', this.createProduct());

            const params = { id: this.importResponse.uuid, type: ProductUrlSourceType.File };
            await this.$store.dispatch('uploadFile/prepareEvd', params.id);

            this.loadingOverlayVisible = false;

            if (this.isDrawing) {
                this.$router.push({ name: Routes.ProductLayout, params });
            } else {
                this.$router.push({ name: Routes.ProductCostEstimation, params });
            }
        }
    }

    handlePreviewWindowResize() {
        this.heightOfPreviewWindow = window.innerHeight - 224;
        return this.heightOfPreviewWindow;
    }

    @Watch('file')
    async onFileChange(file: File) {

        this.setErrorMessage(null);

        if (!file) {
            return;
        }

        this.loadingOverlayVisible = true;
        await this.$store.dispatch('startCADSession');
        let resp;
        try {
            resp = await this.$store.dispatch(`uploadFile/upload`, file);
        } catch (e) {
            this.setFileError(this.$i18n.t(`errors.${e.code}`).toString());
            this.loadingOverlayVisible = false;
            return;
        }

        if (resp.layouts.length + resp.oneUps.length === 0) {
            this.setFileError(this.$i18n.t('errors.cannotRecognizeDrawing').toString());
            this.loadingOverlayVisible = false;
            return;
        }

        this.setInitialParams();

        this.loadingOverlayVisible = false;
    }

    openHowTo() {
        this.guideLinesModalVisible = true;
    }

    showDrawing(index: any) {
        this.activeTab = index;
        const activeDrawing = this.drawingsTabs[this.activeTab];
        const layoutOrDrawing = activeDrawing.layout ?? activeDrawing.drawing;
        this.$store.commit('uploadFile/type',
            typeof activeDrawing.drawing !== 'undefined' ? DrawingType.OneUp : (layoutOrDrawing.dieboard ? DrawingType.Dieboard : DrawingType.Layout));
        this.$store.commit('uploadFile/setDrawingId', layoutOrDrawing.id);
        this.$store.commit('uploadFile/setOriginals', {
            viewSide: this.$store.getters['uploadFile/viewSide'],
            type: this.$store.getters['uploadFile/type'],
            drawingId: this.$store.getters['uploadFile/drawingId']
        });
        this.clearSavedNextSteps();
    }

    setErrorMessage(error: string | null) {
        this.errorMessage = error;
    }

    setType(type: DrawingType) {
        let viewSide;
        if (type === DrawingType.Dieboard) {
            viewSide = cuttingDieSideToViewSide(this.$store.getters['uploadFile/materialInfoResponse'].cuttingSide);
        } else {
            viewSide = this.originalViewSide;
        }
        this.$store.commit('uploadFile/viewSide', viewSide);
        this.$store.commit('uploadFile/type', type);
    }

    setViewSide(viewSide: ViewSide) {
        this.$store.commit('uploadFile/viewSide', viewSide);
    }

    onDrawingError(error: ServerError) {
        // ResourceIDNotFound
        if (error.code === 2015) {
            this.$store.commit('uploadFile/clearData');
        }
    }

    get type(): DrawingType {
        return this.$store.getters['uploadFile/type'];
    }

    get isDrawing(): boolean {
        return this.type === DrawingType.OneUp;
    }

    get importResponse(): any {
        return this.$store.getters['uploadFile/uploadFileResponse'];
    }

    get drawingsTabs() {
        return this.$store.getters['uploadFile/drawingsTabs'];
    }

    get viewSide() {
        return this.$store.getters['uploadFile/viewSide'];
    }

    get uploadFileMaterial(): any {
        return this.$refs.uploadFileMaterial;
    }

    get uploadFileSheet(): any {
        return this.$refs.uploadFileSheet;
    }

    get unit(): Units {
        return this.$store.getters['uploadFile/unit'];
    }

    setUnits(unit: Units) {
        const oldUnit = this.$store.getters['uploadFile/unit'];
        this.$store.commit('uploadFile/setUnit', unit);
        if (this.importResponse) {
            const answer = window.confirm(this.$i18n.t('uploadFile.unitChange') as string);
            if (answer) {
                this.clearSavedNextSteps();
                this.$store.commit('uploadFile/clearData');
                this.$store.commit('uploadFile/setUnit', unit);
            } else {
                this.$nextTick(() => {
                    this.$store.commit('uploadFile/setUnit', oldUnit);
                });
            }
        }
    }

    get sides() {
        if (this.type === DrawingType.Dieboard) {
            return [
                { value: ViewSide.Front, text: 'viewSides.die.DVS_ViewFront' },
                { value: ViewSide.Back, text: 'viewSides.die.DVS_ViewBack' },
            ];
        }

        return [
            { value: ViewSide.Front, text: 'viewSides.normal.DVS_ViewFront' },
            { value: ViewSide.Back, text: 'viewSides.normal.DVS_ViewBack' },
        ];
    }

    get viewSideText(): string {
        if (this.type === DrawingType.Dieboard) {
            return this.viewSide === ViewSide.Front ? 'viewSides.die.DVS_ViewFront' : 'viewSides.die.DVS_ViewBack';
        } else {
            return this.viewSide === ViewSide.Front ? 'viewSides.normal.DVS_ViewFront' : 'viewSides.normal.DVS_ViewBack';
        }
    }

    get originalViewSide(): ViewSide {
        const activeDrawing = this.drawingsTabs[this.activeTab];
        const layoutOrDrawing = activeDrawing.layout ?? activeDrawing.drawing;
        return viewSideFromIndex(layoutOrDrawing.viewside);
    }

    get isBackSide() {
        return this.viewSide === ViewSide.Back;
    }

    get isDieboard() {
        return this.type === DrawingType.Dieboard;
    }

    get unitSelectVisible(): boolean {
        return this.diemakerUnits === Units.BOTH;
    }

    get diemakerUnits(): Units {
        return this.$store.getters['diemakerUnits'];
    }

    get diemaker(): Diemaker {
        return this.$store.getters['diemaker'];
    }

    @Watch('diemaker', {immediate: true})
    onDiemakerChange() {
        if (this.diemaker) {
            if (this.diemaker.units !== Units.BOTH) {
                this.$store.commit('uploadFile/setUnit', this.diemaker.units);
            } else {
                this.$store.commit('uploadFile/setUnit', Units.MM);
            }
        }
    }

    private setInitialParams() {
        this.showDrawing(0);
    }

    private createProduct(): Product {
        const activeDrawing = this.drawingsTabs[this.activeTab];
        const layoutOrDrawing = activeDrawing.layout ?? activeDrawing.drawing;

        const product = new Product();
        product.id = this.importResponse.uuid;
        product.name = layoutOrDrawing.name;
        product.drawings = this.drawingsTabs.map(
            (dt: any) => {
                const pd = new ProductDrawing();
                pd.evDrawingId = dt.layout?.id ?? dt.drawing?.id;
                pd.svgURL = dt.svgUrl;
                pd.layoutPatterns = this.importResponse.layoutPatterns.filter((p: any) => p.drawingIds.includes(pd.evDrawingId)).map((p: any) => {
                    return {
                        id: p.id,
                        name: p.id
                    };
                });
                return pd;
            }
        );

        product.fluteDirection = this.uploadFileMaterial.fluteDirection as FluteDirection;
        this.$store.commit('changeSelectedFluteDirection', product.fluteDirection);

        this.saveResizeParams(this.importResponse.uuid, layoutOrDrawing.id);
        this.saveLayoutParams(this.importResponse.uuid, layoutOrDrawing.id, activeDrawing);
        this.saveCostEstimationParams(this.importResponse.uuid, layoutOrDrawing.id, activeDrawing);

        return product;
    }

    private initBreadcrumbs() {
        this.breadcrumbs = [
            {
                text: 'breadcrumbs.newRequest',
                disabled: false,
                to: {
                    name: Routes.NewRequest
                }
            },
            {
                text: 'products.breadcrumbs.uploadFile',
                disabled: true
            }
        ];
    }

    private saveResizeParams(id: string, drawingId: number) {
        this.$store.commit('saveResizeParams', {
            id,
            drawingId,
            params: {
                material: {
                    name: this.uploadFileMaterial.material,
                    thickness: this.uploadFileMaterial.thickness
                },
                fluteDir: this.$store.getters.getSelectedFluteDirection,
                grammageNote: this.uploadFileMaterial.grammageNote
            }
        });

        this.$store.commit('uploadFile/materialType', this.uploadFileMaterial.materialType);
    }

    private saveCostEstimationParams(id: string, drawingId: number, activeDrawing: any) {

        if (!this.$store.getters.getCostEstimationParams(id)) {
            this.$store.commit('saveCostEstimationParams', {
                id,
                drawingId,
                params: { }
            });
        }

        if (activeDrawing.layout?.dieboard) {
            this.$store.commit('productCostEstimation/setDieboard', {
                height: UtilService.formatNumber(activeDrawing.layout?.dieboard.height, this.unit),
                width: UtilService.formatNumber(activeDrawing.layout?.dieboard.width, this.unit),
                dieCutter: activeDrawing.layout?.dieboard.name,
                type: DieboardType.Diecutter,
                sizeType: DieboardSizeType.Fixed,
                marginB: UtilService.formatNumber(activeDrawing.layout?.dieboard.marginB, this.unit),
                marginT: UtilService.formatNumber(activeDrawing.layout?.dieboard.marginT, this.unit),
                marginL: UtilService.formatNumber(activeDrawing.layout?.dieboard.marginL, this.unit),
                marginR: UtilService.formatNumber(activeDrawing.layout?.dieboard.marginR, this.unit),
            } as Partial<Dieboard>);
        }

        this.$store.commit('productCostEstimation/setCuttingDieSide', viewSideToCuttingDieSide(this.viewSide));
    }

    private setFileError(error: string) {

        const fileInput = this.$refs.fileInput as any;

        if (fileInput) {
            this.file = undefined;
            fileInput.errors = [error];
        } else {
            this.setErrorMessage(error);
        }
    }

    private loadSavedParams() {
        const uuid = this.importResponse.uuid;
        const resizeParams = this.$store.getters.getResizeParams(uuid);
        // const costEstimationParams = this.$store.getters.getCostEstimationParams(uuid);

        const cuttingDieSide = this.$store.getters['productCostEstimation/cuttingDieSide'];

        if (cuttingDieSide) {
            this.$store.commit('uploadFile/viewSide', cuttingDieSideToViewSide(cuttingDieSide));
        }

        this.activeTab = this.drawingsTabs.findIndex((drawing: any) => drawing.layout?.id === resizeParams.drawingId || drawing.drawing?.id === resizeParams.drawingId);
    }

    private clearSavedNextSteps() {
        this.$store.commit('clearResizeParams');
        this.$store.commit('clearLayoutParams');
        this.$store.commit('clearCostEstimationParams');
        this.$store.commit('productCostEstimation/reset');
        this.$store.commit('productLayout/reset');
        this.$store.commit('productMakeOrder/reset');
        this.$store.commit('clearOrderRequestParams');
    }

    private saveLayoutParams(id: string, drawingId: number, drawing: any) {
        let sheetName = 'User Defined';

        if (this.uploadFileSheet.selectedCatalog) {
            sheetName = this.uploadFileSheet.catalog.find((c: any) => c.sheetId === (this.uploadFileSheet.selectedCatalog as any).toString()).name;
        }

        const calcInfo: any[] = [{
            sheets: [
                {
                    id: this.uploadFileSheet.selectedCatalog || -1,
                    sheet: {
                        name: sheetName,
                        width: this.uploadFileSheet.width || 0,
                        height: this.uploadFileSheet.height || 0,
                        marginL: this.uploadFileSheet.margins.left || 0,
                        marginT: this.uploadFileSheet.margins.top || 0,
                        marginR: this.uploadFileSheet.margins.right || 0,
                        marginB: this.uploadFileSheet.margins.bottom || 0
                    }
                }
            ],
            id,
            gapX: 0,
            gapY: 0
        }];

        if (this.type === DrawingType.OneUp) {
            calcInfo[0].oneups = [
                {
                    oneupId: drawingId,
                    count: 1
                }
            ];
        } else {
            // calcInfo[0].layoutDrawingId = drawingId;
        }

        this.$store.commit('saveLayoutParams', {
            id,
            drawingId,
            params: {
                calcInfo,
                layoutResponse: {
                    layoutCalcResults: [
                        {
                            sheetOutputs: [
                                { url: drawing.svgUrl }
                            ]
                        }
                    ]
                }
            }
        });
    }
}
