import Component from 'vue-class-component';
import ProductSection from '@shared/modules/products/components/product-section/product-section.component.vue';
import Vue from 'vue';

@Component({
    components: {
        ProductSection
    }
})
export default class CustomerInquiry extends Vue {

    handleNotesChange(notes: string): void {
        this.$store.commit('productMakeOrder/setNotes', notes);
    }

    get notes(): string {
        return this.$store.getters['productMakeOrder/notes'];
    }
}
