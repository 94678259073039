import { Component, Vue } from 'vue-property-decorator';
import { Routes } from '../../routes.enum';
import SecondImage from './images/second-image.vue';

@Component({
    components: {
        SecondImage
    }
})
export default class Landing extends Vue {
    get productListUrl(): string {
        if (!this.isWorkAs) {
            const props = this.$router.resolve({
                name: Routes.NewRequest,
            });

            return props.href;
        } else {
            const props = this.$router.resolve({
                name: Routes.ProductList,
            });

            return props.href;
        }
    }

    get isWorkAs(): boolean {
        return this.$store.getters['auth/isAuthenticated'] && this.$store.getters['auth/customerWorkAsId'];
    }
}
