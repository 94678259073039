import { Component, Prop, Vue } from 'vue-property-decorator';
import { BaseOrder } from '@shared/modules/orders/models/base-order.model';
import ProductSection from '@shared/modules/products/components/product-section/product-section.component.vue';

@Component({
    components: {
        ProductSection
    }
})
export default class MaterialsInfo extends Vue {
    @Prop() order!: BaseOrder;
}
