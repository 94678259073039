import { Component, Vue, Watch } from 'vue-property-decorator';
import ProductResize from '../../components/product-resize/product-resize.component.vue';
import { Routes } from '../../../../routes.enum';
import Breadcrumbs from '@shared/components/breadcrumbs/breadcrumbs.component.vue';
import { ProductService } from '../../product.service';
import { ProductUrlSourceType } from '../../enums/product-url-source-type.enum';
import ProductToggleFavorites from '../../components/product-toggle-favorites/product-toggle-favorites.component.vue';
import DrawingThumbnailList from '@shared/modules/products/components/drawing-thumbnail-list/drawing-thumbnail-list.component.vue';
import DrawingSelectedThumbnail from '@shared/modules/products/components/drawing-selected-thumbnail/drawing-selected-thumbnail.component.vue';
import { DrawingThumbnailItem } from '@shared/modules/products/components/drawing-thumbnail-list/drawing-thumbnail-item.interface';
import { BaseConfig } from '@shared/base-config';
import { Theme } from '@shared/themes/theme.enum';
import { Units } from '@shared/modules/diemaker/enums/units-enum';

@Component({
    components: {
        Breadcrumbs,
        ProductResize,
        ProductToggleFavorites,
        DrawingThumbnailList,
        DrawingSelectedThumbnail
    },
    beforeRouteEnter(to: any, from: any, next: (vm: any) => void) {
        next((vm: ProductPreview) => {
            if (from.name === Routes.ProductLayout || from.name === Routes.ProductSendResizeTemplate) {
                vm.toggleResize();
            }
        });
    }
})
export default class ProductPreview extends Vue {

    showButtons = true;
    showResize = false;
    heightOfPreviewWindow = 0;
    numberOfColumns = 12;
    selectedThumbnail: DrawingThumbnailItem | null = null;

    get productLoading() {
        return this.$store.getters['productLoading'];
    }

    get currentProduct() {
        return this.$store.getters.getPreviewOfCurrentProduct;
    }

    get fromRoute(): { name: Routes; query: any } | null {
        return this.$store.getters.getListFilters;
    }

    get templateUnit(): Units {
        return this.$store.getters.templateUnit;
    }

    async created() {

        if (this.currentProduct?.id === +this.$route.params.id) {
            this.currentProductChange();
        }

        await this.$store.dispatch('loadProduct', {
            productId: +this.$route.params.id
        });

        this.$store.dispatch('startCADSession');
    }

    destroyed() {
        window.removeEventListener('resize', this.handlePreviewWindowResize);
    }

    selectedThumbnailChange(item: DrawingThumbnailItem) {
        this.selectedThumbnail = item;
    }

    set3dSelected(selected: boolean) {
        this.$store.commit('set3dSelected', selected);
    }

    updateChosenDrawing(drawing: any) {
        this.$store.commit('changeChosenDrawing', drawing);
    }

    changeGroupsArray(obj: any) {
        this.$store.commit('changeGroupsArray', obj);
    }

    toggleResize() {

        this.showButtons = !this.showButtons;
        this.showResize = !this.showResize;
        this.numberOfColumns === 8
            ? (this.numberOfColumns = 12)
            : (this.numberOfColumns = 8);

        window.dispatchEvent(new Event('resize'));

        this.$store.commit('changeIsResizePage', true);

        if (!this.showResize) {
            this.$store.commit('clearResizeParams');
            this.$store.commit('clearLayoutParams');
            this.$store.commit('clearCostEstimationParams');
            this.$store.commit('productCostEstimation/reset');
            this.$store.commit('productLayout/reset');
            this.$store.commit('productMakeOrder/reset');
            this.$store.commit('clearOrderRequestParams');
        }

    }

    handlePreviewWindowResize() {
        this.heightOfPreviewWindow = window.innerHeight - 224;
        return this.heightOfPreviewWindow;
    }

    backToProducts() {
        if (this.fromRoute) {
            this.$router.push(this.fromRoute);
        } else {
            this.$router.push({name: Routes.ProductList});
        }
    }

    get isAuthenticated(): boolean {
        return this.$store.getters['auth/isAuthenticated'];
    }

    get isWorkAs(): boolean {
        return this.isAuthenticated && this.$store.getters['auth/customerWorkAsId'];
    }

    get is3dSelected() {
        return this.$store.getters['is3dSelected'];
    }

    get breadcrumbs() {
        if (!this.isWorkAs && BaseConfig.theme !== Theme.PoligrafBG) {
            return [
                {
                    text: 'breadcrumbs.newRequest',
                    disabled: false,
                    to: {
                        name: Routes.NewRequest
                    }
                },
                ProductService.getRoute(this.$store.getters.getListFilters, this.$route.params.type as ProductUrlSourceType),
                {
                    plainText: this.currentProduct.name,
                    disabled: true
                }
            ];
        } else {
            return [
                ProductService.getRoute(this.$store.getters.getListFilters, this.$route.params.type as ProductUrlSourceType),
                {
                    plainText: this.currentProduct.name,
                    disabled: true
                }
            ];
        }
    }

    @Watch('currentProduct')
    currentProductChange() {
        window.removeEventListener('resize', this.handlePreviewWindowResize);
        window.addEventListener('resize', this.handlePreviewWindowResize);
        this.handlePreviewWindowResize();
    }
}
