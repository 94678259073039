import { Routes } from '../../routes.enum';
import store from '../../store/store';

export async function diemakerResolver(to: any, from: any, next: any) {
    const diemakerId = to.query.diemakerid || to.params.diemakerId;

    if (diemakerId !== 'null' && diemakerId) {
        store.commit('setDiemakerContext', diemakerId);
        await store.dispatch('getDiemaker', diemakerId);
        if (isWorkAs()) {
            store.commit('setUserDefaultDiemaker', diemakerId);
        }
    } else if (store.getters.getDiemakerContext && to.name !== Routes.UserChooseDefaultDiemaker) {
        try {
            await store.dispatch('getDiemaker', store.getters.getDiemakerContext);
        } catch (e) {
            if (e.code === 19 && store.getters['auth/isAuthenticated']) {
                next({name: Routes.UserChooseDefaultDiemaker});
                return;
            }
        }
    }

    if (!isWorkAs() && store.getters['auth/isAuthenticated']) {
        if (to.name !== Routes.UserChooseDefaultDiemaker && store.getters['auth/isEmailConfirmed']) {
            await store.dispatch('loadDefaultDiemaker');
            if (!store.getters.getUserDefaultDiemaker) {
                next({name: Routes.UserChooseDefaultDiemaker, query: {redirectTo: from.query.redirectTo}});
                return;
            }
        }
    }
    next();
}

function isWorkAs() {
    return store.getters['auth/isAuthenticated'] && store.getters['auth/customerWorkAsId'];
}
