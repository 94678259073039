export class Person {
    name: string;
    email: string;
    phone: string;
    id: string;
    locale: string;

    constructor({id, name, email, locale, phone}: any) {
        this.id = id;
        this.name = name;
        this.email = email;
        this.phone = phone;
        this.locale = locale;
    }
}
