import Component from 'vue-class-component';
import ProductSection from '@shared/modules/products/components/product-section/product-section.component.vue';
import Vue from 'vue';
import { DiemakerRuleMainType } from '@shared/modules/products/enums/diemaker-rule-main-type.enum';
import { Dieboard, DieboardType } from '../../../../store/modules/product-cost-estimation.store';
import { Prop } from 'vue-property-decorator';

@Component({
    components: {
        ProductSection
    }
})
export default class DieboardTable extends Vue {

    @Prop() ruleType!: DiemakerRuleMainType;

    DiemakerRuleMainType = DiemakerRuleMainType;

    get dieboard(): Dieboard {
        return this.$store.getters['productCostEstimation/dieboard'];
    }

    get dieCutter(): string | null {
        return this.dieboard.dieCutter;
    }

    get width(): number | null {
        return this.dieboard.width;
    }

    get height(): number | null {
        return this.dieboard.height;
    }
}
