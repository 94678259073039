import Component from 'vue-class-component';
import Vue from 'vue';
import ProductSection from '@shared/modules/products/components/product-section/product-section.component.vue';
import { Validators } from '@shared/validation/validators';
import { Prop } from 'vue-property-decorator';

@Component({
    components: {
       ProductSection
    }
})
export default class RequestDetails extends Vue {

    @Prop() currency!: string;

    readonly requiredRules = [
        Validators.MaxLength(255)
    ];

    deliveryDateMenu = false;
    dateNow = new Date().toISOString();

    handleRequestTitleChange(requestTitle: string): void {
        this.$store.commit('productMakeOrder/setRequestTitle', requestTitle);
    }

    handleDeliveryDateChange(date: Date): void {
        this.$store.commit('productMakeOrder/setDeliveryDate', date);
        this.deliveryDateMenu = false;
    }

    get language(): string {
        return this.$store.getters['language'];
    }

    get requestTitle(): string {
        return this.$store.getters['productMakeOrder/requestTitle'];
    }

    get deliveryDate(): Date {
        return this.$store.getters['productMakeOrder/deliveryDate'];
    }

    get estimatedCost(): number {
        return this.$store.getters['productMakeOrder/estimatedCost'];
    }

     validate(): void {
        return (this.$refs.form as any).validate();
    }
}
