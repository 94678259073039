import { Component, Vue } from 'vue-property-decorator';
import ProductResize from '../../components/product-resize/product-resize.component.vue';
import { ProductService } from '../../product.service';
import ProductPreviewDrawingThumbnail from '../../../../../../../shared/modules/products/components/product-preview-drawing-thumbnail/product-preview-drawing-thumbnail.component.vue';
import { Routes } from '../../../../routes.enum';
import { Product } from '@shared/modules/products/models/product.model';
import numberRound from '@shared/filters/number-round.filter';
import { ProductDrawing } from '@shared/modules/products/models/product-drawing.model';
import ProductSection from '@shared/modules/products/components/product-section/product-section.component.vue';
import ProductPreviewDrawing from '@shared/modules/products/components/product-preview-drawing/product-preview-drawing.component.vue';
import { BreadcrumbHistoryItem } from '@shared/components/breadcrumbs/breadcrumb-history-item.interface';
import Breadcrumbs from '@shared/components/breadcrumbs/breadcrumbs.component.vue';
import { Config } from '../../../../config';
import { Validators } from '@shared/validation/validators';
import drawingUrl from '@shared/filters/drawing-url.filter';
import { ProductUrlSourceType } from '../../enums/product-url-source-type.enum';
import store from '../../../../store/store';
import { UtilService } from '@shared/utils/util.service';
import { ServerError } from '@shared/request/server-error.model';
import { ProductLayoutStats } from '../../models/product-layout-stats.interface';
import { ProductLayoutMargins } from '../../models/product-layout-margins.interface';
import Navigation from './components/navigation/navigation.component.vue';
import PreviewDrawingCard from './components/preview-drawing-card/preview-drawing-card.component.vue';
import SheetSection from './components/sheet-section/sheet-section.component.vue';
import MarginsSection from './components/margins-section/margins-section.component.vue';
import GapOptions from './components/gap-options/gap-options.component.vue';
import LayoutOptions from './components/layout-options/layout-options.component.vue';
import { Units } from '@shared/modules/diemaker/enums/units-enum';

@Component({
    components: {
        Breadcrumbs,
        ProductResize,
        ProductPreviewDrawing,
        ProductPreviewDrawingThumbnail,
        ProductSection,
        Navigation,
        PreviewDrawingCard,
        SheetSection,
        MarginsSection,
        GapOptions,
        LayoutOptions
    },
    beforeRouteEnter(to: any, from: any, next: any) {
        if (!store.getters.getPreviewOfCurrentProduct?.id) {
            next({name: Routes.NewRequest});
        } else {
            next();
        }
    }
})
export default class ProductLayout extends Vue {

    heightOfPreviewWindow = 0;
    isUpdating = false;
    breadcrumbs: BreadcrumbHistoryItem[] = [];
    resizeParams: any;
    loadingOverlayVisible = false;

    readonly Validators = Validators;
    drawingPrefix = `${Config.apiWebStandardsLibrary}api/v1/files/`;

    get currentProduct(): Product {
        return this.$store.getters.getPreviewOfCurrentProduct;
    }

    async created() {

        if (this.isFile) {
            this.drawingPrefix = Config.apiPortal;
        }

        this.initBreadcrumbs();

        if (!this.$store.getters['areWidthHeightSet']) {
            if (this.selectedUnit === Units.INCH) {
                this.$store.commit('productLayout/setWidth', 39);
                this.$store.commit('productLayout/setHeight', 27);
            } else {
                this.$store.commit('productLayout/setWidth', 1000);
                this.$store.commit('productLayout/setHeight', 700);
            }
        }

        this.resizeParams = this.$store.getters.getResizeParams(this.currentProduct.id);
        this.$store.commit('productLayout/setDrawing', this.currentProduct.drawings.find(d => d.evDrawingId === this.resizeParams.drawingId));

        await this.$store.dispatch('productLayout/getCatalog', this.selectedUnit);
        this.$store.commit('productLayout/unshiftCatalog', {
            name: this.$i18n.t('products.costEstimation.labels.userDefined'),
            sheetId: null
        });

        const savedLayoutParams = this.$store.getters.getLayoutParams(this.currentProduct.id);

        if (savedLayoutParams?.layoutResponse) {
            this.$store.commit('productLayout/setHasGeneratedLayout', true);
        }

        if (savedLayoutParams?.calcInfo) {
            this.initFields(savedLayoutParams?.calcInfo, savedLayoutParams?.rotate);
            await this.getSheetLayout();
        } else {
            await this.getSheetLayout();
            this.setDirty();
        }
    }

    mounted() {
        this.handlePreviewWindowResize();
        window.addEventListener('resize', this.handlePreviewWindowResize);
    }

    handlePreviewWindowResize() {
        this.heightOfPreviewWindow = window.innerHeight - 284;
    }

    goToCostEstimation() {
        if (this.$store.getters['auth/isAuthenticated']) {
            this.$router.push({name: Routes.ProductCostEstimation, params: {id: this.currentProduct.id} as any});
        } else {
            const costEstimationRoute = this.$router.resolve({
                name: Routes.ProductCostEstimation,
                params: {id: this.currentProduct.id} as any
            });
            this.$router.push({name: Routes.UserLogin, query: {redirectTo: costEstimationRoute.route.path} as any});
        }
    }

    setDirty() {
        this.$store.commit('productLayout/setDirty', true);
    }

    destroyed() {
        window.removeEventListener('resize', this.handlePreviewWindowResize);
    }

    backToProducts() {
        if (this.isFile) {
            this.$router.push({
                name: Routes.UploadFile
            });
        } else {
            this.$router.push({
                name: Routes.ProductPreview,
                params: {
                    id: this.currentProduct.id
                } as any
            });
        }
    }

    async getSheetLayout() {

        this.loadingOverlayVisible = true;

        const resizeParams = {...this.resizeParams as any};
        const curFluteDir = this.$store.getters.getSelectedFluteDirection;
        let sheetName = 'User Defined';

        const selectedCatalog = this.$store.getters['productLayout/selectedCatalog'];
        if (selectedCatalog) {
            const catalog = this.$store.getters['productLayout/catalog'];
            sheetName = catalog.find((c: any) => c.sheetId === (selectedCatalog).toString()).name;
        }

        const {width, height, margins, gapX, gapY} = this.$store.getters['productLayout/dimensions'];
        const calcInfo: any[] = [{
            sheets: [
                {
                    id: selectedCatalog || -1,
                    sheet: {
                        name: sheetName,
                        width,
                        height,
                        marginL: margins.left || 0,
                        marginT: margins.top || 0,
                        marginR: margins.right || 0,
                        marginB: margins.bottom || 0
                    }
                }
            ],
            oneups: [
                {
                    oneupId: resizeParams.drawingId,
                    count: 1
                }
            ],
            id: this.currentProduct.id,
            gapX: gapX || 0,
            gapY: gapY || 0
        }];

        calcInfo[0].fixedCounts = this.$store.getters['productLayout/fixedCount'];

        const template = this.$store.getters['productLayout/template'];
        if (template !== '') {
            calcInfo[0].layoutPatternId = template;
        }

        delete resizeParams.drawingId;

        const layout = await this.$store.dispatch('productLayout/layout', {
            urlSourceType: this.$route.params.type,
            productId: this.currentProduct.id,
            calcInfo,
            curFluteDir,
            rotate: this.$store.getters['productLayout/rotate'],
            resizeParams
        });

        this.$store.commit('productLayout/setPreviewUrl', this.setSVGUrl(layout?.layoutCalcResults[0]?.sheetOutputs[0]?.url));
        this.$store.commit('productLayout/setStats', layout.stats);

        this.loadingOverlayVisible = false;
        this.$store.commit('productLayout/setHasGeneratedLayout', true);
        this.$store.commit('productLayout/setDirty', false);
    }

    changeSheetType(sheetType: 'sheetSize' | 'rowsCols'): void {
        this.$store.commit('productLayout/setSheetType', sheetType);
        this.setDirty();
    }

    get isSheetSizeSelected(): boolean {
        return this.sheetType === 'sheetSize';
    }

    get sheetType() {
        return this.$store.getters['productLayout/sheetType'];
    }

    onDrawingError(error: ServerError) {
        // ResourceIDNotFound
        if (error.code === 2015 && this.isFile) {
            this.$store.commit('uploadFile/clearData');
            this.$router.push({name: Routes.UploadFile});
        }
    }

    setTemplate(template: string): void {
        this.$store.commit('productLayout/setTemplate', template);
        this.setDirty();
    }

    setWidth(width: number): void {
        this.$store.commit('productLayout/setWidth', width);
        this.setDirty();
    }

    setHeight(height: number): void {
        this.$store.commit('productLayout/setHeight', height);
        this.setDirty();
    }

    setRows(rows: number): void {
        this.$store.commit('productLayout/setRows', rows);
        this.setDirty();
    }

    setCols(cols: number): void {
        this.$store.commit('productLayout/setCols', cols);
        this.setDirty();
    }

    setRotate(rotate: boolean): void {
        this.$store.commit('productLayout/setRotate', rotate);
        this.setDirty();
    }

    get isFile(): boolean {
        return this.$route.params.type === ProductUrlSourceType.File;
    }

    get selectedUnit(): Units {
        return !this.isFile ? this.$store.getters['templateUnit'] : this.$store.getters['uploadFile/unit'];
    }

    get sheetSizeForTable(): string {
        const {width, height, margins, rows, cols} = this.$store.getters['productLayout/dimensions'];
        const stats = this.$store.getters['productLayout/stats'];
        if (this.sheetType === 'sheetSize' && Number(width) > 0 && Number(height) > 0) {
            return `${width} x ${height}`;
        } else if (stats && Number(rows) > 0 && Number(cols) > 0) {

            let usedWidth = stats.usedWidth;

            if (!UtilService.isEmptyOrNull(margins.left)) {
                usedWidth += parseInt(margins.left, 10);
            }

            if (!UtilService.isEmptyOrNull(margins.right)) {
                usedWidth += parseInt(margins.right, 10);
            }

            let usedHeight = stats.usedHeight;

            if (!UtilService.isEmptyOrNull(margins.top)) {
                usedHeight += parseInt(margins.top, 10);
            }

            if (!UtilService.isEmptyOrNull(margins.bottom)) {
                usedHeight += parseInt(margins.bottom, 10);
            }

            return `${numberRound(usedWidth)} x ${numberRound(usedHeight)}`;
        } else {
            return '-';
        }
    }

    get hasGeneratedLayout(): boolean {
        return this.$store.getters['productLayout/hasGeneratedLayout'];
    }

    get previewUrl(): string {
        return this.$store.getters['productLayout/previewUrl'];
    }

    get stats(): ProductLayoutStats {
        return this.$store.getters['productLayout/stats'];
    }

    get drawing(): ProductDrawing {
        return this.$store.getters['productLayout/drawing'];
    }

    get template(): string {
        return this.$store.getters['productLayout/template'];
    }

    get selectedCatalog(): any {
        return this.$store.getters['productLayout/selectedCatalog'];
    }

    get catalog(): any[] {
        return this.$store.getters['productLayout/catalog'];
    }

    get width(): number {
        return this.$store.getters['productLayout/width'];
    }

    get height(): number {
        return this.$store.getters['productLayout/height'];
    }

    get rows(): number {
        return this.$store.getters['productLayout/rows'];
    }

    get cols(): number {
        return this.$store.getters['productLayout/cols'];
    }

    get margins(): ProductLayoutMargins {
        return this.$store.getters['productLayout/margins'];
    }

    get gapX(): number {
        return this.$store.getters['productLayout/gapX'];
    }

    get gapY(): number {
        return this.$store.getters['productLayout/gapY'];
    }

    get rotate(): boolean {
        return this.$store.getters['productLayout/rotate'];
    }

    get dirty(): boolean {
        return this.$store.getters['productLayout/dirty'];
    }

    get ruleLengthSuffix(): string {
        return this.selectedUnit === Units.MM ? 'm' : 'ft';
    }

    private initBreadcrumbs() {

        this.breadcrumbs = [
            {
                text: 'breadcrumbs.newRequest',
                disabled: false,
                to: {
                    name: Routes.NewRequest
                }
            },
            ProductService.getRoute(this.$store.getters.getListFilters, this.$route.params.type as ProductUrlSourceType)
        ];

        if (!this.isFile) {
            this.breadcrumbs.push({
                plainText: this.currentProduct.name,
                disabled: false,
                to: {
                    name: Routes.ProductPreview,
                    params: {
                        id: this.currentProduct.id
                    }
                }
            });
        }

        this.breadcrumbs.push({
            text: 'products.breadcrumbs.createLayout',
            disabled: true
        });

    }

    private setSVGUrl(url: string): string {
        return url && drawingUrl(url, this.drawingPrefix);
    }

    private initFields([{
                           sheets: [{
                               id: sheetId,
                               sheet: {name: sheetName, width, height, marginL, marginT, marginR, marginB}
                           }], gapX, gapY, fixedCounts, layoutPatternId
                       }]: any,
                       rotate: boolean) {
        width && this.$store.commit('productLayout/setWidth', width);
        height && this.$store.commit('productLayout/setHeight', height);

       /* if (fixedCounts) {
            this.$store.commit('productLayout/setRows', fixedCounts.rows);
            this.$store.commit('productLayout/setCols', fixedCounts.cols);
            this.$store.commit('productLayout/setSheetType', 'rowsCols');
        }*/

        if (layoutPatternId) {
            this.$store.commit('productLayout/setTemplate', layoutPatternId);
        }

        const margins = {
            left: marginL,
            right: marginR,
            top: marginT,
            bottom: marginB
        };
        this.$store.commit('productLayout/setMargins', margins);

        if (gapX) {
            this.$store.commit('productLayout/setGapX', gapX);
        }

        if (gapY) {
            this.$store.commit('productLayout/setGapY', gapY);
        }

        this.$store.commit('productLayout/setRotate', rotate);

        if (sheetId !== -1) {
            this.$store.commit('productLayout/setSelectedCatalog', sheetId);
        }
    }

}
