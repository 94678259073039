import { Component, Prop } from 'vue-property-decorator';
import { UtilService } from '../../utils/util.service';
import BaseValidationInput from '../../validation/base-validation-input.component';
import { SelectFieldItem } from '@shared/components/select-field/select-field-item.interface';

@Component({
    inheritAttrs: false
})
export default class SelectField extends BaseValidationInput {
    @Prop({default: null}) label!: string;
    @Prop({default: ''}) value!: string;
    @Prop({
        default: () => {
            return `input-${UtilService.getUniqueNum()}`;
        }
    }) name!: string;

    @Prop({default: 'col-4'}) labelClass!: string;
    @Prop({default: 'col-8'}) inputClass!: string;
    @Prop({default: false}) hideDetails!: boolean;

    @Prop({default: []}) items!: SelectFieldItem[];

    handleChange(value: string) {
        this.$emit('input', value);
    }
}
