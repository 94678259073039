import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import ProductPreview from '../modules/products/pages/product-preview/product-preview.component.vue';
import ProductList from '../modules/products/pages/product-list/product-list.component.vue';
import { Routes } from '../routes.enum';
import { i18n, loadLanguageAsync } from '@shared/plugins/i18n';
import ProductLayout from '../modules/products/pages/product-layout/product-layout.component.vue';
import store from '../store/store';
import ProductCostEstimation from '../modules/products/pages/product-cost-estimation/product-cost-estimation.component.vue';
import ProductSendResizeTemplate from '../modules/products/pages/product-send-resize-template/product-send-resize-template.component.vue';
import ProductOrderRequest from '../modules/products/pages/product-order-request/product-order-request.component.vue';
import UserLogin from '../modules/users/pages/user-login/user-login.component.vue';
import UserRegister from '../modules/users/pages/user-register/user-register.component.vue';
import UserForgotten from '@shared/components/user-forgotten/user-forgotten.component.vue';
import OrderList from '../modules/orders/pages/order-list/order-list.component.vue';
import Profile from '../modules/profile/pages/profile/profile.component.vue';
import CompanyDetails from '../modules/profile/pages/company-details/company-details.component.vue';
import ContactPerson from '../modules/profile/pages/contact-person/contact-person.component.vue';
import MyDiemakers from '../modules/profile/pages/my-diemakers/my-diemakers.component.vue';
import OrderDetails from '../modules/orders/pages/order-details/order-details.component.vue';
import Landing from '../modules/landing/landing.component.vue';
import UserResetPassword from '@shared/modules/users/pages/user-reset-password/user-reset-password.component.vue';
import PageContainer from '@shared/components/page-container/page-container.component.vue';
import UserConfirmEmail from '@shared/modules/users/pages/user-confirm-email/user-confirm-email.component.vue';
import UserSetPassword from '../modules/users/pages/user-set-password/user-set-password.component.vue';
import UserResendActivation from '../modules/users/pages/user-resend-activation/user-resend-activation.component.vue';
import UserChooseDefaultDiemaker from '../modules/users/pages/user-choose-default-diemaker/user-choose-default-diemaker.component.vue';
import NewRequestLanding from '../modules/new-request-landing/new-request-landing.component.vue';
import ProductFavouriteList from '../modules/products/pages/product-favourite-list/product-favourite-list.component.vue';
import UploadFile from '../modules/products/pages/upload-file/upload-file.component.vue';
import FlatDiesComponent from '../modules/profile/pages/flat-dies/flat-dies.component.vue';
import RotaryDiesComponent from '../modules/profile/pages/rotary-dies/rotary-dies.component.vue';
import { BaseConfig } from '@shared/base-config';
import { Theme } from '@shared/themes/theme.enum';
import { diemakerResolver } from './resolvers/diemaker.resolver';
import { isAuthenticatedResolver } from './resolvers/is-authenticated.resolver';
import HelpPage from '@shared/components/help-page/help-page/help-page.component.vue';
import HelpPageLayout from '@shared/components/help-page/help-page-layout.component.vue';
import { HELP_PAGE_NAME } from '@shared/components/help-page/help-page-layout.component.vue';
import { preserveQueryParams } from '@shared/router/preserveQueryParams';
import Tos from '@shared/components/tos/tos.component.vue';
import Privacy from '@shared/components/privacy/privacy.component.vue';

Vue.use(VueRouter);

const poligrafRedirect = BaseConfig.theme === Theme.PoligrafBG ? {name: Routes.ProductList} : undefined;

const routes: RouteConfig[] = [
    {
        path: '/',
        name: Routes.Home,
        component: Landing,
        redirect: poligrafRedirect
    },
    {
        path: '/new-request',
        name: Routes.NewRequest,
        component: NewRequestLanding,
        redirect: poligrafRedirect,
        meta: {helpRoute: Routes.HelpMakingRequests}
    },
    {
        path: '/product',
        component: PageContainer,
        name: Routes.ProductRoot,
        redirect: {name: Routes.ProductList},
        children: [
            {path: 'upload', name: Routes.UploadFile, component: UploadFile, meta: {helpRoute: Routes.HelpImportFile}},
            {path: 'list', name: Routes.ProductList, component: ProductList, meta: {helpRoute: Routes.HelpMakingRequests}},
            {path: 'favourites', name: Routes.ProductFavouriteList, component: ProductFavouriteList, beforeEnter: isAuthenticatedResolver},
            {path: ':type/preview/:id', name: Routes.ProductPreview, component: ProductPreview, meta: {helpRoute: Routes.HelpMakingRequests}},
            {path: ':type/layout/:id', name: Routes.ProductLayout, component: ProductLayout, meta: {helpRoute: Routes.HelpMakingRequests}},
            {path: ':type/cost-estimation/:id', name: Routes.ProductCostEstimation, component: ProductCostEstimation, meta: {helpRoute: Routes.HelpMakingRequests}},
            {path: ':type/send/:id', name: Routes.ProductSendResizeTemplate, component: ProductSendResizeTemplate},
            {path: ':type/order-request/:id', name: Routes.ProductOrderRequest, component: ProductOrderRequest, meta: {helpRoute: Routes.HelpMakingRequests}}
        ]
    },
    {path: '/tos', name: 'tos', component: Tos},
    {path: '/privacy', name: 'privacy', component: Privacy},
    {path: '/user/login', name: Routes.UserLogin, component: UserLogin},
    {path: '/user/reset-password/:diemakerId/:token', name: Routes.UserResetPassword, component: UserResetPassword},
    {path: '/user/set-password/:diemakerId/:token', name: Routes.UserSetPassword, component: UserSetPassword},
    {path: '/user/confirm/:token', name: Routes.UserConfirmEmail, component: UserConfirmEmail},
    {path: '/user/register', name: Routes.UserRegister, component: UserRegister},
    {path: '/user/forgotten', name: Routes.UserForgotten, component: UserForgotten},
    {path: '/user/resend-activation', name: Routes.UserResendActivation, component: UserResendActivation},
    {path: '/user/select-diemaker', name: Routes.UserChooseDefaultDiemaker, component: UserChooseDefaultDiemaker},
    {
        path: '/order',
        component: PageContainer,
        name: Routes.OrderRoot,
        redirect: {name: Routes.OrderList},
        children: [
            {path: 'list', name: Routes.OrderList, component: OrderList, meta: {helpRoute: Routes.HelpMyRequests}},
            {path: ':id', name: Routes.OrderDetails, component: OrderDetails, meta: {helpRoute: Routes.HelpMyRequests}},
        ]
    },
    {
        path: '/profile', name: Routes.Profile, component: Profile,
        children: [
            {
                path: 'company-details',
                name: Routes.CompanyDetails,
                component: CompanyDetails,
                meta: {helpRoute: Routes.HelpProfile},
            },
            {
                path: 'contact-person',
                name: Routes.ContactPerson,
                component: ContactPerson,
                meta: {helpRoute: Routes.HelpProfile},
            },
            {
                path: 'my-diemakers',
                name: Routes.MyDiemakers,
                component: MyDiemakers,
                meta: {helpRoute: Routes.HelpProfile},
            },
            {
                path: 'flat-dies',
                name: Routes.FlatDies,
                component: FlatDiesComponent,
                meta: {helpRoute: Routes.HelpProfile},
            },
            {
                path: 'rotary-dies',
                name: Routes.RotaryDies,
                component: RotaryDiesComponent,
                meta: {helpRoute: Routes.HelpProfile},
            }
        ]
    },

    {
        path: '/help',
        component: HelpPageLayout,
        name: HELP_PAGE_NAME,
        redirect: {name: Routes.HelpMakingRequests},
        meta: {
            pages: [
                {
                    text: 'help.pages.customer.importFile',
                    route: Routes.HelpImportFile
                },
                {
                    text: 'help.pages.customer.makeRequest',
                    route: Routes.HelpMakingRequests
                },
                {
                    text: 'help.pages.customer.myRequests',
                    route: Routes.HelpMyRequests
                },
                {
                    text: 'help.pages.customer.profile',
                    route: Routes.HelpProfile
                }
            ]
        },
        children: [
            {
                path: 'import-file',
                name: Routes.HelpImportFile,
                component: HelpPage,
                meta: {template: 'customer-import-file'},
                beforeEnter: preserveQueryParams
            },
            {
                path: 'making-requests',
                name: Routes.HelpMakingRequests,
                component: HelpPage,
                meta: {template: 'customer-making-requests'},
                beforeEnter: preserveQueryParams
            },
            {
                path: 'profile',
                name: Routes.HelpProfile,
                component: HelpPage,
                meta: {template: 'customer-profiles-page'},
                beforeEnter: preserveQueryParams
            },
            {
                path: 'my-requests',
                name: Routes.HelpMyRequests,
                component: HelpPage,
                meta: {template: 'customer-my-requests-page'},
                beforeEnter: preserveQueryParams
            },
        ]
    },
    {path: '*', redirect: {name: Routes.Home}},
];

const router = new VueRouter({
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        return {x: 0, y: 0};
    }
});

router.beforeEach(diemakerResolver);

router.beforeEach((to, from, next) => {
    loadLanguageAsync(store.getters['language']).then(() => next());
});

router.beforeEach((to, from, next) => {

    const resetRoutes = [Routes.ProductList, Routes.NewRequest, Routes.ProductFavouriteList, Routes.Home];

    if (resetRoutes.includes(to.name as Routes) && from.path.includes('product')) {

        store.commit('set3dSelected' , false);

        if (store.getters['uploadFile/hasUploadData'] || store.getters.hasLayoutParams() || store.getters.hasResizeParams() || store.getters.hasCostEstimationParams()) {
            const answer = window.confirm(i18n.t('messages.unsavedChanges') as string);
            if (answer) {
                store.dispatch('endCADSession', !store.getters['uploadFile/hasUploadData'] ? store.getters['templateUnit'] : store.getters['uploadFile/unit']);

                store.commit('clearResizeParams');
                store.commit('clearLayoutParams');
                store.commit('clearCostEstimationParams');
                store.commit('productCostEstimation/reset');
                store.commit('productLayout/reset');
                store.commit('productMakeOrder/reset');
                store.commit('clearOrderRequestParams');
                store.commit('changePreviewOfCurrentProduct', {});
                store.commit('changeListFilters', null);
                store.commit('uploadFile/clearData');
                next();
            } else {
                next(false);
            }
            return;
        } else {
            store.dispatch('endCADSession', store.getters['templateUnit']);
            store.commit('changeListFilters', null);
            store.commit('uploadFile/clearData');
        }

    } else if ((from.name === Routes.ProductList || from.name === Routes.ProductFavouriteList) && to.name === Routes.ProductPreview) {
        store.commit('changeListFilters', from);
    }

    next();
});

router.beforeEach((to, from, next) => {
    if (store.getters['auth/isAuthenticated']) {
        if (store.getters['auth/isEmailConfirmed'] || to.name === Routes.UserResendActivation ||
            to.name === Routes.UserConfirmEmail || to.name === Routes.UserResetPassword || to.name === Routes.UserLogin) {
            next();
        } else {
            next({name: Routes.UserResendActivation, query: from.query});
        }
        return;
    } else {
        next();
    }
});

export default router;
