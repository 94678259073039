import Component from 'vue-class-component';
import ProductSection from '@shared/modules/products/components/product-section/product-section.component.vue';
import Vue from 'vue';
import { StrippingRulesInfo } from '../../models/stripping-rules-info.interface';

@Component({
    components: {
        ProductSection
    }
})
export default class ProductRulesSummary extends Vue {

    get strippingRulesInfo(): StrippingRulesInfo {
        return this.$store.getters['productCostEstimation/strippingRulesInfo'];
    }

    get hasCompensatingRules(): boolean {
        return this.$store.getters['productCostEstimation/hasCompensatingRules'];
    }

    get rules(): Array<{ selectedThickness: string; selectedBrand: string; type: string }> {
        return this.$store.getters['productCostEstimation/rules'];
    }
}
