import { evaluate } from 'mathjs';

export class MathService {
    static validate(value: any): boolean  {
        try {
            value = value.toString().replaceAll(' ', '+');
            return typeof evaluate(value) === 'number';
        } catch (ex) {
            return false;
        }
    }

    static calculate(value: any): string {
        try {
            value = value.toString().replaceAll(' ', '+');
            const res = evaluate(value);

            if (typeof res === 'number') {
                return res.toFixed(3);
            } else {
                throw Error();
            }
        } catch (ex) {
            throw Error('Invalid Number');
        }
    }
}
