import { DiemakerRuleMainType } from '@shared/modules/products/enums/diemaker-rule-main-type.enum';
import { DieboardType } from '@shared/modules/products/models/dieboard-type.enum';

function diecutterFilter(dieboardCatalog: any[], type: DiemakerRuleMainType) {

    const showOnly = type === DiemakerRuleMainType.Flat ? [DieboardType.FixedSize, DieboardType.AutoSize] : [DieboardType.RotaryFixedSize, DieboardType.RotaryAutoSize];

    return dieboardCatalog
        .filter((item: any) => showOnly.includes(item.dieboardType));
}

export default diecutterFilter;
