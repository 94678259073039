import { ProductMaterialCuttingSide } from '../../modules/products/models/product-material-cutting-side.enum';
import { ProductUrlSourceType } from '../../modules/products/enums/product-url-source-type.enum';
import { DrawingType } from '@shared/modules/products/enums/drawing-type.enum';
import { ProductService } from '../../modules/products/product.service';
import { ProductDrawing } from '@shared/modules/products/models/product-drawing.model';
import { CostEstimationBaseRequest } from '../../modules/products/models/cost-estimation/cost-estimation-base.request';
import drawingUrl from '@shared/filters/drawing-url.filter';
import { LayoutCalcSheetInfo } from '../../modules/products/models/layout-calc-sheet-info.interface';
import { StrippingDieInfo } from '../../modules/products/models/stripping-die-info.interface';
import { StrippingRulesInfo } from '../../modules/products/models/stripping-rules-info.interface';
import { DieboardInfo } from '../../modules/products/models/dieboard-info.interface';
import { DiemakerRule } from '../../modules/products/models/diemaker-rule.interface';
import { DiemakerRuleMainType } from '@shared/modules/products/enums/diemaker-rule-main-type.enum';
import { filterRulesWithStatsLength, getRulePrice } from './helpers/product-rules.helpers';
import { RuleType } from '@shared/modules/products/models/rule-type.enum';

export enum DieboardType {
    Diecutter = 'diecutter',
    UserDefined = 'userDefined'
}

export enum DieboardSizeType {
    Fixed = 'fixed',
    Auto = 'auto'
}

export interface Dieboard {
    dieCutter: string | null;
    type: DieboardType;
    sizeType: DieboardSizeType;
    userDefined: string;
    width: number | null;
    height: number | null;
    marginL: number | null;
    marginR: number | null;
    marginB: number | null;
    marginT: number | null;
    custom: boolean;
}

function createInitialState(): ProductCostEstimationState {
    return {
        cuttingDieSide: ProductMaterialCuttingSide.DCS_FrontCuttingDie,
        dirty: true,
        topPreview: null,
        bottomPreview: null,
        strippingDieInfo: null,
        hasFemaleStrippingDie: false,
        hasFrontStrippingDie: false,
        hasMaleStrippingDie: false,
        strippingRulesInfo: {
            enabled: false,
            horizontal: '',
            vertical: ''
        },
        isDisabledFrontStrippingDie: true,
        isDisabledMaleStrippingDie: true,
        isDisabledFemaleStrippingDie: true,
        hasCompensatingRules: false,
        dieboard: {
            dieCutter: null,
            type: DieboardType.Diecutter,
            sizeType: DieboardSizeType.Fixed,
            userDefined: '',
            width: null,
            height: null,
            marginL: null,
            marginR: null,
            marginB: null,
            marginT: null,
            custom: false
        },
        rules: {
            flat: [],
            rotary: []
        },
        ruleType: DiemakerRuleMainType.Flat,
        diecutterFormValid: false,
        boardId: null,
        boardList: []
    };
}

export interface ProductCostEstimationState {
    cuttingDieSide: ProductMaterialCuttingSide;
    dirty: boolean;
    topPreview: string | null;
    bottomPreview: string | null;
    strippingDieInfo: StrippingDieInfo | null;
    hasMaleStrippingDie: boolean;
    hasFemaleStrippingDie: boolean;
    hasFrontStrippingDie: boolean;
    strippingRulesInfo: StrippingRulesInfo;
    isDisabledMaleStrippingDie: boolean;
    isDisabledFemaleStrippingDie: boolean;
    isDisabledFrontStrippingDie: boolean;
    hasCompensatingRules: boolean;
    dieboard: Dieboard;
    rules: {
        flat: DiemakerRule[],
        rotary: DiemakerRule[]
    };
    ruleType: DiemakerRuleMainType;
    diecutterFormValid: boolean;
    boardId: number | null;
    boardList: any[];
}

export const ProductCostEstimationStore = {
    namespaced: true,
    state: createInitialState(),
    actions: {
        async costEstimation({ commit, state, getters, rootGetters }: { commit: any, state: ProductCostEstimationState, getters: any, rootGetters: any }, {
            urlSourceType,
            productId,
            prices,
            percentages,
            strippingRulesInfo,
            discount,
            hasCompensatingRules
        }: any): Promise<any> {

            const { paramMap, material, fluteDir } = rootGetters.getResizeParams(productId);
            const { calcInfo, rotate } = rootGetters.getLayoutParams(productId);

            const copyCalfInfo = JSON.parse(JSON.stringify(calcInfo));

            const dieboard = getters['dieboardInfo'];
            copyCalfInfo[0].sheets[0].dieboard = dieboard;
            copyCalfInfo[0].fixedCounts = rootGetters['productLayout/fixedCount'];

            const costEstimationsParams = { ...prices, ...percentages, strippingRulesInfo, hasCompensatingRules };

            if (costEstimationsParams) {
                costEstimationsParams.discount = discount;
            }

            if (rootGetters['diemaker']?.internalToLocalCurrency) {
                costEstimationsParams.targetCurrencyRate = rootGetters['diemaker'].internalToLocalCurrency;
            }

            const layoutCalcSheetInfo = copyCalfInfo[0].sheets[0] as LayoutCalcSheetInfo;

            const baseRequest = {
                cuttingRuleCost: costEstimationsParams.cuttingRuleCost,
                creasingRuleCost: costEstimationsParams.creasingRuleCost,
                cutCreaseRuleCost: costEstimationsParams.cutCreaseRuleCost,
                perfoRuleCost: costEstimationsParams.perfoRuleCost,
                targetCurrencyRate: costEstimationsParams.targetCurrencyRate,
                hasMaleStrippingDie: costEstimationsParams.hasMaleStrippingDie,
                hasFemaleStrippingDie: costEstimationsParams.hasFemaleStrippingDie,
                hasFrontStripper: costEstimationsParams.hasFrontStripper,
                maleStrippingDieCostPercentage: costEstimationsParams.maleStrippingDieCostPercentage,
                femaleStrippingDieCostPercentage: costEstimationsParams.femaleStrippingDieCostPercentage,
                frontStripperCostPercentage: costEstimationsParams.frontStripperCostPercentage,
                discount: costEstimationsParams.discount,
                boardCost: getters['boardCost']
            } as CostEstimationBaseRequest;

            const cuttingSide = state.cuttingDieSide;

            if (urlSourceType === ProductUrlSourceType.File) {

                const type = rootGetters['uploadFile/type'];

                if (type === DrawingType.OneUp) {
                    return ProductService.costEstimationFromOneUpFile(productId,
                        {
                            ...baseRequest,
                            calcInfo: [
                                {
                                    sheets: [layoutCalcSheetInfo],
                                    layoutPatternId: copyCalfInfo[0].layoutPatternId,
                                    oneups: copyCalfInfo[0].oneups,
                                    id: rootGetters['uploadFile/drawingId'],
                                    gapX: copyCalfInfo[0].gapX,
                                    gapY: copyCalfInfo[0].gapY,
                                    fixedCounts: rootGetters['productLayout/fixedCount']
                                }
                            ],
                            material: { ...material, cuttingSide },
                            fluteDir,
                            force90DegRotate: rotate,
                            hasCompensatingRules: costEstimationsParams.hasCompensatingRules,
                            strippingRulesInfo: costEstimationsParams.strippingRulesInfo,
                            paramMap
                        }
                    );
                } else if (type === DrawingType.Layout) {
                    return ProductService.costEstimationFromLayoutFile(productId,
                        {
                            ...baseRequest,
                            drawingInfo: {
                                dieboard,
                                sheet: copyCalfInfo[0].sheets[0].sheet,
                                id: rootGetters['uploadFile/drawingId'],
                            },
                            material: { ...material, cuttingSide },
                            fluteDir,
                            force90DegRotate: rotate,
                            hasCompensatingRules: costEstimationsParams.hasCompensatingRules,
                            strippingRulesInfo: costEstimationsParams.strippingRulesInfo,
                        }
                    );
                } else {
                    return ProductService.costEstimationFromDiebiardFile(productId,
                        {
                            ...baseRequest,
                            drawingId: rootGetters['uploadFile/drawingId']
                        }
                    );
                }

            } else {
                return ProductService.costEstimation(productId,
                    {
                        paramMap,
                        material: { ...material, cuttingSide },
                        fluteDir,
                    },
                    { calcInfo: copyCalfInfo, force90DegRotate: rotate },
                    {...costEstimationsParams, boardCost: getters['boardCost']}
                );
            }
        },
        setInitialTopPreview({ state, commit, rootGetters }: { state: ProductCostEstimationState, commit: any, rootGetters: any }, { isDieboard, fromFile, drawing }: { isDieboard: boolean, fromFile: boolean, drawing: ProductDrawing }): void {
            const frontSideUrl = rootGetters['uploadFile/svgUrl'];
            const backSideUrl = rootGetters['uploadFile/flippedSvgUrl'];
            let initialTopUrl = '';
            if (isDieboard) {
                state.cuttingDieSide === ProductMaterialCuttingSide.DCS_FrontCuttingDie ?
                    initialTopUrl = backSideUrl :
                    initialTopUrl = frontSideUrl;
            } else if (fromFile) {
                initialTopUrl = frontSideUrl;
            } else {
                initialTopUrl = drawing.svgURL;
            }
            commit('setTopPreview', initialTopUrl);
        },
        setInitialBottomPreview({ state, commit, rootGetters }: { state: ProductCostEstimationState, commit: any, rootGetters: any }, { savedLayoutParams, isDieboard, prefix }: { savedLayoutParams: any, isDieboard: boolean, prefix: string }): void {
            let initialBottomPreview = '';
            if (isDieboard) {
                initialBottomPreview = rootGetters['uploadFile/svgUrl'];
            } else {
                const url = savedLayoutParams.layoutResponse?.layoutCalcResults[0]?.sheetOutputs[0]?.url as string;
                initialBottomPreview = !url.startsWith('http') ? drawingUrl(url, prefix) : url;
            }

            commit('setBottomPreview', initialBottomPreview);
        },
        setInitialStrippingDieInfoAndBoxes({state, commit}: {state: ProductCostEstimationState, commit: any}, strippingDieInfo: StrippingDieInfo): void {
            commit('setStrippingDieInfo', strippingDieInfo);
            const shouldDisableStrippingDieBoxes = !strippingDieInfo || Object.keys(strippingDieInfo).length <= 0;
            if (shouldDisableStrippingDieBoxes) {
                commit('disableAllStrippingDieBoxes');
            } else {
                const shouldDisableFemaleStrippingDieBox = strippingDieInfo.flat.femaleStrippingDieCostPercentage <= 0 || !strippingDieInfo.flat.femaleStrippingDieCostPercentage;
                const shouldDisableMaleStrippingDieBox = strippingDieInfo.flat.maleStrippingDieCostPercentage <= 0 || !strippingDieInfo.flat.maleStrippingDieCostPercentage;
                const shouldDisableFrontStrippingDieBox = strippingDieInfo.flat.frontStripperCostPercentage <= 0 || !strippingDieInfo.flat.frontStripperCostPercentage;
                commit('setIsDisabledFemaleStrippingDie', shouldDisableFemaleStrippingDieBox);
                commit('setIsDisabledMaleStrippingDie', shouldDisableMaleStrippingDieBox);
                commit('setIsDisabledFrontStrippingDie', shouldDisableFrontStrippingDieBox);
            }
        },
        loadRules({state, commit, rootGetters}: {state: ProductCostEstimationState, commit: any, rootGetters: any}, {rules, layoutStats}: any) {

            if (state.rules.flat.length || state.rules.rotary.length) {
                return;
            }

            let type = DiemakerRuleMainType.Flat;

            if (!rootGetters['diemaker'].supportsFlat) {
                type = DiemakerRuleMainType.Rotary;
            }

            commit('setRuleType', type);

            rules.flat && filterRulesWithStatsLength(rules.flat, layoutStats).forEach((rule) => {
                state.rules.flat.push(rule);
            });

            rules.rotary && filterRulesWithStatsLength(rules.rotary, layoutStats).forEach((rule) => {
                state.rules.rotary.push(rule);
            });
        },

        setRuleType({state, commit, rootGetters}: {state: ProductCostEstimationState, commit: any, rootGetters: any}, type: DiemakerRuleMainType) {
            commit('setRuleType', type);
            commit('clearDieboardDiecutter');
        }

    },
    mutations: {
        setCuttingDieSide(state: ProductCostEstimationState, side: ProductMaterialCuttingSide): void {
            state.cuttingDieSide = side;
        },
        setTopPreview(state: ProductCostEstimationState, url: string): void {
            state.topPreview = url;
        },
        setBottomPreview(state: ProductCostEstimationState, url: string): void {
            state.bottomPreview = url;
        },
        setDirty(state: ProductCostEstimationState, dirty: boolean): void {
            state.dirty = dirty;
        },
        setStrippingDieInfo(state: ProductCostEstimationState, strippingDieInfo: StrippingDieInfo): void {
            state.strippingDieInfo = strippingDieInfo;
        },
        reset(state: ProductCostEstimationState): void {
            const _state = createInitialState();

            for (const _key of Object.keys(state)) {
                const key = _key as keyof ProductCostEstimationState;
                // @ts-ignore
                state[key] = _state[key];
            }
        },
        setHasMaleStrippingDie(state: ProductCostEstimationState, hasMaleStrippingDie: boolean): void {
            state.hasMaleStrippingDie = hasMaleStrippingDie;
        },
        setHasFemaleStrippingDie(state: ProductCostEstimationState, hasFemaleStrippingDie: boolean): void {
            state.hasFemaleStrippingDie = hasFemaleStrippingDie;
        },
        setHasFrontStrippingDie(state: ProductCostEstimationState, hasFrontStrippingDie: boolean): void {
            state.hasFrontStrippingDie = hasFrontStrippingDie;
        },
        setIsDisabledMaleStrippingDie(state: ProductCostEstimationState, isDisabledMaleStrippingDie: boolean): void {
            state.isDisabledMaleStrippingDie = isDisabledMaleStrippingDie;
        },
        setIsDisabledFemaleStrippingDie(state: ProductCostEstimationState, isDisabledFemaleStrippingDie: boolean): void {
            state.isDisabledFemaleStrippingDie = isDisabledFemaleStrippingDie;
        },
        setIsDisabledFrontStrippingDie(state: ProductCostEstimationState, isDisabledFrontStrippingDie: boolean): void {
            state.isDisabledFrontStrippingDie = isDisabledFrontStrippingDie;
        },
        disableAllStrippingDieBoxes(state: ProductCostEstimationState): void {
            state.isDisabledFemaleStrippingDie = true;
            state.isDisabledFrontStrippingDie = true;
            state.isDisabledMaleStrippingDie = true;
        },
        setStrippingRulesInfoHorizontal(state: ProductCostEstimationState, horizontal: string): void {
            state.strippingRulesInfo.horizontal = horizontal;
        },
        setStrippingRulesInfoVertical(state: ProductCostEstimationState, vertical: string): void {
            state.strippingRulesInfo.vertical = vertical;
        },
        setStrippingRulesInfoEnabled(state: ProductCostEstimationState, enabled: boolean): void {
            state.strippingRulesInfo.enabled = enabled;

            if (!enabled) {
                state.strippingRulesInfo.horizontal = '';
                state.strippingRulesInfo.vertical = '';
            }
        },
        setHasCompensatingRules(state: ProductCostEstimationState, hasCompensatingRules: boolean): void {
            state.hasCompensatingRules = hasCompensatingRules;
        },
        setDieboard(state: ProductCostEstimationState, values: Partial<Dieboard>): void {
            state.dieboard.dieCutter = values.dieCutter ?? state.dieboard.dieCutter;
            state.dieboard.type = values.type ?? state.dieboard.type;
            state.dieboard.sizeType = values.sizeType ?? state.dieboard.sizeType;
            state.dieboard.userDefined = values.userDefined ?? state.dieboard.userDefined;
            state.dieboard.width = values.width ?? state.dieboard.width;
            state.dieboard.height = values.height ?? state.dieboard.height;
            state.dieboard.marginL = values.marginL ?? state.dieboard.marginL;
            state.dieboard.marginR = values.marginR ?? state.dieboard.marginR;
            state.dieboard.marginB = values.marginB ?? state.dieboard.marginB;
            state.dieboard.marginT = values.marginT ?? state.dieboard.marginT;
            state.dieboard.custom = values.custom ?? state.dieboard.custom;
        },
        clearDieboardDiecutter(state: ProductCostEstimationState): void {
            state.dieboard.dieCutter = null;
        },
        clearDieboard(state: ProductCostEstimationState): void {
            state.dieboard.width = null;
            state.dieboard.height = null;
            state.dieboard.marginL = null;
            state.dieboard.marginR = null;
            state.dieboard.marginB = null;
            state.dieboard.marginT = null;
        },
        setRuleType(state: ProductCostEstimationState, type: DiemakerRuleMainType) {
            state.ruleType = type;
        },
        setRuleThickness(state: ProductCostEstimationState, {index, thickness}: {index: number, thickness: string}) {
            const prev = state.rules[state.ruleType][index];
            state.rules[state.ruleType].splice(index, 1, {...prev, selectedThickness: thickness});
        },
        setRuleBrand(state: ProductCostEstimationState, {index, brand}: {index: number, brand: string}) {
            const prev = state.rules[state.ruleType][index];
            state.rules[state.ruleType].splice(index, 1, {...prev, selectedBrand: brand});
        },
        setDiecutterFormValid(state: ProductCostEstimationState, diecutterFormValid: boolean) {
            state.diecutterFormValid = diecutterFormValid;
        },
        setBoardId(state: ProductCostEstimationState, boardId: number): void {
            state.boardId = boardId;
        },
        setBoardList(state: ProductCostEstimationState, boards: any[]): void {
            state.boardList = boards;
        },
    },
    getters: {
        topPreview: (state: ProductCostEstimationState): any => state.topPreview,
        bottomPreview: (state: ProductCostEstimationState): any => state.bottomPreview,
        cuttingDieSide: (state: ProductCostEstimationState): ProductMaterialCuttingSide | null => state.cuttingDieSide,
        dirty: (state: ProductCostEstimationState): boolean => state.dirty,
        strippingDieInfo: (state: ProductCostEstimationState): StrippingDieInfo => state.strippingDieInfo as StrippingDieInfo,
        hasMaleStrippingDie: (state: ProductCostEstimationState): boolean => state.hasMaleStrippingDie,
        hasFemaleStrippingDie: (state: ProductCostEstimationState): boolean => state.hasFemaleStrippingDie,
        hasFrontStrippingDie: (state: ProductCostEstimationState): boolean => state.hasFrontStrippingDie,
        isDisabledMaleStrippingDie: (state: ProductCostEstimationState): boolean => state.isDisabledMaleStrippingDie,
        isDisabledFemaleStrippingDie: (state: ProductCostEstimationState): boolean => state.isDisabledFemaleStrippingDie,
        isDisabledFrontStrippingDie: (state: ProductCostEstimationState): boolean => state.isDisabledFrontStrippingDie,
        strippingRulesInfo: (state: ProductCostEstimationState): StrippingRulesInfo => state.strippingRulesInfo,
        strippingRulesInfoHorizontal: (state: ProductCostEstimationState): string => state.strippingRulesInfo.horizontal,
        strippingRulesInfoVertical: (state: ProductCostEstimationState): string => state.strippingRulesInfo.vertical,
        strippingRulesInfoEnabled: (state: ProductCostEstimationState): boolean => state.strippingRulesInfo.enabled,
        hasCompensatingRules: (state: ProductCostEstimationState): boolean => state.hasCompensatingRules,
        dieboard: (state: ProductCostEstimationState): Dieboard => state.dieboard,
        ruleType: (state: ProductCostEstimationState): DiemakerRuleMainType => state.ruleType,
        rules: (state: ProductCostEstimationState): DiemakerRule[] => state.rules[state.ruleType],
        boardId: (state: ProductCostEstimationState) => state.boardId,
        boardList: (state: ProductCostEstimationState) => state.boardList,
        isDieboardSizeEntered: (state: ProductCostEstimationState): boolean => {
            return !!state.dieboard.marginB && !!state.dieboard.marginL && !!state.dieboard.marginR && !!state.dieboard.marginT &&
                !!state.dieboard.height && !!state.dieboard.width;
        },
        dieboardInfo: (state: ProductCostEstimationState): DieboardInfo => {
            return {
                name: state.dieboard.dieCutter ?? state.dieboard.userDefined,
                userDefined: !state.dieboard.dieCutter || state.dieboard.custom,
                widthSizing: state.dieboard.sizeType !== DieboardSizeType.Auto ? 0 : 2,
                heightSizing: state.dieboard.sizeType !== DieboardSizeType.Auto ? 0 : 2,
                width: state.dieboard.sizeType !== DieboardSizeType.Auto && state.dieboard.width ? state.dieboard.width : undefined,
                height: state.dieboard.sizeType !== DieboardSizeType.Auto && state.dieboard.height ? state.dieboard.height : undefined,
                marginL: state.dieboard.marginL ?? undefined,
                marginR: state.dieboard.marginR ?? undefined,
                marginT: state.dieboard.marginT ?? undefined,
                marginB: state.dieboard.marginB ?? undefined,
            } as DieboardInfo;
        },
        isRulesValid: (state: ProductCostEstimationState): boolean => {
            for (const rule of state.rules[state.ruleType]) {
                if (!rule.selectedBrand) {
                    return false;
                }
            }

            return true;
        },
        getRulesCosts: (state: ProductCostEstimationState): {cuttingRuleCost: number | undefined; creasingRuleCost: number | undefined;
            cutCreaseRuleCost: number | undefined; perfoRuleCost: number | undefined} => {

            let cuttingRuleCost;
            let creasingRuleCost;
            let cutCreaseRuleCost;
            let perfoRuleCost;

            for (const rule of state.rules[state.ruleType]) {
                switch (rule.type) {
                    case RuleType.Cut:
                        cuttingRuleCost = getRulePrice(rule);
                        break;
                    case RuleType.Crease:
                        creasingRuleCost = getRulePrice(rule);
                        break;
                    case RuleType.CutCrease:
                        cutCreaseRuleCost = getRulePrice(rule);
                        break;
                    case RuleType.Perfo:
                        perfoRuleCost = getRulePrice(rule);
                        break;
                }
            }

            return {
                cuttingRuleCost,
                creasingRuleCost,
                cutCreaseRuleCost,
                perfoRuleCost
            };
        },
        bottomPreviewVisible: (state: ProductCostEstimationState): boolean => {
            return state.dieboard.type !== DieboardType.Diecutter || (state.dieboard.type === DieboardType.Diecutter && !!state.dieboard.dieCutter);
        },
        diecutterFormValid: (state: ProductCostEstimationState): boolean => state.diecutterFormValid,
        boardCost: (state: ProductCostEstimationState): number => {
            if (!state.boardId || !state.boardList.length) {
                return 0;
            }
            return state.boardList.find((b: any) => b.dbId === state.boardId).price;
        }
    }
};
