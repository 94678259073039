export const BaseConfig = {
    // apiRoot: process.env['VUE_APP_API_ROOT'],
    defaultLanguage: 'en',
    availableLanguages: {
        en: 'English',
        fr: 'French',
        de: 'German',
        es: 'Spanish',
        // es_MX: 'Spanish (Mexico)',
        bg: 'Bulgarian',
        hu: 'Hungarian',
        tr: 'Turkish',
        uk: 'Ukrainian'
    },
    theme: process.env['VUE_APP_THEME'] || null,
    privacyPolicyLink: process.env['VUE_APP_PRIVACY_POLICY_LINK'] || '#',
    termsAndConditionsLink: process.env['VUE_APP_TERMS_AND_CONDITIONS_LINK'] || '#',
    projectType: process.env['VUE_APP_PROJECT'],
    projectDiemakerType: process.env['VUE_APP_PROJECT_DIEMAKER'],
    webLibraryToken: process.env['VUE_APP_WEB_LIBRARY_TOKEN'],
    get apiWebStandardsLibrary(): string {
        return process.env['VUE_APP_WEB_STANDARDS_LIBRARY'];
    },
    get apiPortal(): string {
        return process.env['VUE_APP_API_ROOT'];
    },
    get diemakerPortalUrl(): string {
        return process.env['VUE_APP_DIEMAKER_PORTAL'];
    },
    get customerPortalUrl(): string {
        return process.env['VUE_APP_CUSTOMER_PORTAL'];
    },
    get superadminPortalUrl(): string {
        return process.env['VUE_APP_SUPERADMIN_PORTAL'];
    },
};
