import { ConfirmModalData } from '@shared/components/confirm-modal/confirm-modal-data.model';

export const confirmModuleNamespace = 'confirmModal';
export const confirmModalShowAction = `${confirmModuleNamespace}/show`;

export const confirmModalModule = {
    namespaced: true,
    state: {
        data: null
    },
    mutations: {
        setData(state: any, data: ConfirmModalData) {
            state.data = data;
        }
    },
    actions: {
        show({ commit }: any, payload: ConfirmModalData) {
            commit('setData', payload);
        }
    },
};
