import { Component, Vue, Watch } from 'vue-property-decorator';
import { Routes } from '../../../../routes.enum';
import FormErrorMessage from '@shared/components/form-error-message/form-error-message.component.vue';
import LoadingButton from '@shared/components/loading-button/loading-button.component.vue';
import { DiemakerService } from '../../../profile/services/diemakers.service';
import { Diemaker } from '@shared/modules/diemaker/models/diemaker.model';

@Component({
  components: {
    FormErrorMessage,
    LoadingButton
  },
  beforeRouteLeave(to, from, next) {
    if (!this.$store.getters.getUserDefaultDiemaker && this.$store.getters['auth/isAuthenticated']) {
      next(false);
    } else {
      next();
    }
  }
})
export default class UserChooseDefaultDiemaker extends Vue {

  diemakers: Diemaker[] = [];
  selectedDiemaker: null | Diemaker = null;
  loadingOverlayVisible = true;

  async created() {
    this.diemakers = await DiemakerService.getPublicDiemakers();
    this.loadingOverlayVisible = false;
  }

  async setAsCurrent(stopLoading: () => void) {
    if (this.selectedDiemaker) {
      await this.$store.dispatch('setUserCurrentDiemaker', this.selectedDiemaker.uuid);
      this.redirect();
    }
    stopLoading();
  }

  private redirect() {
    if (this.$route.query.redirectTo) {
      const redirectTo = this.$route.query.redirectTo as string;
      this.$router.push({path: redirectTo});
    } else {
      this.$router.push({name: Routes.NewRequest});
    }
  }
}
