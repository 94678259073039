import { Component, Vue, Watch } from 'vue-property-decorator';
import { Routes } from '../../../../routes.enum';
import FormErrorMessage from '@shared/components/form-error-message/form-error-message.component.vue';
import LoadingButton from '@shared/components/loading-button/loading-button.component.vue';
import CustomInput from '@shared/components/custom-input/custom-input.component.vue';

@Component({
    components: {
        FormErrorMessage,
        CustomInput,
        LoadingButton
    }
})
export default class UserLogin extends Vue {

    username = '';
    password = '';
    errorMessage: string | null = null;
    readonly Routes = Routes;

    async submit(stopLoading: () => void) {
        this.errorMessage = null;

        if (this.validate()) {
            try {
                await this.$store.dispatch('auth/login', {
                    username: this.username,
                    password: this.password,
                    requestType: 'customer'
                });

                await this.$store.dispatch('loadDefaultDiemaker', {reload: true});

                if (this.$store.getters['auth/language']) {
                    this.$store.commit('setLanguage', this.$store.getters['auth/language']);
                }

                stopLoading();
                (this.$refs.form as any).reset();

                if (!this.$store.getters.getUserDefaultDiemaker) {
                    this.$router.push({name: Routes.UserChooseDefaultDiemaker, query: {redirectTo: this.redirectTo}});
                    return;
                } else {
                    if (this.redirectTo) {
                        this.$router.push({path: this.redirectTo});
                        return;
                    } else {
                        this.$router.push({name: Routes.NewRequest});
                    }
                }
            } catch (e) {
                if (e.message !== 'redirect') {
                    this.errorMessage = `errors.${e.code}`;
                }
            }

            stopLoading();

        } else {
            stopLoading();
        }
    }

    get redirectTo(): string {
        return this.$route.query.redirectTo as string;
    }

    get showConfirm(): boolean {
        return !this.errorMessage && !!this.$route.query.confirmed;
    }

    private validate(): boolean {
        return (this.$refs.form as any).validate();
    }
}
