import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
    components: {
    }
})
export default class UploadFileGuidelinesModal extends Vue {
    dialog = true;

    html = '';

    created() {
        const data = require('raw-loader!../../../../../assets/guidelines/' + this.$i18n.locale + '.html');
        this.html = data.default;
    }

    close() {
        this.$emit('closed');
    }

    @Watch('dialog')
    onDialogChange() {
        if (!this.dialog) {
            this.$nextTick(() => {
                this.$emit('closed');
            });
        }
    }
}
