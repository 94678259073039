import { NotificationTypes } from '@shared/components/notification/notification.types';

export default {
    namespaced: true,
    state: {
        text: '',
        color: NotificationTypes.default,
        timeout: 0
    },
    mutations: {
        showMessage(state: any, {text, color, timeout}: any) {
            state.text = text;
            state.color = color;
            state.timeout = timeout;
        }
    },
    actions: {
        showSuccess({ commit }: any, payload: any) {
            payload.color = NotificationTypes.success;
            commit('showMessage', payload);
        },
        showError({ commit }: any, payload: any) {
            payload.color = NotificationTypes.error;
            commit('showMessage', payload);
        },
        showWarning({commit}: any, payload: any) {
            payload.color = NotificationTypes.warning;
            commit('showMessage', payload);
        },
    },
};
