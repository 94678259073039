import { UserService } from '../modules/users/services/user.service';
import { BaseConfig } from '@shared/base-config';
import { ProjectType } from '@shared/enums/project-type.enum';
import { DrawingType } from '@shared/modules/products/enums/drawing-type.enum';
import { ViewSide } from '@shared/modules/products/enums/view-side.enum';

function hasRole({user: {roles}}: any, role: string): boolean {
    return !!roles.find(({name}: any) => name.toLowerCase() === role);
}

const localStorageUserKey = `${BaseConfig.projectType}_auth`;
const localStorageOrganisationIdKey = `organizationUuid`;
const localStorageCustomerWorkAsIdKey = `customerWorkAsUuid`;

interface AuthState {
    loggedUser: any;
    organizationUuid: string | null;
    customerWorkAsId: string | null;
}

function createInitialState(): AuthState {
    const organizationUuid = sessionStorage.getItem(localStorageOrganisationIdKey);
    let loggedUser = null;
    if (localStorage.getItem(localStorageUserKey)) {
        loggedUser = JSON.parse(localStorage.getItem(localStorageUserKey) as any);
    } else if (sessionStorage.getItem(localStorageUserKey)) {
        loggedUser = JSON.parse(sessionStorage.getItem(localStorageUserKey) as any);
    }

    const customerWorkAsId = sessionStorage.getItem(localStorageCustomerWorkAsIdKey);

    return {
        loggedUser,
        organizationUuid,
        customerWorkAsId
    };
}

export default {
    namespaced: true,
    state: createInitialState(),
    actions: {
        async login({state, commit}: { state: AuthState, commit: any }, {username, password, requestType}: any) {
            const data = await UserService.login(username, password, requestType);
            commit('login', data);
        },
        async logout({commit, dispatch, getters}: { commit: any, dispatch: any, getters: any }, options = {status: null, code: null}) {

            if (!options.status) {
                await UserService.logout();
            }
            commit('logout', options);
            commit('setOrganizationUuid', null);
            commit('setCustomerWorkAsId', null);
        },
        workAs({state, commit}: { state: AuthState, commit: any }, diemakerId: string) {
            commit('setOrganizationUuid', diemakerId);
            sessionStorage.setItem(`${BaseConfig.projectDiemakerType}_auth`, JSON.stringify(state.loggedUser));
            location.href = BaseConfig.diemakerPortalUrl;
        },
        revertWorkAs({state, commit}: { state: AuthState, commit: any }) {
            commit('setOrganizationUuid', null);
            sessionStorage.removeItem(`${BaseConfig.projectType}_auth`);
            location.href = BaseConfig.superadminPortalUrl;
        },
        workAsCustomer({state, commit}: { state: AuthState, commit: any }, customerWorkAsId: string) {
            commit('setCustomerWorkAsId', customerWorkAsId);
            sessionStorage.setItem(`${ProjectType.customer}_auth`, JSON.stringify(state.loggedUser));
            const url = !BaseConfig.customerPortalUrl.endsWith('/') ? `${BaseConfig.customerPortalUrl}/` : BaseConfig.customerPortalUrl;
            location.href = `${url}#/product/list?diemakerid=${state.loggedUser.user.organization.guid}`;
        },
        revertWorkAsCustomer({state, commit}: { state: AuthState, commit: any }) {
            commit('setCustomerWorkAsId', null);
            sessionStorage.removeItem(`${ProjectType.diemaker}_auth`);
            location.href = `${BaseConfig.diemakerPortalUrl}/#/customer/list`;
        }
    },
    mutations: {
        updateLoggedUser(state: AuthState, loggedUser: any) {
            state.loggedUser = loggedUser;
            localStorage.setItem(localStorageUserKey, JSON.stringify(state.loggedUser));
        },
        setOrganizationUuid(state: AuthState, organizationUuid: string) {
            state.organizationUuid = organizationUuid;
            if (organizationUuid) {
                sessionStorage.setItem(localStorageOrganisationIdKey, organizationUuid);
            } else {
                sessionStorage.removeItem(localStorageOrganisationIdKey);
            }

        },
        setCustomerWorkAsId(state: AuthState, customerId: string) {
            state.customerWorkAsId = customerId;
            if (customerId) {
                sessionStorage.setItem(localStorageCustomerWorkAsIdKey, customerId);
            } else {
                sessionStorage.removeItem(localStorageCustomerWorkAsIdKey);
            }

        },
        emailConfirmed(state: AuthState) {
            if (state.loggedUser) {
                state.loggedUser.user.emailConfirmed = true;
                localStorage.setItem(`${ProjectType.customer}_auth`, JSON.stringify(state.loggedUser));
            }
        },
        login(state: AuthState, user: any) {
            switch (BaseConfig.projectType) {
                case ProjectType.customer:
                    if (hasRole(user, 'diemaker')) {
                        localStorage.setItem(`${ProjectType.diemaker}_auth`, JSON.stringify(user));
                        window.location.href = BaseConfig.diemakerPortalUrl;
                        throw new Error('redirect');
                    }
                    break;
                case ProjectType.diemaker:
                    if (hasRole(user, 'customer')) {
                        localStorage.setItem(`${ProjectType.customer}_auth`, JSON.stringify(user));
                        window.location.href = BaseConfig.customerPortalUrl;
                        throw new Error('redirect');
                    }
                    break;
            }

            state.loggedUser = user;
            localStorage.setItem(localStorageUserKey, JSON.stringify(state.loggedUser));
        },
        logout(state: AuthState) {
            localStorage.removeItem(localStorageUserKey);
            sessionStorage.removeItem(localStorageUserKey);
            state.loggedUser = null;
        },
        logoutSilent(state: AuthState) {
            localStorage.removeItem(localStorageUserKey);
            sessionStorage.removeItem(localStorageUserKey);
            state.loggedUser = null;
        }
    },
    getters: {
        authToken: (state: AuthState) => state.loggedUser?.accessToken,
        userId: (state: AuthState) => state.loggedUser.user.guid,
        languageWithDefault: (state: AuthState) => state.loggedUser?.user?.locale || BaseConfig.defaultLanguage,
        language: (state: AuthState) => state.loggedUser?.user?.locale,
        loggedUser: (state: AuthState) => state.loggedUser,
        isAuthenticated: (state: AuthState) => !!state.loggedUser,
        isEmailConfirmed: (state: AuthState) => state.loggedUser?.user.emailConfirmed,
        organizationUuid: (state: AuthState) => state.organizationUuid,
        customerWorkAsId: (state: AuthState) => state.customerWorkAsId
    }
};
