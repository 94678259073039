import { Component, Vue } from 'vue-property-decorator';
import { NotificationTypes } from '@shared/components/notification/notification.types';

const NOTIFICATION_TIMEOUT = 3000;

@Component
export default class Notification extends Vue {

  show = false;
  text = '';
  timeout = NOTIFICATION_TIMEOUT;
  color = NotificationTypes.default;

  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'notification/showMessage') {
        this.text = state.notification.text;
        this.timeout = state.notification.timeout ?? NOTIFICATION_TIMEOUT;
        this.show = true;
      }
    });
  }
}
