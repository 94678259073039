import axios, { AxiosRequestConfig } from 'axios';
import { BaseConfig } from '@shared/base-config';
import { ServerError } from './server-error.model';
import store from '~shared/store/store';
import { RequestOptions } from '@shared/request/request-options.interface';
import { ProjectType } from '@shared/enums/project-type.enum';

export class RequestService {

    static async getFile(url: string, params: any = {}, {apiPath}: any = {}): Promise<string> {

        apiPath = apiPath ?? BaseConfig.apiWebStandardsLibrary;

        params = params || {};
        const config = {
            headers: {},
            params,
            responseType: 'arraybuffer',
        } as AxiosRequestConfig;

        this.attachHeaders(config.headers, apiPath);
        this.attachWorkAs(params);

        try {
            if (!url.startsWith('http')) {
                url = `${apiPath}${url}`;
            }
            const response = await axios.get(url, config);
            const base64 = btoa(
                new Uint8Array(response.data).reduce(
                    (data, byte) => data + String.fromCharCode(byte),
                    '',
                ),
            );
            return `data:;base64,${base64}`;

        } catch (e) {
            if (e.response?.status) {
                throw new ServerError(e.response.data.code, e.response.data.message);
            }

            throw e;
        }

    }

    static async get(url: string, params: any = {}, {
        attachAuth,
        apiPath,
        logoutOnExpiredSession,
        responseType
    }: any = {}): Promise<any> {
        if (!apiPath) {
            if (url.includes(BaseConfig.apiPortal)) {
                apiPath = BaseConfig.apiPortal;
            } else if (url.includes(BaseConfig.apiWebStandardsLibrary)) {
                apiPath = BaseConfig.apiWebStandardsLibrary;
            } else {
                apiPath = apiPath ?? BaseConfig.apiWebStandardsLibrary;
            }
        }

        attachAuth = attachAuth ?? true;
        logoutOnExpiredSession = logoutOnExpiredSession ?? true;
        params = params || {};
        const config = {
            headers: {},
            params,
            responseType
        };

        if (store.getters['auth/isAuthenticated'] && attachAuth) {
            config.headers = {Authorization: `Bearer ${store.getters['auth/authToken']}`};
        }

        this.attachHeaders(config.headers, apiPath);
        this.attachWorkAs(params);

        try {
            if (!url.startsWith('http')) {
                url = `${apiPath}${url}`;
            }
            const response = await axios.get(url, config);
            return response.data;
        } catch (e) {
            if (e.response?.status) {
                if (logoutOnExpiredSession) {
                    await this.handleError(e.response);
                }
                throw new ServerError(e.response.data.code, e.response.data.message);
            }

            throw e;
        }

    }

    static async post(url: string, params: any, options: RequestOptions = {}): Promise<any> {

        options.apiPath = options.apiPath ?? BaseConfig.apiWebStandardsLibrary;

        if (store.getters['auth/isAuthenticated']) {
            options.headers = {
                ...options.headers,
                Authorization: `Bearer ${store.getters['auth/authToken']}`
            };
        }

        this.attachWorkAs(params);

        if (options?.headers && options.headers['Content-Type'] === 'application/x-www-form-urlencoded') {
            params = this.transformToSearchParams(params);
        } else if (options.multipart) {
            options.headers = {
                ...options.headers,
                'Content-Type': 'multipart/form-data'
            };
            params = this.transformToMultipart(params);
        }

        options.headers = options.headers || {};
        this.attachHeaders(options.headers, options.apiPath);

        try {
            const response = await axios.post(`${options.apiPath}${url}`, params, options);
            return response.data;
        } catch (e) {
            if (!options.ignoreUnauthorized) {
                await this.handleError(e.response);
            }
            throw new ServerError(e.response.data.code, e.response.data.message);
        }
    }

    static async put(url: string, params: any, options: RequestOptions = {}): Promise<any> {

        options.apiPath = options.apiPath ?? BaseConfig.apiWebStandardsLibrary;

        options.headers = options.headers || {};

        if (store.getters['auth/isAuthenticated']) {
            options.headers = {
                ...options.headers,
                Authorization: `Bearer ${store.getters['auth/authToken']}`
            };
        }

        this.attachWorkAs(params);

        if (options?.headers && options.headers['Content-Type'] === 'application/x-www-form-urlencoded') {
            params = this.transformToSearchParams(params);
        } else if (options.multipart) {
            options.headers = {
                ...options.headers,
                'Content-Type': 'multipart/form-data'
            };
            params = RequestService.transformToMultipart(params);
        }

        this.attachHeaders(options.headers, options.apiPath);

        try {
            const response = await axios.put(`${options.apiPath}${url}`, params, options);
            return response.data;
        } catch (e) {
            await this.handleError(e.response);
            throw new ServerError(e.response.data.code, e.response.data.message);
        }
    }

    static async delete(url: string, options: RequestOptions = {}): Promise<any> {

        options.apiPath = options.apiPath ?? BaseConfig.apiWebStandardsLibrary;

        if (store.getters['auth/isAuthenticated']) {
            options.headers = {
                Authorization: `Bearer ${store.getters['auth/authToken']}`
            };
        }

        options.headers = options.headers || {};
        url = this.attachWorkAs(url) || url;
        this.attachHeaders(options.headers, options.apiPath);

        try {
            const response = await axios.delete(`${options.apiPath}${url}`, {
                headers: options.headers
            });
            return response.data;
        } catch (e) {
            await this.handleError(e.response);
            throw new ServerError(e.response.data.code, e.response.data.message);
        }
    }

    static async stream(url: string): Promise<any> {
        try {
            const res = await this.get(`${BaseConfig.apiPortal}${url}`);
            return res;
        } catch (error: any) {
            return this.stream(url);
        }
    }

    private static transformToSearchParams(params: any) {
        const formData = new URLSearchParams();
        for (const key in params) {
            formData.append(key, params[key]);
        }

        return formData;
    }

    private static transformToMultipart(params: any) {
        const formData = new FormData();
        for (const key in params) {
            formData.append(key, params[key]);
        }

        return formData;
    }

    private static attachHeaders(headers: any, apiPath: string) {
        headers['Cache-Control'] = 'no-cache';

        if (apiPath === BaseConfig.apiWebStandardsLibrary && BaseConfig.webLibraryToken) {
            headers['Authorization'] = `Bearer ${BaseConfig.webLibraryToken}`;
        }
    }

    private static attachWorkAs(request: any): string | void {
        if ((BaseConfig.projectType === ProjectType.diemaker || BaseConfig.projectType === ProjectType.printhouse) &&
            store.getters['auth/isAuthenticated'] &&
            store.getters['auth/organizationUuid']) {

            if (typeof request === 'string') {
                return `${request}${request.includes('?') ? '&' : '?'}organizationUuid=${store.getters['auth/organizationUuid']}`;
            } else {
                request['organizationUuid'] = store.getters['auth/organizationUuid'];
            }
        }

        if (BaseConfig.projectType === ProjectType.customer &&
            store.getters['auth/isAuthenticated'] &&
            store.getters['auth/customerWorkAsId']) {

            if (typeof request === 'string') {
                return `${request}${request.includes('?') ? '&' : '?'}customerUUID=${store.getters['auth/customerWorkAsId']}`;
            } else {
                request['customerUUID'] = store.getters['auth/customerWorkAsId'];
            }
        }
    }

    private static async handleError(errorResponse: any) {
        const {status, data} = errorResponse;
        if (status === 401) {
            await store.dispatch('auth/logout', {status});
        } else if (status === 403) {
            await store.dispatch('auth/logout', {status, code: data.code});
        }
    }
}
