


































import { Component, Vue } from 'vue-property-decorator';
import SubNavigation from '@shared/components/sub-navigation/sub-navigation.component.vue';

export const HELP_PAGE_NAME = 'Help';

@Component({
  components: {
    SubNavigation
  }
})
export default class HelpPageLayout extends Vue {

  items = null;

  created() {
    this.items = this.$route.matched[0].meta.pages;
  }

  back() {
    if (this.$route.query.backUrl) {
      const redirectTo = this.$route.query.backUrl as string;
      this.$router.push({path: redirectTo});
    } else {
      this.$router.push('/');
    }
  }
}
