import { render, staticRenderFns } from "./order-qa.component.vue?vue&type=template&id=53951da3&scoped=true&"
import script from "./order-qa.component.ts?vue&type=script&lang=ts&"
export * from "./order-qa.component.ts?vue&type=script&lang=ts&"
import style0 from "./order-qa.component.scss?vue&type=style&index=0&id=53951da3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53951da3",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol})
