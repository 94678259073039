import Component from 'vue-class-component';
import Vue from 'vue';

@Component
export default class DieOptions extends Vue {
    cuttingDie = true;

    setDirty(): void {
        this.$store.commit('productCostEstimation/setDirty', true);
    }

    get boardList() {
        return this.$store.getters['productCostEstimation/boardList'].map((b: any) => {
            return {
                id: b.dbId,
                name: `${b.description}`
            };
        });
    }

    get boardId(): number {
        return this.$store.getters['productCostEstimation/boardId'];
    }

    set boardId(boardId: number) {
        this.setDirty();
        this.$store.commit('productCostEstimation/setBoardId', boardId);
    }

    get hasMaleStrippingDie(): boolean {
        return this.$store.getters['productCostEstimation/hasMaleStrippingDie'];
    }

    set hasMaleStrippingDie(hasMaleStrippingDie: boolean) {
        this.setDirty();
        this.$store.commit('productCostEstimation/setHasMaleStrippingDie', hasMaleStrippingDie);
    }

    get hasFemaleStrippingDie(): boolean {
        return this.$store.getters['productCostEstimation/hasFemaleStrippingDie'];
    }

    set hasFemaleStrippingDie(hasFemaleStrippingDie: boolean) {
        this.setDirty();
        this.$store.commit('productCostEstimation/setHasFemaleStrippingDie', hasFemaleStrippingDie);
    }

    get hasFrontStripper(): boolean {
        return this.$store.getters['productCostEstimation/hasFrontStrippingDie'];
    }

    set hasFrontStripper(hasFrontStrippingDie: boolean) {
        this.setDirty();
        this.$store.commit('productCostEstimation/setHasFrontStrippingDie', hasFrontStrippingDie);
    }

    get isDisabledMaleStrippingDie(): boolean {
        return this.$store.getters['productCostEstimation/isDisabledMaleStrippingDie'];
    }

    get isDisabledFemaleStrippingDie(): boolean {
        return this.$store.getters['productCostEstimation/isDisabledFemaleStrippingDie'];
    }

    get isDisabledFrontStrippingDie(): boolean {
        return this.$store.getters['productCostEstimation/isDisabledFrontStrippingDie'];
    }

}
