import { Component, Vue, Watch } from 'vue-property-decorator';
import { Routes } from '../../../../routes.enum';
import { Product } from '@shared/modules/products/models/product.model';
import { ProductService } from '../../product.service';
import { DiemakerRule } from '../../models/diemaker-rule.interface';
import { DiemakerRuleMainType } from '@shared/modules/products/enums/diemaker-rule-main-type.enum';
import { Config } from '../../../../config';
import ProductPreviewDrawing from '@shared/modules/products/components/product-preview-drawing/product-preview-drawing.component.vue';
import ProductSection from '@shared/modules/products/components/product-section/product-section.component.vue';
import { ProductDrawing } from '@shared/modules/products/models/product-drawing.model';
import { BreadcrumbHistoryItem } from '@shared/components/breadcrumbs/breadcrumb-history-item.interface';
import Breadcrumbs from '@shared/components/breadcrumbs/breadcrumbs.component.vue';
import { ProductMaterialCuttingSide } from '../../models/product-material-cutting-side.enum';
import CustomInput from '@shared/components/custom-input/custom-input.component.vue';
import { Validators } from '@shared/validation/validators';
import ProductCostEstimationRuleRow from '../../components/product-cost-estimation-rule-row/product-cost-estimation-rule-row.component.vue';
import drawingUrl from '@shared/filters/drawing-url.filter';
import { ProductUrlSourceType } from '../../enums/product-url-source-type.enum';
import store from '../../../../store/store';
import { DrawingType } from '@shared/modules/products/enums/drawing-type.enum';
import { ServerError } from '@shared/request/server-error.model';
import { DiemakerService } from '../../../profile/services/diemakers.service';
import { Diemaker } from '@shared/modules/diemaker/models/diemaker.model';
import CostEstimationDieboard from '../../components/cost-estimation-dieboard/cost-estimation-dieboard.component.vue';
import ProductRules from '../../components/product-rules/product-rules.component.vue';
import DieOptions from '../../components/die-options-component/die-options.component.vue';
import { RuleType } from '@shared/modules/products/models/rule-type.enum';
import { Units } from '@shared/modules/diemaker/enums/units-enum';

@Component({
    components: {
        Breadcrumbs,
        ProductPreviewDrawing,
        ProductSection,
        CustomInput,
        ProductCostEstimationRuleRow,
        CostEstimationDieboard,
        ProductRules,
        DieOptions
    },
    beforeRouteEnter(to: any, from: any, next: (vm: any) => void) {
        if (!store.getters.getPreviewOfCurrentProduct?.id) {
            next({ name: Routes.NewRequest });
        } else {
            next((vm: ProductCostEstimation) => {
                vm.fromRoute = from;
            });
        }
    }
})
export default class ProductCostEstimation extends Vue {

    readonly Validators = Validators;

    drawingPrefix = `${Config.apiWebStandardsLibrary}api/v1/files/`;
    heightOfPreviewWindow = 0;
    fromRoute!: { name: Routes; query: any };
    breadcrumbs: BreadcrumbHistoryItem[] = [];
    drawing: ProductDrawing | null = null;
    loadingOverlayVisible = false;
    resizeParams: any = null;
    isUpdating = false;
    samples: number | null = null;
    estimatedCost: number | null = null;
    isCalculated = false;
    DiemakerRuleMainType = DiemakerRuleMainType;
    disclaimer = '';
    currency = 'EUR';

    readonly availableCuttingDieSides = [
        {
            value: ProductMaterialCuttingSide.DCS_FrontCuttingDie,
            text: this.$i18n.t('dropdowns.cuttingSides.DCS_FrontCuttingDie')
        },
        {
            value: ProductMaterialCuttingSide.DCS_BackCuttingDie,
            text: this.$i18n.t('dropdowns.cuttingSides.DCS_BackCuttingDie')
        }
    ];

    get currentProduct(): Product {
        return this.$store.getters.getPreviewOfCurrentProduct;
    }

    async created(): Promise<void> {

        if (this.isFile) {
            this.drawingPrefix = Config.apiPortal;
        }

        this.initBreadcrumbs();

        this.resizeParams = this.$store.getters.getResizeParams(this.currentProduct.id);
        this.drawing = this.currentProduct.drawings.find(d => d.evDrawingId === this.resizeParams.drawingId) as ProductDrawing;

        if (!this.$store.getters['productCostEstimation/cuttingDieSide'] && this.currentProduct.materialInfo?.cuttingSide) {
            this.$store.commit('productCostEstimation/setCuttingDieSide', this.currentProduct.materialInfo?.cuttingSide);
        }

        const savedLayoutParams = this.$store.getters.getLayoutParams(this.currentProduct.id);

        this.setInitialBottomPreview(savedLayoutParams, this.isDieboard, this.drawingPrefix);

        this.setInitialTopPreview(this.isDieboard, this.drawing);

        const customerRulesAndDieboards = await DiemakerService.getRulesAndBoards(this.$store.getters.getDiemakerContext);

        const { rules, currency, boards: { flatBoards }, disclaimer, strippingDieInfo } = this.$store.getters.diemaker as Diemaker;
        await this.$store.dispatch('productCostEstimation/setInitialStrippingDieInfoAndBoxes', strippingDieInfo);

        this.disclaimer = disclaimer;
        this.currency = currency;

        const mergeFlatboards = customerRulesAndDieboards.dieboards?.flatBoards?.length ? customerRulesAndDieboards.dieboards.flatBoards : flatBoards;

        if (mergeFlatboards) {
            if (!this.$store.getters['productCostEstimation/boardId']) {
                this.$store.commit('productCostEstimation/setBoardList', mergeFlatboards.filter((b: { enabled: boolean }) => b.enabled));
                this.$store.commit('productCostEstimation/setBoardId', mergeFlatboards.find((b: { default: boolean }) => b.default)?.dbId);
            }
        }

        const mergedRules = this.mergeRules(customerRulesAndDieboards.rules, rules);
        this.initRules(mergedRules, savedLayoutParams.layoutResponse?.stats);

        this.loadSavedCostEstimationParams();

        if (this.isFile) {
            if (!this.isOneUpFile && this.isDieboard) {
                this.calculateCost(false);
            } else if (this.isCalculated) {
                this.calculateCost();
            }
        } else {
            if (this.$store.getters['productCostEstimation/dieboard'].dieCutter && this.$store.getters['productCostEstimation/isRulesValid']) {
                this.calculateCost();
            }
        }
    }

    validate() {
        return (this.$refs.costEstimationDieboard as any).validate();
    }

    mounted() {
        this.handlePreviewWindowResize();
        window.addEventListener('resize', this.handlePreviewWindowResize);
    }

    goToOrderRequest() {
        this.$router.replace({ name: Routes.ProductOrderRequest });
    }

    handlePreviewWindowResize() {
        this.heightOfPreviewWindow = window.innerHeight - 224;
    }

    destroyed() {
        window.removeEventListener('resize', this.handlePreviewWindowResize);
    }

    backToLayout() {
        if (this.$store.getters['uploadFile/type'] === DrawingType.Layout || this.$store.getters['uploadFile/type'] === DrawingType.Dieboard) {
            this.$router.push({
                name: Routes.UploadFile,
                params: {
                    id: this.currentProduct.id
                } as any
            });
        } else {
            this.$router.push({
                name: Routes.ProductLayout,
                params: {
                    id: this.currentProduct.id
                } as any
            });
        }
    }

    async calculateCost(validate = true, saveParams = true, showErrors = true): Promise<{ stats: any } | null> {
        if (validate) {
            if (!this.validate() && !this.isDieboard) {
                if (showErrors) {
                    this.$store.dispatch('notification/showError', { text: this.$i18n.t('validation.multipleErrors') });
                }
                return null;
            }

            if (!this.$store.getters['productCostEstimation/isRulesValid']) {
                if (showErrors) {
                    this.$store.dispatch('notification/showError', { text: this.$i18n.t('products.costEstimation.rulesRequired') });
                }
                return null;
            }
        }

        this.drawingPrefix = Config.apiPortal;
        this.isUpdating = true;

        const payload: any = {
            productId: this.currentProduct.id,
            prices: {
                ...(this.$store.getters['productCostEstimation/getRulesCosts'])
            },
            percentages: {
                ...this.getCuttingDiePercentages()
            },
            hasCompensatingRules: this.$store.getters['productCostEstimation/hasCompensatingRules'],
            urlSourceType: this.$route.params.type,
        };
        if (this.discount) {
            payload.discount = this.discount;
        }
        const strippingRulesInfo = this.$store.getters['productCostEstimation/strippingRulesInfo'];
        if (strippingRulesInfo.enabled) {

            if (!this.validateProductRules()) {
                this.isUpdating = false;
                this.isCalculated = true;
                return null;
            }

            payload.strippingRulesInfo = {
                horizontal: strippingRulesInfo.horizontal,
                vertical: strippingRulesInfo.vertical,
            };
        }

        try {
            const {
                totalCost,
                layoutCalcResults,
                layoutSheetOutput,
                stats
            } = await this.$store.dispatch('productCostEstimation/costEstimation', payload);

            await this.$store.dispatch('registerCostEstimation');

            if (!this.isDieboard) {

                const newDrawingUrl = layoutCalcResults?.find((l: any) => l.sheetOutputs[0]?.id === DrawingType.OneUp || l.sheetOutputs[0]?.id === DrawingType.Layout)?.sheetOutputs[0]?.url;

                if (newDrawingUrl) {
                    this.$store.commit('productCostEstimation/setTopPreview', this.setSVGUrl(newDrawingUrl));
                }

                if (this.isFile) {
                    if (this.isLayoutFile) {
                        this.$store.commit('productCostEstimation/setBottomPreview', this.setSVGUrl(layoutSheetOutput.url));
                    } else {
                        this.$store.commit('productCostEstimation/setBottomPreview', this.setSVGUrl(layoutCalcResults.find((l: any) => l.sheetOutputs[0]?.id === DrawingType.Dieboard || l.sheetOutputs[0]?.id === '-1')?.sheetOutputs[0]?.url));
                    }
                } else {
                    this.$store.commit('productCostEstimation/setBottomPreview', this.setSVGUrl(layoutCalcResults[0].sheetOutputs[0].url));
                }
            }

            this.estimatedCost = totalCost;

            if (saveParams) {
                this.saveCostEstimationParams();
            }

            this.isUpdating = false;

            if (this.$store.getters['productCostEstimation/isRulesValid']) {
                this.isCalculated = true;
                this.$store.commit('productCostEstimation/setDirty', false);
            }

            return { stats };

        } catch (err) {
            this.onDrawingError(err);
            throw err;
        }
    }

    changeRuleType(type: DiemakerRuleMainType): void {
        if (this.supportsFlat && type === DiemakerRuleMainType.Flat) {
            this.$store.dispatch('productCostEstimation/setRuleType', type);
        } else if (this.supportsRotary && type === DiemakerRuleMainType.Rotary) {
            this.$store.dispatch('productCostEstimation/setRuleType', type);
        }
    }

    onDrawingError(error: ServerError): void {
        // ResourceIDNotFound
        if (error.code === 2015 && this.isFile) {
            this.$store.commit('uploadFile/clearData');
            this.$router.push({ name: Routes.UploadFile });
        }
    }

    setInitialTopPreview(isDieboard: boolean, drawing: ProductDrawing): void {
        this.$store.dispatch('productCostEstimation/setInitialTopPreview', {
            isDieboard,
            drawing,
            fromFile: this.isFile
        });
    }

    setDirty(): void {
        this.$store.commit('productCostEstimation/setDirty', true);
    }

    setThickness(index: number, selectedThickness: string) {
        this.$store.commit('productCostEstimation/setRuleThickness', {index, thickness: selectedThickness});
        this.setDirty();
    }

    setBrand(index: number, selectedBrand: string) {
        this.$store.commit('productCostEstimation/setRuleBrand', {index, brand: selectedBrand});
        this.setDirty();
    }

    setInitialBottomPreview(savedLayoutParams: any, isDieboard: boolean, prefix: any): void {
        this.$store.dispatch('productCostEstimation/setInitialBottomPreview', { savedLayoutParams, isDieboard, prefix });
    }

    get cuttingDieSide(): ProductMaterialCuttingSide {
        return this.$store.getters['productCostEstimation/cuttingDieSide'];
    }

    get dirty(): boolean {
        return this.$store.getters['productCostEstimation/dirty'];
    }

    get supportsFlat(): boolean {
        return this.$store.getters['diemaker'].supportsFlat;
    }

    get discount(): number | null {
        return this.$store.getters['diemaker'].discount;
    }

    get supportsRotary(): boolean {
        return this.$store.getters['diemaker'].supportsRotary;
    }

    get topPreview(): string {
        return this.$store.getters['productCostEstimation/topPreview'];
    }

    get bottomPreview(): string {
        return this.$store.getters['productCostEstimation/bottomPreview'];
    }

    get hasMaleStrippingDie(): boolean {
        return this.$store.getters['productCostEstimation/hasMaleStrippingDie'];
    }

    get hasFemaleStrippingDie(): boolean {
        return this.$store.getters['productCostEstimation/hasFemaleStrippingDie'];
    }
    
    get hasFrontStripper(): boolean {
        return this.$store.getters['productCostEstimation/hasFrontStrippingDie'];
    }

    get isFile(): boolean {
        return this.$route.params.type === ProductUrlSourceType.File;
    }

    get isDieboard() {
        return this.isFile && this.$store.getters['uploadFile/type'] === DrawingType.Dieboard;
    }

    get isOneUpFile() {
        return this.isFile && this.$store.getters['uploadFile/type'] === DrawingType.OneUp;
    }

    get isLayoutFile() {
        return this.isFile && this.$store.getters['uploadFile/type'] === DrawingType.Layout;
    }

    get ruleType(): DiemakerRuleMainType {
        return this.$store.getters['productCostEstimation/ruleType'];
    }

    get rules(): DiemakerRule {
        return this.$store.getters['productCostEstimation/rules'];
    }

    get bottomPreviewVisible(): boolean {
        return this.$store.getters['productCostEstimation/bottomPreviewVisible'];
    }

    @Watch('cuttingDieSide')
    onCuttingDieSideChange(cuttingDieSide: ProductMaterialCuttingSide): void {

        this.$store.commit('productCostEstimation/setCuttingDieSide', cuttingDieSide);

        if (this.isDieboard) {
            const frontSideUrl = this.$store.getters['uploadFile/svgUrl'];
            const backSideUrl = this.$store.getters['uploadFile/flippedSvgUrl'];
            let newSideUrl = '';
            this.cuttingDieSide === ProductMaterialCuttingSide.DCS_FrontCuttingDie ?
                newSideUrl = backSideUrl :
                newSideUrl = frontSideUrl;

            this.$store.commit('productCostEstimation/setTopPreview', newSideUrl);

        } else {
            this.$store.commit('productCostEstimation/setDirty', true);
            if (this.$store.getters['productCostEstimation/isDieboardSizeEntered'] && !this.isUpdating) {
                this.calculateCost(false);
            }
        }
    }

    get language(): string {
        return this.$store.getters['language'];
    }

    private setSVGUrl(url: string): string {
        if (!url) {
            return '';
        }

        if (!url.startsWith('http')) {
            return url && drawingUrl(url, this.drawingPrefix);
        }

        return url;
    }

    private getCuttingDiePercentages() {

        const data: any = {};
        const strippingDieInfo = this.$store.getters['productCostEstimation/strippingDieInfo'];
        if (this.hasMaleStrippingDie) {
            data.hasMaleStrippingDie = true;
            data.maleStrippingDieCostPercentage = strippingDieInfo.flat.maleStrippingDieCostPercentage;
        }

        if (this.hasFemaleStrippingDie) {
            data.hasFemaleStrippingDie = true;
            data.femaleStrippingDieCostPercentage = strippingDieInfo.flat.femaleStrippingDieCostPercentage;
        }

        if (this.hasFrontStripper) {
            data.hasFrontStripper = true;
            data.frontStripperCostPercentage = strippingDieInfo.flat.frontStripperCostPercentage;
        }
        return data;
    }

    private loadSavedCostEstimationParams() {
        const costEstimationParams = this.$store.getters.getCostEstimationParams(this.currentProduct.id);

        if (costEstimationParams && costEstimationParams?.estimatedCost) {
            /*this.$store.commit('productCostEstimation/setHasMaleStrippingDie', costEstimationParams.hasMaleStrippingDie ?? this.hasMaleStrippingDie);
            this.$store.commit('productCostEstimation/setHasFemaleStrippingDie', costEstimationParams.hasFemaleStrippingDie ?? this.hasFemaleStrippingDie);
            this.$store.commit('productCostEstimation/setHasFrontStrippingDie', costEstimationParams.hasMaleStrippingDie ?? this.hasFrontStripper);*/

            this.isCalculated = true;
            this.estimatedCost = costEstimationParams.estimatedCost ?? this.estimatedCost;

          /*  if (costEstimationParams.strippingRulesInfo) {
                const newStrippingRules: StrippingRulesInfo = {
                    enabled: true,
                    horizontal: costEstimationParams.strippingRulesInfo.horizontal,
                    vertical: costEstimationParams.strippingRulesInfo.vertical
                };
                this.$store.commit('productCostEstimation/setStrippingRulesInfo', newStrippingRules);
            }

            if (costEstimationParams.hasCompensatingRules) {
                this.$store.commit('productCostEstimation/setHasCompensatingRules', costEstimationParams.hasCompensatingRules);
            }*/

            if (!costEstimationParams.hasMaleStrippingDie) {
                return;
            }
        }
    }
    private saveCostEstimationParams() {
        const strippingRulesInfo = this.$store.getters['productCostEstimation/strippingRulesInfo'];
        this.$store.commit('saveCostEstimationParams', {
            id: this.currentProduct.id,
            drawingId: (this.drawing as ProductDrawing).evDrawingId,
            params: {
                estimatedCost: this.estimatedCost,
                currency: this.currency,
                previewLayoutUrl: this.$store.getters['productCostEstimation/bottomPreview'],
                hasCompensatingRules: this.$store.getters['productCostEstimation/hasCompensatingRules'],
                strippingRulesInfo: strippingRulesInfo.enabled ?
                    {
                        horizontal: strippingRulesInfo.horizontal,
                        vertical: strippingRulesInfo.vertical
                    } : null,
                discount: this.discount,
                ...this.getCuttingDiePercentages(),
            }
        });
    }

    private initBreadcrumbs() {

        this.breadcrumbs = [
            {
                text: 'breadcrumbs.newRequest',
                disabled: false,
                to: {
                    name: Routes.NewRequest
                }
            },
            ProductService.getRoute(this.$store.getters.getListFilters, this.$route.params.type as ProductUrlSourceType)
        ];

        if (!this.isFile) {
            this.breadcrumbs.push({
                plainText: this.currentProduct.name,
                disabled: false,
                to: {
                    name: Routes.ProductPreview,
                    params: {
                        id: this.currentProduct.id
                    }
                }
            });
        }

        if (this.$store.getters['uploadFile/type'] !== DrawingType.Dieboard && this.$store.getters['uploadFile/type'] !== DrawingType.Layout) {
            this.breadcrumbs.push({
                text: 'products.breadcrumbs.layout',
                disabled: false,
                to: {
                    name: Routes.ProductLayout,
                    params: {
                        id: this.currentProduct.id
                    }
                }
            });
        }

        this.breadcrumbs.push({
            text: 'products.breadcrumbs.costEstimation',
            disabled: true
        });
    }

    private validateProductRules(): boolean {
        return (this.$refs.productRules as any).validateStrippingRulesInfoForm();
    }

    private mergeRules(customerRules: {flat: Array<{type: RuleType}>, rotary: Array<{type: RuleType}>},
                       rules: { flat: Array<{type: RuleType}>, rotary: Array<{type: RuleType}> }): {flat: any[]; rotary: any[]} {
        let flat: Array<{type: RuleType}> = [];

        if (customerRules?.flat?.length) {
            flat = [...customerRules?.flat];
        }

        rules = rules || {};

        if (rules.flat && Array.isArray(rules.flat)) {
            for (const rule of rules.flat) {
                if (!flat.find((e: any) => e.type === rule.type)) {
                    flat.push(rule);
                }
            }
        }

        let rotary: Array<{type: RuleType}> = [];

        if (customerRules?.rotary?.length) {
            rotary = [...customerRules?.rotary];
        }

        if (rules.rotary && Array.isArray(rules.rotary)) {
            for (const rule of rules.rotary) {
                if (!rotary.find((e: any) => e.type === rule.type)) {
                    rotary.push(rule);
                }
            }
        }

        return {
            flat,
            rotary
        };
    }

    private initRules(mergedRules: any, stats: any) {
        if (this.isFile && !this.isOneUpFile) {
            const _stats = this.$store.getters['uploadFile/layoutStatistics'] ?? {};
            this.$store.dispatch('productCostEstimation/loadRules', {rules: mergedRules, layoutStats: _stats});
        } else {
            this.$store.dispatch('productCostEstimation/loadRules', {rules: mergedRules, layoutStats: stats});
        }
    }
}
