import { Component, Vue } from 'vue-property-decorator';
import ProductSection from '@shared/modules/products/components/product-section/product-section.component.vue';
import { ProductLayoutMargins } from '../../../../models/product-layout-margins.interface';
import { Validators } from '@shared/validation/validators';
import { Units } from '@shared/modules/diemaker/enums/units-enum';
import { ProductUrlSourceType } from '../../../../enums/product-url-source-type.enum';
import { MathService } from '@shared/utils/math.service';

@Component({
    components: {
        ProductSection
    }
})

export default class MarginsSection extends Vue {

    readonly Validators = Validators;

    setDirty() {
        this.$store.commit('productLayout/setDirty', true);
    }

    setMarginsLeft(marginsLeft: number): void {
        this.$store.commit('productLayout/setMarginsLeft', marginsLeft);
        this.setDirty();
    }

    setMarginsRight(marginsRight: number): void {
        this.$store.commit('productLayout/setMarginsRight', marginsRight);
        this.setDirty();
    }

    setMarginsTop(marginsTop: number): void {
        this.$store.commit('productLayout/setMarginsTop', marginsTop);
        this.setDirty();
    }

    setMarginsBottom(marginsBottom: number): void {
        this.$store.commit('productLayout/setMarginsBottom', marginsBottom);
        this.setDirty();
    }

    get margins(): ProductLayoutMargins {
        return this.$store.getters['productLayout/margins'];
    }

    get isFile(): boolean {
        return this.$route.params.type === ProductUrlSourceType.File;
    }

    get selectedUnit(): Units {
        return !this.isFile ? this.$store.getters['templateUnit'] : this.$store.getters['uploadFile/unit'];
    }

    formatField($event: any, setter: (value: any) => void) {
        if (this.selectedUnit === Units.INCH) {
            try {
                $event.target.value = MathService.calculate($event.target.value);
                setter($event.target.value);
            } catch (ex) {}
        }
    }

    get validators() {
        if (this.selectedUnit === Units.MM) {
            return [Validators.MaxLength(5)];
        } else {
            return [Validators.InchParam];
        }
    }
}
