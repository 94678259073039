import { Component, Prop, Vue } from 'vue-property-decorator';
import ProductSection from '@shared/modules/products/components/product-section/product-section.component.vue';
import { Validators } from '@shared/validation/validators';
import { Units } from '@shared/modules/diemaker/enums/units-enum';
import { ProductUrlSourceType } from '../../../../enums/product-url-source-type.enum';
import { UtilService } from '@shared/utils/util.service';
import { MathService } from '@shared/utils/math.service';

@Component({
    components: {
        ProductSection
    }
})

export default class SheetSection extends Vue {
    @Prop() sheetType!: string;
    readonly input = this.selectedUnit === Units.INCH ? 'decimal' : 'numeric';
    readonly Validators = Validators;

    onSelectedCatalogChange(id: any) {
        if (id) {
            const catalog = this.$store.getters['productLayout/catalog'];
            const {
                bottomMargin, leftMargin, rightMargin,
                topMargin, maxHeight, maxWidth
            } = catalog.find((c: any) => c.sheetId === id.toString()) || {};

            this.$store.commit('productLayout/setWidth', UtilService.formatNumber(maxWidth, this.selectedUnit));
            this.$store.commit('productLayout/setHeight', UtilService.formatNumber(maxHeight, this.selectedUnit));

            const margins = {
                left: UtilService.formatNumber(leftMargin, this.selectedUnit),
                top: UtilService.formatNumber(topMargin, this.selectedUnit),
                bottom: UtilService.formatNumber(bottomMargin, this.selectedUnit),
                right: UtilService.formatNumber(rightMargin, this.selectedUnit)
            };

            this.$store.commit('productLayout/setMargins', margins);
            this.setDirty();
        }
    }

    setCols(cols: number): void {
        this.$store.commit('productLayout/setCols', cols);
        this.setDirty();
    }

    setRows(rows: number): void {
        this.$store.commit('productLayout/setRows', rows);
        this.setDirty();
    }

    get isFile(): boolean {
        return this.$route.params.type === ProductUrlSourceType.File;
    }

    get selectedUnit(): Units {
        return !this.isFile ? this.$store.getters['templateUnit'] : this.$store.getters['uploadFile/unit'];
    }

    setDirty() {
        this.$store.commit('productLayout/setDirty', true);
    }

    setWidth(width: number): void {
        this.$store.commit('productLayout/setWidth', width);
        this.setDirty();
    }

    setHeight(height: number): void {
        this.$store.commit('productLayout/setHeight', height);
        this.setDirty();
    }

    get selectedCatalog(): any {
        return this.$store.getters['productLayout/selectedCatalog'];
    }

    get catalog(): any[] {
        return this.$store.getters['productLayout/catalog'];
    }

    get isSheetSizeSelected(): boolean {
        return this.sheetType === 'sheetSize';
    }

    get rows(): number {
        return this.$store.getters['productLayout/rows'];
    }

    get cols(): number {
        return this.$store.getters['productLayout/cols'];
    }

    get width(): number {
        return this.$store.getters['productLayout/width'];
    }

    get height(): number {
        return this.$store.getters['productLayout/height'];
    }

    formatField($event: any, setter: (value: any) => void) {
        if (this.selectedUnit === Units.INCH) {
            try {
                $event.target.value = MathService.calculate($event.target.value);
                setter($event.target.value);
            } catch (ex) {}
        }
    }

    get validators() {
        if (this.selectedUnit === Units.MM) {
            return [Validators.MaxLength(5)];
        } else {
            return [Validators.InchParam];
        }
    }

    private changeSheetType(sheetType: 'sheetSize' | 'rowsCols'): void {
        this.$emit('changeSheetType', sheetType);
    }
}
