import { Component, Prop, Vue } from 'vue-property-decorator';
import { Units } from '@shared/modules/diemaker/enums/units-enum';

@Component
export default class UnitsSelect extends Vue {
    @Prop() value!: Units;

    units = [
        {value: Units.MM, text: Units.MM},
        {value: Units.INCH, text: Units.INCH}
    ];

    updateUnit(unit: Units) {
        this.$emit('input', unit);
    }
}
