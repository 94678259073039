function url(val: string) {
    if (val) {
        if (val.startsWith('http')) {
            return val;
        } else {
            return `//${val}`;
        }
    }
    return '';
}

export default url;
