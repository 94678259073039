








import { Component, Vue, Watch } from 'vue-property-decorator';
import SubNavigation from '@shared/components/sub-navigation/sub-navigation.component.vue';

@Component({
  components: {
    SubNavigation
  }
})
export default class HelpPage extends Vue {
  html = '';

  created() {
    this.loadHelp();
  }

  @Watch('$route')
  routeChange() {
    this.loadHelp();
  }

  private loadHelp() {
    if (this.$route?.meta?.template) {
      const data = require('raw-loader!@shared/assets/help/en/' + (this.$route.meta.template) + '.html');
      this.html = data.default;
    }
  }
}
