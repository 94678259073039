import { Component, Prop } from 'vue-property-decorator';
import { UtilService } from '../../utils/util.service';
import BaseValidationInput from '../../validation/base-validation-input.component';

@Component({
    inheritAttrs: false
})
export default class CustomInput extends BaseValidationInput {
    @Prop({default: 'text'}) type!: string;
    @Prop({default: ''}) label!: string;
    @Prop({default: undefined}) placeholder!: string;
    @Prop({default: true}) labelOutside!: boolean;
    @Prop({default: 'auto'}) width!: string;
    @Prop({default: false}) disabled!: boolean;
    @Prop({default: 'text '}) inputmode!: string;
    @Prop({
        default: () => {
            return `input-${UtilService.getUniqueNum()}`;
        }
    }) name!: string;

    @Prop({default: 'col-4'}) labelClass!: string;
    @Prop({default: 'col-8'}) inputClass!: string;

    handleChange(value: string) {
        this.$emit('input', value);
    }
}
