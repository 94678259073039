import { Component, Vue } from 'vue-property-decorator';
import { Routes } from '../../routes.enum';

@Component
export default class NewRequestLanding extends Vue {
    leftSideClick() {
        this.$router.push({name: Routes.ProductList});
    }

    rightSideClick() {
        this.$router.push({name: Routes.UploadFile});
    }

    topSideClick() {
        this.$router.push({name: Routes.ProductFavouriteList});
    }
}
