import { BaseConfig } from '@shared/base-config';

export class ProductListItem {

    id: number;
    libraryCode: string;
    name: string;
    previewMimeType: string;
    previewURL: string;
    preview3DUrl: string | null = null;

    constructor({dbId, libraryCode, name, previewMimeType, preview3DUrl, previewURL}: any = {}) {
        this.id = dbId;
        this.libraryCode = libraryCode;
        this.name = name;
        this.previewMimeType = previewMimeType;
        this.previewURL = `${BaseConfig.apiWebStandardsLibrary}api/v1/files/${encodeURI(previewURL)}?t=${Date.now()}`;
        if (preview3DUrl) {
            this.preview3DUrl = `${BaseConfig.apiWebStandardsLibrary}api/v1/files/${encodeURI(preview3DUrl)}?t=${Date.now()}`;
        }
    }
}
