import { Component, Vue, Prop } from 'vue-property-decorator';

import CustomInput from '@shared/components/custom-input/custom-input.component.vue';
import { Validators } from '@shared/validation/validators';
import { UserService } from '@shared/modules/users/services/user.service';
import LoadingButton from '@shared/components/loading-button/loading-button.component.vue';
import { ServerError } from '@shared/request/server-error.model';
import FormErrorMessage from '@shared/components/form-error-message/form-error-message.component.vue';

@Component({
    components: {
        CustomInput,
        LoadingButton,
        FormErrorMessage
    }
})
export default class ChangePasswordModal extends Vue {
    currentPassword = '';
    newPassword = '';
    confirmPassword = '';
    errorMessage: string | null = null;

    passwordValidators = [
        Validators.MinLength(6)
    ];

    cancelClicked() {
        this.$emit('close');
    }

    async changePassword(stopLoading: () => void) {

        this.errorMessage = null;

        if (this.validate()) {
            try {
                await UserService.changePassword(this.currentPassword, this.newPassword);
                this.$store.dispatch('notification/showSuccess', {
                    text: this.$i18n.t('changePassword.messages.successfullyChanged')
                });
                this.$emit('close');
            } catch (e: any | ServerError) {
                this.errorMessage = `errors.${e.code === 9 ? 'invalidPassword' : e.code}`;
            }
        }

        stopLoading();
    }

    get repeatPasswordRule() {
        return [Validators.PasswordMatch(this.newPassword)];
    }
    
    private validate(): boolean {
        return (this.$refs.form as any).validate();
    }
}
