import { Component, Vue } from 'vue-property-decorator';
import ProductPreviewDrawing from '@shared/modules/products/components/product-preview-drawing/product-preview-drawing.component.vue';
import { Routes } from '../../../../routes.enum';
import { Product } from '@shared/modules/products/models/product.model';
import ProductSection from '@shared/modules/products/components/product-section/product-section.component.vue';
import { Config } from '../../../../config';
import { ProductDrawing } from '@shared/modules/products/models/product-drawing.model';
import { DiemakerRuleMainType } from '@shared/modules/products/enums/diemaker-rule-main-type.enum';
import { BreadcrumbHistoryItem } from '@shared/components/breadcrumbs/breadcrumb-history-item.interface';
import Breadcrumbs from '@shared/components/breadcrumbs/breadcrumbs.component.vue';
import { ProductService } from '../../product.service';
import { ProductUrlSourceType } from '../../enums/product-url-source-type.enum';
import store from '../../../../store/store';
import { ServerError } from '@shared/request/server-error.model';
import CustomerInquiry from '../../components/customer-inquiry/customer-inquiry.component.vue';
import RequestDetails from '../../components/request-details/request-details.component.vue';
import DieboardTable from '../../components/dieboard-table/dieboard-table.component.vue';
import MaterialsTable from '../../components/materials-table/materials-table.component.vue';
import ProductRulesSummary from '../../components/product-rules-summary/product-rules-summary.component.vue';
import DieOptionsSummary from '../../components/die-options-summary/die-options-summary.component.vue';
import { DrawingType } from '@shared/modules/products/enums/drawing-type.enum';

@Component({
    components: {
        Breadcrumbs,
        ProductPreviewDrawing,
        ProductSection,
        CustomerInquiry,
        RequestDetails,
        DieboardTable,
        MaterialsTable,
        ProductRulesSummary,
        DieOptionsSummary
    },
    beforeRouteEnter(to: any, from: any, next: any) {
        if (!store.getters.getPreviewOfCurrentProduct?.id) {
            next({name: Routes.NewRequest});
        } else {
            next();
        }
    }
})
export default class ProductOrderRequest extends Vue {

    readonly DiemakerRuleMainType = DiemakerRuleMainType;
    readonly drawingPrefix = `${Config.apiWebStandardsLibrary}api/v1/files/`;

    heightOfPreviewWindow = 0;
    breadcrumbs: BreadcrumbHistoryItem[] = [];
    drawing!: ProductDrawing;
    previewLayoutUrl: string | null = null;
    dieCutter = '';
    width: number | null = null;
    height: number | null = null;
    cuttingDie = true;
    ruleType: DiemakerRuleMainType | null = null;
    loadingOverlayVisible = false;
    currency = 'EUR';

    get currentProduct(): Product {
        return this.$store.getters.getPreviewOfCurrentProduct;
    }

    async created(): Promise<void> {

        this.initBreadcrumbs();

        const resizeParams = this.$store.getters.getResizeParams(this.currentProduct.id);
        this.drawing = this.currentProduct.drawings.find(d => d.evDrawingId === resizeParams.drawingId) as ProductDrawing;

        this.initData();
    }

    mounted(): void {
        this.handlePreviewWindowResize();
        window.addEventListener('resize', this.handlePreviewWindowResize);
    }

    handlePreviewWindowResize(): void {
        this.heightOfPreviewWindow = window.innerHeight - 224;
    }

    destroyed(): void {
        window.removeEventListener('resize', this.handlePreviewWindowResize);
    }

    backToCostEstimation(): void {
        this.$router.push({
            name: Routes.ProductCostEstimation,
            params: {
                id: this.currentProduct.id
            } as any
        });
    }

    onDrawingError(error: ServerError): void {
        // ResourceIDNotFound
        if (error.code === 2015 && this.isFile) {
            this.$store.commit('uploadFile/clearData');
            this.$router.push({name: Routes.UploadFile});
        }
    }

    async submit(): Promise<void | boolean> {

        if (!this.validate()) {
            this.$store.dispatch('notification/showError', {text: this.$i18n.t('validation.multipleErrors')});
            return false;
        }

        this.loadingOverlayVisible = true;

        const data = await this.$store.dispatch('productMakeOrder/makeOrder', {
            urlSourceType: this.$route.params.type,
            productId: this.currentProduct.id,
            orderData: {
                notes: this.$store.getters['productMakeOrder/notes'],
                deliveryDate: this.$store.getters['productMakeOrder/deliveryDate'],
                layoutDrawingName: this.$store.getters['productMakeOrder/requestTitle'],
                diemakerUuid: this.$store.getters.getDiemakerContext,
                rules: this.$store.getters['productCostEstimation/rules']
            }
        });

        this.$store.dispatch('notification/showSuccess', {text: this.$i18n.t('products.orderRequest.messages.orderSubmitted')});
        this.clearSavedData();
        this.$router.push({name: Routes.OrderList});

        this.loadingOverlayVisible = false;
    }

    get topPreview(): string {
        return this.$store.getters['productCostEstimation/topPreview'];
    }

    get bottomPreview(): string {
        return this.$store.getters['productCostEstimation/bottomPreview'];
    }

    get isFile(): boolean {
        return this.$route.params.type === ProductUrlSourceType.File;
    }

    get rules(): Array<{ selectedThickness: string; selectedBrand: string; type: string }> {
        return this.$store.getters['productCostEstimation/rules'];
    }

    get isDieboard() {
        return this.isFile && this.$store.getters['uploadFile/type'] === DrawingType.Dieboard;
    }

    private clearSavedData() {
        this.$store.dispatch('endCADSession', !this.isFile ? this.$store.getters['templateUnit'] : this.$store.getters['uploadFile/unit']);
        this.$store.commit('clearResizeParams');
        this.$store.commit('clearLayoutParams');
        this.$store.commit('clearCostEstimationParams');
        this.$store.commit('productCostEstimation/reset');
        this.$store.commit('productLayout/reset');
        this.$store.commit('productMakeOrder/reset');
        this.$store.commit('clearOrderRequestParams');
        this.$store.commit('uploadFile/clearData');
        this.$store.commit('changePreviewOfCurrentProduct', {});
    }

    private validate() {
        return (this.$refs.request as any).validate();
    }

    private initData() {
        const costEstimationParams = this.$store.getters.getCostEstimationParams(this.currentProduct.id);

        if (costEstimationParams) {
            this.$store.commit('productMakeOrder/setEstimatedCost', costEstimationParams.estimatedCost);
            this.ruleType = this.$store.getters['productCostEstimation/ruleType'];
            // this.ruleType = costEstimationParams.ruleType;
            this.previewLayoutUrl = costEstimationParams.previewLayoutUrl;
            this.currency = costEstimationParams.currency;
            // this.$store.commit('productMakeOrder/setRules', costEstimationParams.rules.filter(({selectedBrand}: any) => !!selectedBrand));
        }

        const orderRequestParams = this.$store.getters.getOrderRequestParams(this.currentProduct.id);

        if (orderRequestParams) {
            this.$store.commit('productMakeOrder/setNotes', orderRequestParams.notes);
            this.$store.commit('productMakeOrder/setDeliveryDate', orderRequestParams.deliveryDate);
            this.$store.commit('productMakeOrder/setRequestTitle', orderRequestParams.requestTitle);
        }
    }

    private initBreadcrumbs() {

        this.breadcrumbs = [
            {
                text: 'breadcrumbs.newRequest',
                disabled: false,
                to: {
                    name: Routes.NewRequest
                }
            },
            ProductService.getRoute(this.$store.getters.getListFilters, this.$route.params.type as ProductUrlSourceType)
        ];

        if (!this.isFile) {
            this.breadcrumbs.push({
                plainText: this.currentProduct.name,
                disabled: false,
                to: {
                    name: Routes.ProductPreview,
                    params: {
                        id: this.currentProduct.id
                    }
                }
            });
        }

        if (!this.isFile && !this.isDieboard) {
            this.breadcrumbs.push({
                text: 'products.breadcrumbs.layout',
                disabled: false,
                to: {
                    name: Routes.ProductLayout,
                    params: {
                        id: this.currentProduct.id
                    }
                }
            });
        }

        this.breadcrumbs.push( {
            text: 'products.breadcrumbs.costEstimation',
            disabled: false,
            to: {
                name: Routes.ProductCostEstimation,
                params: {
                    id: this.currentProduct.id
                }
            }
        });

        this.breadcrumbs.push({
            text: 'products.breadcrumbs.requestDetails',
            disabled: true
        });
    }
}
