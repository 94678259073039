import { Component, Vue } from 'vue-property-decorator';
import ProductSection from '@shared/modules/products/components/product-section/product-section.component.vue';

@Component({
    components: {
        ProductSection
    }
})

export default class LayoutOptions extends Vue {

    setRotate(rotate: boolean): void {
        this.$store.commit('productLayout/setRotate', rotate);
        this.setDirty();
    }

    setDirty() {
        this.$store.commit('productLayout/setDirty', true);
    }

    get rotate(): boolean {
        return this.$store.getters['productLayout/rotate'];
    }
}
