import Vue from 'vue';
import Vuex from 'vuex';
import { Product } from '@shared/modules/products/models/product.model';
import { ProductService } from '../modules/products/product.service';
import { RequestService } from '@shared/request/request.service';
import { FluteDirection } from '@shared/modules/products/enums/flute-direction.enum';
import { CompanyService } from '../modules/profile/services/company.service';
import { Company } from '../modules/profile/models/company.model';
import { PersonService } from '../modules/profile/services/person.service';
import { Person } from '../modules/profile/models/person.model';
import notificationModule from '@shared/components/notification/notification.store';
import { confirmModalModule, confirmModuleNamespace } from '@shared/components/confirm-modal/confirm-modal.store';
import authModule from '@shared/store/auth.store';
import { Config } from '../config';
import { DiemakerService } from '../modules/profile/services/diemakers.service';
import { Diemaker } from '@shared/modules/diemaker/models/diemaker.model';
import { BaseConfig } from '@shared/base-config';
import { UploadFileStore } from './modules/upload-file.store';
import { ProductCostEstimationStore } from './modules/product-cost-estimation.store';
import { ProductMakeOrder } from './modules/product-make-order.store';
import { ProductLayoutStore } from './modules/product-layout.store';
import { Units } from '@shared/modules/diemaker/enums/units-enum';
import { copyLayoutPatterns, copyMeasLines, copyParamsTexts } from '@shared/modules/products/utils/product.utils';

Vue.use(Vuex);

const personService = new PersonService();
const localStorageUserLangKey = `${BaseConfig.projectType}_lang`;
const previewDrawingFontSizeKey = `preview_drawing_font_size`;

export default new Vuex.Store({
    modules: {
        notification: notificationModule,
        [confirmModuleNamespace]: confirmModalModule,
        auth: authModule,
        uploadFile: UploadFileStore,
        productCostEstimation: ProductCostEstimationStore,
        productMakeOrder: ProductMakeOrder,
        productLayout: ProductLayoutStore,
    },
    state: () => {
        const language = localStorage.getItem(localStorageUserLangKey);
        const previewDrawingFontSize = localStorage.getItem(previewDrawingFontSizeKey);
        return {
            previewOfCurrentProduct: {},
            chosenDrawing: '',
            groupsArray: [],
            drawingVisibleParams: [],
            isResizePage: false,
            selectedFluteDirection: null,
            resizeParams: {},
            layoutParams: {},
            costEstimationParams: {},
            orderRequestParams: {},
            originalProduct: null,
            sessionOpened: false,
            listFilters: null,
            registerCostEstimationCalled: false,
            diemakerContext: null,
            userDefaultDiemaker: null,
            diemaker: null,
            language,
            previewDrawingFontSize,
            productLoading: true,
            is3dSelected: false
        };
    },
    actions: {
        async getDiemaker({ state, commit }: any, id: string): Promise<Diemaker> {
            const diemaker = await DiemakerService.getDiemaker(id);
            commit('setDiemaker', diemaker);
            return state.diemaker;
        },
        async loadProduct({ commit, state }: any, { productId }: { productId: number }) {
            if (state.previewOfCurrentProduct?.id !== productId) {
                commit('setProductLoading', true);
                const product = await RequestService.get(`api/v1/templates/${productId}`);
                state.originalProduct = JSON.stringify(product) as any;
                commit('changePreviewOfCurrentProduct', new Product(product));
                commit('setProductLoading', false);
            }
        },
        async setUserCurrentDiemaker({ commit, state, dispatch }: any, diemakerId: string) {
            const diemaker = await DiemakerService.setAsCurrent(diemakerId);
            commit('setUserDefaultDiemaker', diemakerId);
            commit('setDiemakerContext', diemakerId);

            if (diemakerId !== state.diemaker?.uuid) {
                await dispatch('getDiemaker', diemakerId);
            }
            commit('setDiemakerDiscount', diemaker.discount);
        },
        async loadDefaultDiemaker({ commit, state, getters, dispatch }: any, options?: {reload: boolean}) {
            if (!state.userDefaultDiemaker || options?.reload) {
                const diemakers = await DiemakerService.getDiemakers();
                const userDefaultDiemaker = diemakers.filter(({ isCurrent }: any) => isCurrent)[0];

                if (getters.getDiemakerContext && userDefaultDiemaker?.uuid !== getters.getDiemakerContext) {
                    await dispatch('setUserCurrentDiemaker', getters.getDiemakerContext);
                } else if (userDefaultDiemaker?.uuid) {
                    commit('setUserDefaultDiemaker', userDefaultDiemaker?.uuid);
                    commit('setDiemakerContext', userDefaultDiemaker?.uuid);
                    if (userDefaultDiemaker?.uuid !== state.diemaker?.uuid) {
                        await dispatch('getDiemaker', userDefaultDiemaker?.uuid);
                    }
                    commit('setDiemakerDiscount', userDefaultDiemaker?.discount);
                }
            }
        },
        async restoreCurrentProduct({ commit, state }) {
            commit('changePreviewOfCurrentProduct', new Product(JSON.parse(state.originalProduct as any)));
        },
        async resize({ commit, state }: any, { productId, grammageNote, paramMap, material, selectedFluteDirection }) {
            const data = await ProductService.resize(productId, paramMap,
                material, selectedFluteDirection as FluteDirection);

            commit('saveResizeParams', {
                id: productId,
                drawingId: state.chosenDrawing?.evDrawingId,
                params: {
                    paramMap,
                    material,
                    fluteDir: selectedFluteDirection,
                    grammageNote,
                }
            });

            copyLayoutPatterns(state.previewOfCurrentProduct, data);
            copyMeasLines(state.previewOfCurrentProduct, data);
            copyParamsTexts(state.previewOfCurrentProduct, data);
            data.possibleMaterials = state.previewOfCurrentProduct.possibleMaterials;
            data.materialInfo = state.previewOfCurrentProduct.materialInfo;
            commit('changePreviewOfCurrentProduct', data);
        },

        async sendEmail({ state }: any, { productId, mailRequest, resizeParams }): Promise<any> {
            delete resizeParams.initial;
            return await ProductService.sendEmail(productId, mailRequest, resizeParams);
        },

        startCADSession({ state, commit }): Promise<any> {
            if (!state.sessionOpened) {
                commit('setSessionOpened', true);
                commit('setRegisterCostEstimationCalled', false);
                return ProductService.startCADSession();
            }

            return Promise.resolve();
        },
        endCADSession({ state, commit, getters }, unit: Units): Promise<any> {
            if (state.sessionOpened) {
                commit('setSessionOpened', false);
                commit('setRegisterCostEstimationCalled', false);
                return ProductService.endCADSession(unit);
            }

            return Promise.resolve();
        },
        async updateCompanyDetails({ getters }, company: Company): Promise<any> {
            return CompanyService.update(company);
        },
        async updatePersonDetails({ state, getters, commit }: any, person: Person): Promise<any> {

            const loggedUser = getters['auth/loggedUser'];
            const resp = await personService.update(person);

            loggedUser.user.name = person.name;
            loggedUser.user.email = person.email;
            loggedUser.user.phone = person.phone;
            loggedUser.user.locale = person.locale;

            commit('auth/updateLoggedUser', loggedUser);
            commit('setLanguage', person.locale);

            return resp;
        },
        async register({ state, commit, getters }: any, userData: any): Promise<any> {

            const data = {
                customer: JSON.stringify({
                    companyName: userData.companyName
                }),
                user: JSON.stringify({
                    name: userData.contactPerson,
                    username: userData.email,
                    email: userData.email,
                    locale: getters['language']
                }),
                password: userData.password,
                currentDiemakerUUID: state.diemakerContext
            };

            const response = await RequestService.post(`api/v1/customer/register`, data, {
                multipart: true,
                apiPath: Config.apiPortal,
                ignoreUnauthorized: true
            });
            commit('auth/login', response);
        },
        async registerCostEstimation({ state, commit, getters }: any): Promise<any> {
            if (!state.registerCostEstimationCalled) {
                await ProductService.registerCostEstimation(getters.getDiemakerContext);
                commit('setRegisterCostEstimationCalled', true);
            }
        },
    },
    mutations: {
        setUserDefaultDiemaker(state: any, id: string) {
            state.userDefaultDiemaker = id;
        },
        setDiemakerContext(state: any, id: string) {
            state.diemakerContext = id;
            localStorage.setItem('defaultDiemakerId', id);
        },
        changeListFilters(state: any, filters) {
            state.listFilters = filters;
        },
        saveResizeParamsDrawingId(state: any, { id, drawingId }) {
            state.resizeParams[id] = { ...state.resizeParams[id], drawingId };
        },
        saveResizeParams(state: any, { id, drawingId, params }) {
            state.resizeParams[id] = { drawingId, ...params };
        },
        saveLayoutParams(state: any, { id, drawingId, params }) {
            state.layoutParams[id] = { drawingId, ...params };
        },
        saveOrderRequestParams(state: any, { id, drawingId, params }) {
            state.orderRequestParams[id] = { drawingId, ...params };
        },
        saveCostEstimationParams(state: any, { id, drawingId, params }) {
            state.costEstimationParams[id] = { drawingId, ...params };
        },
        setRegisterCostEstimationCalled(state: any, registerCostEstimationCalled) {
            state.registerCostEstimationCalled = registerCostEstimationCalled;
        },
        setSessionOpened(state: any, opened) {
            state.sessionOpened = opened;
        },
        clearResizeParams(state) {
            state.resizeParams = {};
        },
        clearLayoutParams(state) {
            state.layoutParams = {};
        },
        clearOrderRequestParams(state) {
            state.orderRequestParams = {};
        },
        clearCostEstimationParams(state) {
            state.costEstimationParams = {};
        },
        changeSelectedFluteDirection(state, dir) {
            state.selectedFluteDirection = dir;
        },
        changeIsResizePage(state, isResizePage) {
            state.isResizePage = isResizePage;
        },
        changeDrawingVisibleParams(state: any, params: string[]) {
            state.drawingVisibleParams = params;
        },
        changePreviewOfCurrentProduct(state: any, previewOfCurrentProduct: Product) {

            if (state.previewOfCurrentProduct.params && previewOfCurrentProduct.params) {
                for (const obj of Object.entries(previewOfCurrentProduct.params.paramMap)) {

                    const [key, value] = obj as any;

                    if (state.previewOfCurrentProduct.params.paramMap[key] != null &&
                        value.description == null) {
                        value.description = state.previewOfCurrentProduct.params.paramMap[key].description;
                    }
                }
                if (previewOfCurrentProduct.colladaAnimations == null) {
                    previewOfCurrentProduct.colladaAnimations = state.previewOfCurrentProduct.colladaAnimations;
                }
            }

            state.previewOfCurrentProduct = previewOfCurrentProduct;
        },
        changeChosenDrawing(state, chosenDrawing) {
            state.chosenDrawing = chosenDrawing;
        },
        changeGroupsArray(state, groupsArray) {

            for (const item of groupsArray.groups) {
                if (item.helpUrl) {
                    item.helpUrl = item.helpUrl.replace('#', encodeURIComponent('#'));
                }

                item.tooltipVisible = false;
            }

            state.groupsArray = groupsArray;
        },
        setDiemaker(state: any, diemaker: Diemaker) {
            state.diemaker = diemaker;
        },
        setDiemakerDiscount(state: any, discount: number | null) {
            state.diemaker.discount = discount;
        },
        setLanguage(state: any, lang: string) {
            state.language = lang;
            localStorage.setItem(localStorageUserLangKey, lang);
        },
        setPreviewDrawingFontSize(state: any, size: string) {
            state.previewDrawingFontSize = size;
            localStorage.setItem(previewDrawingFontSizeKey, size);
        },
        setProductLoading(state: any, loading: boolean) {
            state.productLoading = loading;
        },
        set3dSelected(state: any, is3dSelected: number) {
            state.is3dSelected = is3dSelected;
        },
    },
    getters: {
        getListFilters: state => state.listFilters,
        getDrawingVisibleParams: state => state.drawingVisibleParams,
        hasLayoutParams: state => () => !!Object.keys(state.layoutParams).length,
        hasCostEstimationParams: state => () => !!Object.keys(state.costEstimationParams).length,
        hasResizeParams: (state: any) => {
            return () => {
                let hasParams = false;

                for (const key in state.resizeParams) {
                    if (state.resizeParams[key].paramMap && !state.resizeParams[key].initial) {
                        hasParams = true;
                        break;
                    }
                }

                return hasParams;
            };
        },
        getOrderRequestParams: (state: any) => (id: number) => state.orderRequestParams[id + ''],
        getResizeParams: (state: any) => (id: number) => state.resizeParams[id + ''],
        getLayoutParams: (state: any) => (id: number) => state.layoutParams[id + ''],
        getCostEstimationParams: (state: any) => (id: number) => state.costEstimationParams[id + ''],
        getSelectedFluteDirection: state => state.selectedFluteDirection,
        getPreviewOfCurrentProduct: state => state.previewOfCurrentProduct,
        templateUnit: state => (state.previewOfCurrentProduct as Product)?.measurementUnit,
        getChosenDrawing: state => state.chosenDrawing,
        getGroupsArray: state => state.groupsArray,
        getIsResizePage: state => state.isResizePage,
        getUserDefaultDiemaker: state => state.userDefaultDiemaker,
        getDiemakerContext: (state: any) => {
            if (localStorage.getItem('defaultDiemakerId')) {
                state.diemakerContext = localStorage.getItem('defaultDiemakerId') as string;
            }
            return state.diemakerContext;
        },
        diemaker: (state: any): Diemaker => {
            return state.diemaker as Diemaker;
        },
        diemakerUnits: (state: any): Units => {
            return (state.diemaker as Diemaker)?.units;
        },
        language: (state: any, getters: any) => {
            if (getters['auth/isAuthenticated']) {
                return state.language ?? getters['auth/language'] ?? state.diemaker?.primaryUser?.locale ?? BaseConfig.defaultLanguage;
            } else {
                return state.language ?? state.diemaker?.primaryUser?.locale ?? BaseConfig.defaultLanguage;
            }
        },
        previewDrawingFontSize: (state: any, getters: any) => {
            return state.previewDrawingFontSize;
        },
        productLoading: state => state.productLoading,
        is3dSelected: state => state.is3dSelected,
    }
});
