import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { RequestService } from '@shared/request/request.service';
import svgPanZoom from 'svg-pan-zoom';
import { FluteDirection } from '../../enums/flute-direction.enum';
import { ProductDrawing } from '@shared/modules/products/models/product-drawing.model';
import { UtilService } from '@shared/utils/util.service';
import Icon from '@shared/components/icon/icon.component.vue';
import { SvgIcon } from '@shared/components/icon/svg-icon.enum';
import { ServerError } from '@shared/request/server-error.model';

@Component({
    inject: ['config'],
    components: {
        Icon
    }
})
export default class ProductPreviewDrawing extends Vue {

    readonly SvgIcon = SvgIcon;

    @Prop() url!: string;
    @Prop() drawing!: ProductDrawing;
    @Prop() isSmallerZoomFit?: boolean;
    @Prop({default: null}) viewSideText!: string;
    @Prop({default: true}) showSideInfo!: boolean;
    @Prop({default: null}) fluteDir!: FluteDirection;
    @Prop({default: true}) showFluteDir!: boolean;
    @Prop({default: false}) hideDimensions!: boolean;
    @Prop({default: true}) canHideStyleAnnotations!: boolean;

    hideStyleAnnotation = false;

    private visibleFluteDir: FluteDirection = FluteDirection.Unknown;
    private svgContent = '';
    private paramToSvgMapping: { [key: string]: HTMLElement[] } = {};
    private panZoom?: SvgPanZoom.Instance;
    private imageUrl: string | null = null;
    private resizeEventAdded = false;
    private readonly FluteDirection = FluteDirection;

    async mounted() {

        this.visibleFluteDir = this.drawing?.fluteDirection;

        if (UtilService.isSvg(this.url)) {
            await this.loadSvgContent();
            await this.$nextTick();

            if (!this.$refs.svgContainer) {
                return;
            }

            await this.attachPanZoomEvents();
            if (this.isSmallerZoomFit) {
                this.panZoom?.zoomOut();
            }
            this.storeMeasurementsInMap();
            this.setFontSize();
            this.showDimensionsGroupChange(this.visibleParams, []);
            UtilService.fixSvgIds(this.$refs.svgContainer as SVGElement);
        } else {
            this.imageUrl = this.url;
        }
    }

    beforeDestroy() {
        this.panZoom?.destroy();
        this.panZoom = undefined;
    }

    destroyed() {
        if (this.resizeEventAdded) {
            window.removeEventListener('resize', this.onWindowResize);
        }
    }

    get visibleParams(): string[] {
        return this.$store.getters.getDrawingVisibleParams;
    }

    get isResizePage(): boolean {
        return this.$store.getters.getIsResizePage;
    }

    get selectedFluteDirection(): FluteDirection {
        return this.$store.getters.getSelectedFluteDirection;
    }

    @Watch('url', {immediate: false})
    async urlChange() {

        if (!this.url) {
          return;
        }

        if (UtilService.isSvg(this.url)) {
            await this.loadSvgContent();
            await this.$nextTick();
            await this.attachPanZoomEvents();
            if (this.isSmallerZoomFit) {
                this.panZoom?.zoomOut();
            }
            this.storeMeasurementsInMap();
            this.setFontSize();
            this.showDimensionsGroupChange(this.visibleParams, []);
            UtilService.fixSvgIds(this.$refs.svgContainer as SVGElement);
        } else {
            this.imageUrl = this.url;
        }

    }

    @Watch('fluteDir', {immediate: false})
    @Watch('selectedFluteDirection', {immediate: false})
    selectedFluteDirectionChange(dir: FluteDirection) {
        this.visibleFluteDir = dir || this.drawing.fluteDirection;
    }

    @Watch('isResizePage', {immediate: false})
    async isResizePageChange() {
        this.panZoom?.resize();
        this.panZoom?.center();
        this.panZoom?.reset();
    }

    @Watch('visibleParams')
    showDimensionsGroupChange(values: string[], prevValues: string[]) {
        if (this.drawing && UtilService.isSvg(this.url)) {
            window.requestAnimationFrame(() => {

                for (const v of prevValues) {
                    const idx = v.lastIndexOf('.');
                    let v1 = v;
                    if (idx >= 0) {
                        v1 = v.substring(idx + 1);
                    }
                    const toHide = this.paramToSvgMapping[v1];
                    if (toHide) {
                        for (const el of toHide) {
                            el.style.display = 'none';
                        }
                    }
                }

                for (const v of values) {
                    const idx = v.lastIndexOf('.');
                    let v1 = v;
                    if (idx >= 0) {
                        v1 = v.substring(idx + 1);
                    }
                    const toShow = this.paramToSvgMapping[v1];
                    if (toShow) {
                        for (const el of toShow) {
                            el.style.display = 'block';
                        }
                    }
                }
            });
        }
    }

    toggleStyleAnnotation() {
        this.hideStyleAnnotation = !this.hideStyleAnnotation;
    }

    zoomIn() {
        this.panZoom?.zoomIn();
    }

    zoomOut() {
        this.panZoom?.zoomOut();
    }

    zoomToFit() {
        this.panZoom?.fit();
        this.panZoom?.center();
        this.panZoom?.reset();
        if (this.isSmallerZoomFit) {
            this.panZoom?.zoomOut();
        }
    }

    changeFontSize(coeff: number) {
        if (!this.hideStyleAnnotation) {
            const texts: NodeListOf<SVGTextElement> = (this.$refs.svgContainer as HTMLElement).querySelectorAll('text');
            let isSet = false;
            texts.forEach((t) => {
                const fontSize = (parseFloat(t.getAttribute('font-size') ?? '0') * coeff).toString();
                t.setAttribute('font-size', fontSize);

                if (!isSet) {
                    this.$store.commit('setPreviewDrawingFontSize', fontSize);
                    isSet = true;
                }

            });
        }
    }

    private storeMeasurementsInMap() {
        if (this.drawing && this.drawing.measlines) {
            for (const d of this.drawing.measlines) {

                if (d.dependson) {
                    for (const key of d.dependson) {
                        this.paramToSvgMapping[key] = this.paramToSvgMapping[key] || [];
                        const element: HTMLElement | null = (this.$refs.svgContainer as HTMLElement).querySelector(`[id='evml.${d.id}']`);
                        if (element) {
                            element.style.display = 'none';
                            this.paramToSvgMapping[key].push(element);
                        }
                    }
                }
            }
        }
    }

    private setFontSize() {
        const fontSize = this.$store.getters.previewDrawingFontSize;
        if (!fontSize) {
            return;
        }
        const texts: NodeListOf<SVGTextElement> = (this.$refs.svgContainer as HTMLElement).querySelectorAll('text');
        texts.forEach((t) => {
            t.setAttribute('font-size', fontSize);
        });
    }

    private async loadSvgContent() {
        try {
            this.svgContent = await RequestService.get(this.url);
        } catch (error: ServerError | any) {
            this.$emit('error', error);
        }
    }

    private onWindowResize() {
        if (this.panZoom) {
            this.panZoom.resize();
            this.zoomToFit();
        }
    }

    private async attachPanZoomEvents() {
        this.panZoom?.destroy();
        const svg = (this.$refs.svgContainer as HTMLElement).querySelector('svg') as SVGElement;
        this.panZoom = svgPanZoom(svg);

        if (!this.resizeEventAdded) {
            window.addEventListener('resize', this.onWindowResize);
            this.resizeEventAdded = true;
        }
    }
}
