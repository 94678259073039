import { RequestService } from '@shared/request/request.service';
import { BaseConfig } from '@shared/base-config';

export class UserService {

    static confirmEmail(token: string): Promise<any> {
        const data = {
            token
        };
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded'
        };
        return RequestService.post(`api/v1/user/confirm-email`, data, {apiPath: BaseConfig.apiPortal, headers});
    }

    static forgotPassword(email: string, diemakerId?: string): Promise<any> {

        const data: any = {
            username: email
        };

        if (diemakerId) {
            data.diemakerUuid = diemakerId;
        }

        return RequestService.post(`api/v1/user/forgot-password`, data, {
            apiPath: BaseConfig.apiPortal,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
    }

    static exchangePasswordToken(token: string): Promise<string> {
        return RequestService.post(`api/v1/user/exchange-password-token/${token}`, '{}', {apiPath: BaseConfig.apiPortal}).then(r => r.value);
    }

    static resetPassword(token: string, password: string): Promise<any> {
        const data = {
            newPassword: password
        };
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded'
        };
        return RequestService.post(`api/v1/user/reset-password/${token}`, data, {apiPath: BaseConfig.apiPortal, headers});
    }

    static changePassword(oldPassword: string, password: string): Promise<any> {
        const data = {
            oldPassword,
            password
        };
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded'
        };
        return RequestService.post('api/v1/user/change-password', data, {apiPath: BaseConfig.apiPortal, headers, ignoreUnauthorized: true});
    }

    static login(username: string, password: string, requestType?: 'customer' | 'diemaker'): Promise<any> {
        return RequestService.post(`api/v1/user/login`, {username, password, requestType}, {apiPath: BaseConfig.apiPortal, ignoreUnauthorized: true});
    }

    static logout(): Promise<any> {
        return RequestService.delete(`api/v1/user/logout`, {apiPath: BaseConfig.apiPortal});
    }

    static resendActivationEmail(email: string): Promise<any> {
        const data = {
            email,
            sendEmail: true
        };
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded'
        };
        return RequestService.post('api/v1/user/resend-confirm-email', data, {headers, apiPath: BaseConfig.apiPortal});
    }

    static onEmailConfirmed(): Promise<any> {
        return RequestService.stream('api/v1/user/wait-confirmed');
    }
}
