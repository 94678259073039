import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { loadLanguageAsync } from '@shared/plugins/i18n';

export interface ToggleItem {
    value: string;
    text: string;
}

@Component
export default class Toggle extends Vue {
    @Prop() items!: ToggleItem[];
    @Prop({default: ''}) value!: string;

    itemWidth = 50;

    select(item: ToggleItem) {
        this.$emit('input', item.value);
    }

    mounted() {
        this.calculateItemWidth();
        window.addEventListener('resize', this.calculateItemWidth.bind(this));
    }

    @Watch('language')
    onLangChange(lang: string) {
        this.$nextTick(() => {
            this.calculateItemWidth();
        });
    }

    get language(): string {
        return this.$store.getters['language'];
    }

    get selectedOffset() {
        return this.items.findIndex((item) => item.value === this.value) * this.itemWidth;
    }

    destroyed() {
        window.removeEventListener('resize', this.calculateItemWidth.bind(this));
    }

    private calculateItemWidth() {
        this.itemWidth = this.$el.clientWidth / this.items.length;
    }
}
