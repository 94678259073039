import Component from 'vue-class-component';
import Vue from 'vue';
import CustomInput from '@shared/components/custom-input/custom-input.component.vue';
import { UtilService } from '@shared/utils/util.service';
import { Product } from '@shared/modules/products/models/product.model';
import { DrawingType } from '@shared/modules/products/enums/drawing-type.enum';
import { ProductUrlSourceType } from '../../enums/product-url-source-type.enum';
import { Validators } from '@shared/validation/validators';

@Component({
    components: {
        CustomInput
    }
})
export default class ProductRules extends Vue {

    readonly Validators = Validators;

    get strippingRulesInfoEnabled(): boolean {
        return this.$store.getters['productCostEstimation/strippingRulesInfoEnabled'];
    }

    set strippingRulesInfoEnabled(enabled: boolean) {
        this.setDirty();
        this.$store.commit('productCostEstimation/setStrippingRulesInfoEnabled', enabled);
    }

    get strippingRulesInfoHorizontal(): string {
        return this.$store.getters['productCostEstimation/strippingRulesInfoHorizontal'];
    }

    get strippingRulesInfoVertical(): string {
        return this.$store.getters['productCostEstimation/strippingRulesInfoVertical'];
    }

    setDirty(): void {
        this.$store.commit('productCostEstimation/setDirty', true);
    }

    get currentProduct(): Product {
        return this.$store.getters.getPreviewOfCurrentProduct;
    }

    get isFile(): boolean {
        return this.$route.params.type === ProductUrlSourceType.File;
    }

    get isDieboard() {
        return this.isFile && this.$store.getters['uploadFile/type'] === DrawingType.Dieboard;
    }

    get hasSheet(): boolean {
        const layoutParams = this.$store.getters.getLayoutParams(this.currentProduct.id);
        return !UtilService.isEmptyOrNull(layoutParams.calcInfo[0].sheets[0].sheet.width);
    }

    get hasCompensatingRules(): boolean {
        return this.$store.getters['productCostEstimation/hasCompensatingRules'];
    }

    set hasCompensatingRules(hasCompensatingRules: boolean) {
        this.setDirty();
        this.$store.commit('productCostEstimation/setHasCompensatingRules', hasCompensatingRules);
    }

    setStrippingRulesInfoVertical(newValue: string): void {
        this.setDirty();
        this.$store.commit('productCostEstimation/setStrippingRulesInfoVertical', newValue);
    }
    setStrippingRulesInfoHorizontal(newValue: string): void {
        this.setDirty();
        this.$store.commit('productCostEstimation/setStrippingRulesInfoHorizontal', newValue);
    }
    validateStrippingRulesInfoForm(): boolean {
        return (this.$refs.strippingRulesInfoForm as any).validate();
    }

}
