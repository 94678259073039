export enum Routes {
    Home = 'home',
    NewRequest = 'newRequest',
    ProductRoot = 'productRoot',
    ProductList = 'productList',
    ProductFavouriteList = 'productFavouriteList',
    UploadFile = 'uploadFile',
    ProductPreview = 'productPreview',
    ProductLayout = 'productLayout',
    ProductCostEstimation = 'productCostEstimation',
    ProductSendResizeTemplate = 'productSendResizeTemplate',
    ProductOrderRequest = 'productOrderRequest',
    UserLogin = 'userLogin',
    UserRegister = 'userRegister',
    UserForgotten = 'userForgotten',
    OrderRoot = 'orderRoot',
    OrderList = 'orderList',
    OrderDetails = 'orderDetails',
    Profile = 'profile',
    CompanyDetails = 'companyDetails',
    ContactPerson = 'contactPerson',
    FlatDies = 'flatDies',
    RotaryDies = 'rotarytDies',
    MyDiemakers = 'myDiemakers',
    UserResetPassword = 'userResetPassword',
    UserConfirmEmail = 'userConfirmEmail',
    UserSetPassword = 'userSetPassword',
    UserResendActivation = 'userResendActivation',
    UserChooseDefaultDiemaker = 'userChooseDefaultDiemaker',
    HelpImportFile = 'helpImportFile',
    HelpMakingRequests = 'helpMakingRequests',
    HelpMyRequests = 'helpMyRequests',
    HelpProfile = 'helpProfile',
}
