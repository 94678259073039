import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class LoadingButton extends Vue {

    @Prop({default: 'button'}) type!: string;
    @Prop({default: false}) block!: boolean;
    @Prop({default: false}) disabled!: boolean;

    loading = false;

    onClick() {
        this.loading = true;
        this.$emit('click', () => {
            this.loading = false;
        });
    }
}
