import { Component, Prop, Vue } from 'vue-property-decorator';
import ProductPreviewDrawing from '@shared/modules/products/components/product-preview-drawing/product-preview-drawing.component.vue';
import { BaseOrder } from '@shared/modules/orders/models/base-order.model';

@Component({
    components: {
        ProductPreviewDrawing
    }
})
export default class DrawingPreviews extends Vue {
    @Prop() heightOfPreviewWindow!: number;
    @Prop() order!: BaseOrder;
}
