import { Component, Prop, Vue } from 'vue-property-decorator';

import ContentField from '@shared/components/content-field/content-field.component.vue';
import { Diemaker } from '@shared/modules/diemaker/models/diemaker.model';

@Component({
    components: {
      ContentField
    }
})
export default class DiemakerDetails extends Vue {

    @Prop() diemaker!: Diemaker;
}
