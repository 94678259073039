import { Component, Vue, Watch } from 'vue-property-decorator';
import FormErrorMessage from '@shared/components/form-error-message/form-error-message.component.vue';
import LoadingButton from '@shared/components/loading-button/loading-button.component.vue';
import CustomInput from '@shared/components/custom-input/custom-input.component.vue';
import { UserService } from '@shared/modules/users/services/user.service';

@Component({
  components: {
    FormErrorMessage,
    CustomInput,
    LoadingButton
  }
})
export default class UserForgotten extends Vue {

  email = '';
  isError = false;
  errorMessage = '';
  showSuccess = false;

  async submit(stopLoading: () => void) {
    this.isError = false;
    this.showSuccess = false;

    if (this.validate()) {
      try {

        await UserService.forgotPassword(this.email, this.$store.getters.getDiemakerContext);

        (this.$refs.form as any).reset();

        this.showSuccess = true;

      } catch (e) {
        this.isError = true;

        if (e.code !== 9) {
          this.errorMessage = `errors.${e.code}`;
        } else {
          this.errorMessage = `errors.userNotFound`;
        }

      }
    }

    stopLoading();
  }

  private validate() {
    return (this.$refs.form as any).validate();
  }
}
