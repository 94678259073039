import Vue from 'vue';

Vue.directive('select-text-onload', {
    inserted: (el: HTMLElement) => {
        const input = el.querySelector('input') as HTMLInputElement;
        setTimeout(() => {
            input.focus();
            input.select();
        }, 250);
    }
});
