import { Component, Prop, Watch } from 'vue-property-decorator';
import BaseValidationInput from '../../validation/base-validation-input.component';

@Component({
    inject: ['form']
})
export default class CustomImageInput extends BaseValidationInput {
    @Prop() label!: string;
    @Prop() name!: string;
    @Prop({default: true}) hasPreview!: boolean;
    @Prop({default: '.jpg,.jpeg,.png'}) accepts!: string;
    @Prop({default: 'customFileInput.label'}) placeholder!: string;
    @Prop({default: null}) initialImage!: string;
    @Prop({default: 'col-4'}) labelClass!: string;
    @Prop({default: 'col-8'}) inputClass!: string;
    @Prop({default: undefined}) fullHeight!: boolean;
    @Prop({default: true}) preview!: boolean;

    imgSrc: string | ArrayBuffer | null = '';
    errors: string[] = [];

    created() {
        // tslint:disable-next-line:no-unused-expression
        (this as any).form && (this as any).form.register(this);
    }

    beforeDestroy() {
        // tslint:disable-next-line:no-unused-expression
        (this as any).form && (this as any).form.unregister(this);
    }

    @Watch('initialImage', {immediate: true})
    onInitialImageChange(val: string) {
        this.imgSrc = val;
    }

    @Watch('value')
    onFileChange(value: any) {
        if (!value) {
            this.imgSrc = null;
        }
    }

    onDragAndDropChange(e: any) {
        const file = e.dataTransfer.files[0];
        if (this.validate(file)) {
            this.fileChange(file);
            this.$emit('input', file);
        }
    }

    onChange(e: any) {
        const file: any = this.$refs.fileInput;

        if (this.validate(file.files[0])) {
            this.fileChange(file.files[0]);
            this.$emit('input', file.files[0]);
        }
    }

    fileChange(file: any) {
        if (file) {

            if (this.preview) {
                const reader = new FileReader();

                reader.onload = e => {
                    if (e.target) {
                        this.imgSrc = e.target.result;
                    }
                };
                reader.readAsDataURL(file);

            }
        }
    }

    removeFile() {
        const fileInput = this.$refs.fileInput as any;
        fileInput.value = null;

        this.imgSrc = this.initialImage;
        this.$emit('input', null);
        this.$emit('delete');
    }

    validate(file?: File) {
        const value = file ?? this.value;
        const errors: string[] = [];

        for (const rule of this.validationRules) {
            const result: any | boolean = rule(value);
            if (result !== true) {
                errors.push(result as any);
            }
        }

        this.errors = errors;

        return errors.length === 0;
    }

    get hasImage(): boolean {
        return !!this.value || !!this.imgSrc;
    }

    get valid(): boolean {
        return this.hasErrors;
    }

    get hasErrors(): boolean {
        return this.errors.length > 0;
    }

/*    @Watch('value')
    valueChange() {
        this.validate();
    }*/
}
