import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ProductService } from '../../product.service';
import { Units } from '@shared/modules/diemaker/enums/units-enum';

@Component
export default class ProductToggleFavorites extends Vue {

  @Prop() libraryId!: string;
  @Prop() templateUnit!: Units;

  isFavorite = false;
  isLoading = true;

  async created() {
    this.isFavorite = await ProductService.isFavorite(this.libraryId, this.templateUnit);
    this.isLoading = false;
  }

  add() {
    if (!this.isLoading) {
      this.addFavorite();
    }
  }

  remove() {
    if (!this.isLoading) {
      this.removeFavorite();
    }
  }

  async addFavorite() {
    this.isLoading = true;
    await ProductService.addFavorite(this.libraryId, this.templateUnit);
    this.isFavorite = true;
    setTimeout(() => {
      this.isLoading = false;
    }, 300);
  }

  async removeFavorite() {
    this.isLoading = true;
    await ProductService.removeFavorite(this.libraryId, this.templateUnit);
    this.isFavorite = false;
    setTimeout(() => {
      this.isLoading = false;
    }, 300);

  }
}
