import Vue from 'vue';
import VueI18n from 'vue-i18n';
import axios from 'axios';
import { BaseConfig } from '@shared/base-config';
import { mergeDeep } from '@shared/utils/merge-deep';

Vue.use(VueI18n);

export const i18n = new VueI18n({
    // locale: Config.language, // set locale
    fallbackLocale: BaseConfig.defaultLanguage
});

const loadedLanguages: string[] = [];

function setI18nLanguage(lang: string) {
    i18n.locale = lang;
    axios.defaults.headers.common['Accept-Language'] = lang;
    document.querySelector('html')?.setAttribute('lang', lang);
    return lang;
}

export function loadLanguageAsync(lang: string) {
    if (i18n.locale === lang) {
        return Promise.resolve(setI18nLanguage(lang));
    }

    if (loadedLanguages.includes(lang)) {
        return Promise.resolve(setI18nLanguage(lang));
    }

    if (!Object.keys(BaseConfig.availableLanguages).includes(lang)) {
        lang = BaseConfig.defaultLanguage;
    }
    let languages;
    if (lang === 'en') {
        languages = [import(`@shared/locales/${lang}.json`), import(`@/locales/${lang}.json`)];
    } else {
        languages = [import(`@shared/locales/${'en'}.json`), import(`@/locales/${'en'}.json`),
            import(`@shared/locales/${lang}.json`), import(`@/locales/${lang}.json`)];
    }

    return Promise.all(languages).then(
        ([baseEn, projectEn, base, project]) => {
            if (lang === 'en') {
                i18n.setLocaleMessage(lang, {...baseEn.default, ...projectEn.default});
            } else {
                const merged = mergeDeep({}, baseEn.default, projectEn.default, base.default, project.default);
                i18n.setLocaleMessage(lang, merged);
            }

            loadedLanguages.push(lang);
            return setI18nLanguage(lang);
        }
    );
}
