import { Component, Prop } from 'vue-property-decorator';
import { Validators } from '@shared/validation/validators';
import BaseValidationInput from '@shared/validation/base-validation-input.component';
import { Units } from '../../../../diemaker/enums/units-enum';

@Component
export default class CustomEdit extends BaseValidationInput {
    handleInput(value: string) {
        this.$emit('input', value);
    }

    get valid(): boolean {
        return (this.$refs['inputField'] as any).valid;
    }

    get validationRules(): any {
        let rules = [Validators.MaxLength(this.maxLength)];

        if (this.$store.getters['templateUnit'] === Units.INCH) {
            rules.push(Validators.InchParam);
        } else {
            rules.push(Validators.Decimal);
        }

        if (this.required) {
            rules.push(Validators.Required);
        }

        if (this.rules && Array.isArray(this.rules)) {
            rules = [...rules, ...this.rules];
        }

        return rules;
    }
}
