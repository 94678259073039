import { Component, Vue, Watch } from 'vue-property-decorator';
import { FluteDirection } from '@shared/modules/products/enums/flute-direction.enum';
import { Validators } from '@shared/validation/validators';
import { ProductMaterial } from '../../../../../projects/customer/src/modules/products/models/product-material.interface';
import { ProductResizeParam } from '../../models/product-resize-param.model';
import { ProductExportFormat } from '../../../../../projects/customer/src/modules/products/models/product-export-format.interface';
import { Product } from '../../models/product.model';
import { ProductDrawing } from '@shared/modules/products/models/product-drawing.model';
import ProductResizeAdvancedParams from '@shared/modules/products/components/product-resize-params/product-resize-advanced-params/product-resize-advanced-params.component.vue';
import CustomEdit from '@shared/modules/products/components/product-resize-params/custom-edit/custom-edit.component.vue';
import ParamEditDialogComponent from '@shared/modules/products/components/product-resize-params/param-edit-dialog/param-edit-dialog.component.vue';
import { BaseConfig } from '@shared/base-config';

@Component({
    components: {
        ProductResizeAdvancedParams,
        CustomEdit,
        ParamEditDialogComponent
    }
})
export default class ProductResizeParams extends Vue {

    snackbar = false;
    snackbarText = '';
    mainParamMap: ProductResizeParam[] = [];
    formatValue = '';
    formatsArray: ProductExportFormat[] = [];
    selectedMaterial = '';
    selectedThickness: null | string = null;
    availableMaterials: any[] = [];
    availableMaterialsThicknessMap: any = {};
    resizeLoading = false;
    loadingOverlayVisible = false;
    fluteDirections: Array<{ text: string; value: FluteDirection }> = [
        {text: this.$i18n.t(`dropdowns.fluteDirections.FD_unknown`) as string, value: FluteDirection.Unknown},
        {text: this.$i18n.t(`dropdowns.fluteDirections.X`) as string, value: FluteDirection.X},
        {text: this.$i18n.t(`dropdowns.fluteDirections.Y`) as string, value: FluteDirection.Y},
    ];
    selectedFluteDirection: FluteDirection | null = null;
    grammageNote = '';
    mainParamsHeaders = [
        {
            text: this.$i18n.t('products.resize.mainParameters'),
            align: 'start',
            sortable: false,
            divider: true,
            value: 'paramName'
        },
        {text: this.$i18n.t('products.resize.value'), value: 'value', width: '300px'}
    ];
    otherParamsHeaders = [
        {
            text: this.$i18n.t('products.resize.paramName'),
            align: 'start',
            sortable: false,
            divider: true,
            value: 'paramName'
        },
        {text: this.$i18n.t('products.resize.value'), value: 'value', width: '300px'}
    ];
    dirty = true;

    readonly Validators = Validators;

    created() {

        this.getGroupsAccordingToChosenDrawing();
        this.getMainParamMap();
        // Always show the main group params/measlines
        this.$store.commit('changeDrawingVisibleParams', this.groupsArray[0].paramKeys);

        this.availableMaterials = this.currentProduct.possibleMaterials.map((m: any) => {
            this.availableMaterialsThicknessMap[m.name] = m.thickness;
            return {
                value: m.name,
                text: m.name,
                grammage: m.grammage
            };
        });

        const {name, thickness} = this.currentProduct.materialInfo;
        this.selectedMaterial = name;
        this.selectedThickness = thickness + '';

        this.selectedFluteDirection = this.chosenDrawing ? this.chosenDrawing.fluteDirection : this.currentProduct.fluteDirection;
        this.selectedFluteDirection = this.selectedFluteDirection === FluteDirection.Any ? FluteDirection.Unknown : this.selectedFluteDirection;
    }

    mounted() {
        const material = {
            name: this.selectedMaterial,
            thickness: parseFloat(this.selectedThickness as string)
        } as ProductMaterial;

        const paramMap = this.generateParamsMap();

        if (typeof this.$store.getters.getResizeParams === 'function' && !this.$store.getters.getResizeParams(this.currentProduct.id)) {
            this.$store.commit('saveResizeParams', {
                id: this.currentProduct.id,
                drawingId: this.chosenDrawing?.evDrawingId,
                params: {
                    paramMap,
                    material,
                    fluteDir: this.selectedFluteDirection,
                    initial: true
                }
            });
        } else {
            this.dirty = false;
            this.$emit('update:dirty', this.dirty);
            this.initFields();
        }
    }

    destroyed() {
        this.$store.commit('changeDrawingVisibleParams', []);
        this.$store.commit('changeIsResizePage', false);
        if (BaseConfig.projectType === 'customer') {
            this.$store.commit('changeSelectedFluteDirection', null);
        }
    }

    getGroupsAccordingToChosenDrawing() {
        for (let i = 0; i < this.drawInfos.length; i++) {
            if (this.chosenDrawing === this.drawInfos[i].drawingName) {
                this.$store.commit('changeGroupsArray', this.drawInfos[i]);
                break;
            }
        }
    }

    getMainParamMap() {
        this.mainParamMap = [];
        for (
            let i = 0;
            this.mainParametersArray && i < this.mainParametersArray.length;
            i++
        ) {
            this.mainParamMap.push(
                new ProductResizeParam(this.currentProduct.params.paramMap[this.mainParametersArray[i]], this.mainParametersArray[i])
            );
        }
    }

    async doResize() {
        this.resizeLoading = true;

        try {

            const material = {
                name: this.selectedMaterial,
                thickness: parseFloat(this.selectedThickness as string)
            } as ProductMaterial;

            const paramMap = this.generateParamsMap();

            await this.$store.dispatch('resize', {
                productId: this.currentProduct.id,
                paramMap: {...paramMap},
                material,
                selectedFluteDirection: this.selectedFluteDirection,
                grammageNote: this.grammageNote
            });

            this.dirty = false;
            this.$emit('update:dirty', this.dirty);

        } catch (e) {
            this.snackbarText = '';
            this.snackbarText =
                'code:' +
                e.code + ' ' +
                e.message;
            this.snackbar = true;
        }

        this.resizeLoading = false;
    }

    setDirty() {
        this.dirty = true;
        this.$emit('update:dirty', this.dirty);
    }

    @Watch('chosenDrawing')
    chosenDrawingChange(drawing: ProductDrawing) {
        this.selectedFluteDirection = drawing ? drawing.fluteDirection : this.currentProduct.fluteDirection;
        this.selectedFluteDirection = this.selectedFluteDirection === FluteDirection.Any ? FluteDirection.Unknown : this.selectedFluteDirection;
        this.getMainParamMap();

        if (drawing && BaseConfig.projectType === 'customer') {
            this.$store.commit('saveResizeParamsDrawingId', {
                id: this.currentProduct.id,
                drawingId: drawing.evDrawingId
            });
        }
    }

    @Watch('selectedFluteDirection')
    selectedFluteDirectionChange(dir: FluteDirection) {
        if (BaseConfig.projectType === 'customer') {
            this.$store.commit('changeSelectedFluteDirection', dir);
        }
    }

    @Watch('selectedMaterial')
    selectedMaterialChange(material: string) {
        this.selectedThickness = this.availableMaterialsThicknessMap[material];

        const _material: any = this.availableMaterials.find(((m: any) => m.value === material));
        this.grammageNote = _material?.grammage ?? '';
    }

    selectedThicknessChange() {
        if (!this.selectedThickness?.toString().trim().length) {
            this.selectedThickness = this.availableMaterialsThicknessMap[this.selectedMaterial];
        }
    }

    get chosenDrawing(): ProductDrawing {
        return this.$store.getters.getChosenDrawing;
    }

    get currentProduct(): Product {
        return this.$store.getters.getPreviewOfCurrentProduct;
    }

    get drawInfos(): any {
        return this.currentProduct.params.drawInfos;
    }

    get mainParametersArray(): any {
        return this.$store.getters.getGroupsArray.groups[0].paramKeys;
    }

    get groupsArray() {
        return this.$store.getters.getGroupsArray.groups;
    }

    private generateParamsMap() {
        const paramMap = this.currentProduct.params.paramMap;

        for (const resizeParam of this.mainParamMap) {
            const param: { expression: string } = paramMap[resizeParam.key];
            if (param && resizeParam.originalValue !== resizeParam.value) {
                param.expression = resizeParam.value.toString();
            }
        }
        for (const resizeParam of (this.$refs.advancedParams as any).othersParamMap) {
            const param: { expression: string } = paramMap[resizeParam.key];
            if (param && resizeParam.originalValue !== resizeParam.value) {
                param.expression = resizeParam.value.toString();
            }
        }

        return paramMap;
    }

    private initFields() {
        const resizeParams = this.$store.getters.getResizeParams(this.currentProduct.id);
        if (this.$store.getters.getResizeParams(this.currentProduct.id)) {
            this.selectedMaterial = resizeParams.material.name;
            this.selectedFluteDirection = resizeParams.fluteDir;

            this.$nextTick(() => {
                this.grammageNote = resizeParams.grammageNote;
                this.selectedThickness = resizeParams.material.thickness;
            });

        }
    }

}
