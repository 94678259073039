import { Component, Prop, Vue } from 'vue-property-decorator';
import ProductPreviewDrawing from '@shared/modules/products/components/product-preview-drawing/product-preview-drawing.component.vue';
import ProductSection from '@shared/modules/products/components/product-section/product-section.component.vue';
import { BreadcrumbHistoryItem } from '@shared/components/breadcrumbs/breadcrumb-history-item.interface';
import Breadcrumbs from '@shared/components/breadcrumbs/breadcrumbs.component.vue';
import { BaseOrder } from '@shared/modules/orders/models/base-order.model';
import LoadingButton from '@shared/components/loading-button/loading-button.component.vue';
import { Validators } from '@shared/validation/validators';
import DrawingPreviews from '@shared/modules/orders/base-order-details/components/drawing-previews/drawing-previews.component.vue';
import OrderQa from '@shared/modules/orders/base-order-details/components/order-qa/order-qa.component.vue';
import MaterialsInfo from '@shared/modules/orders/base-order-details/components/materials-info/materials-info.component.vue';
import RulesInfo from '@shared/modules/orders/base-order-details/components/rules-info/rules-info.component.vue';
import Dialogs from '@shared/modules/orders/base-order-details/components/dialogs/dialogs.component.vue';
import DiecutterInfo from '@shared/modules/orders/base-order-details/components/diecutter-info/diecutter-info.component.vue';
import DieOptions from '@shared/modules/orders/base-order-details/components/die-options/die-options.component.vue';
import { DiemakerOrder } from '../../../../projects/diemaker/src/modules/orders/models/diemaker-order.model';
import { Customer } from '../../../../projects/diemaker/src/modules/customers/models/customer.model';

@Component({
    components: {
        ProductPreviewDrawing,
        ProductSection,
        Breadcrumbs,
        LoadingButton,
        DieOptions,
        DrawingPreviews,
        OrderQa,
        DiecutterInfo,
        MaterialsInfo,
        RulesInfo,
        Dialogs
    }
})
export default class BaseOrderDetails extends Vue {

    @Prop() order!: BaseOrder;
    @Prop() breadcrumbs!: BreadcrumbHistoryItem[];
    @Prop() customer!: Customer;

    heightOfPreviewWindow = 0;

    readonly Validators = Validators;

    mounted(): void {
        this.handlePreviewWindowResize();
        window.addEventListener('resize', this.handlePreviewWindowResize);
    }

    handlePreviewWindowResize(): void {
        this.heightOfPreviewWindow = window.innerHeight - 224;
    }

    destroyed(): void {
        window.removeEventListener('resize', this.handlePreviewWindowResize);
    }

    declineOrder(args: any) {
            this.$emit('decline-order', args);
    }

    acceptOrder(args: any) {
        this.$emit('accept-order', args);
    }

}
