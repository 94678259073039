import { Component, Vue, Watch } from 'vue-property-decorator';
import { ConfirmModalData } from '@shared/components/confirm-modal/confirm-modal-data.model';
import { confirmModuleNamespace } from './confirm-modal.store';

@Component
export default class ConfirmModal extends Vue {

  show = false;
  title = '';
  text = '';
  onConfirm: null | (() => void) = null;
  onCancel: null | (() => void) = null;
  confirmButton = '';

  created() {
    this.$store.subscribe(async (mutation, state) => {
      if (mutation.type === `${confirmModuleNamespace}/setData`) {
        const data = state[confirmModuleNamespace].data as ConfirmModalData;
        if (data) {
          this.confirmButton = data.confirmButton;
          this.title = data.title || this.$i18n.t('confirmModal.title') as string;
          this.text = data.text;
          this.onConfirm = data.onConfirm;
          this.onCancel = data.onCancel as any;
          this.show = true;
        } else {
          this.show = false;

          setTimeout(() => {
            this.text = '';
            this.confirmButton = '';
            this.title = '';
            this.onConfirm = null;
            this.onCancel = null;
          }, 1);
        }
      }
    });
  }

  confirm() {
    if (typeof this.onConfirm === 'function') {
      this.onConfirm.call(null);
    }

    this.show = false;
  }

  cancel() {
    if (typeof this.onCancel === 'function') {
      this.onCancel.call(null);
    }

    this.show = false;
  }

  @Watch('show')
  onShowChange(show: boolean) {
    if (!show) {
      this.$store.commit(`${confirmModuleNamespace}/setData`, null);
    }
  }
}
