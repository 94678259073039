import Component from 'vue-class-component';
import ProductSection from '@shared/modules/products/components/product-section/product-section.component.vue';
import Vue from 'vue';

@Component({
    components: {
        ProductSection
    }
})

export default class DieOptionsSummary extends Vue {

    get hasMaleStrippingDie(): boolean {
        return this.$store.getters['productCostEstimation/hasMaleStrippingDie'];
    }

    get hasFemaleStrippingDie(): boolean {
        return this.$store.getters['productCostEstimation/hasFemaleStrippingDie'];
    }

    get hasFrontStripper(): boolean {
        return this.$store.getters['productCostEstimation/hasFrontStrippingDie'];
    }
}
