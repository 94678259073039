import Header from '@/core/header/header.component.vue';
import Main from '@/core/main/main.component.vue';
import Footer from '@shared/components/footer/footer.component.vue';
import Notification from '@shared/components/notification/notification.component.vue';
import '@shared/directives/select-text-onload.directive';
import { Config } from './config';
import { Routes } from './routes.enum';
import ConfirmModal from '@shared/components/confirm-modal/confirm-modal.component.vue';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { UtilService } from '@shared/utils/util.service';
import { ThemeBorderShadowType } from '@shared/enums/theme-border-shadow-type.enum';
import { loadLanguageAsync } from '@shared/plugins/i18n';

@Component({
    provide() {
        return {
            config: Config
        };
    },
    components: {
        Header,
        Main,
        Footer,
        Notification,
        ConfirmModal
    }
})
export default class App extends Vue {

    loadingOverlayVisible = false;
    $routeWatcher: any = null;

    created() {

        this.$store.subscribe((mutation, state) => {
            if (mutation.type === 'auth/logout') {
                this.$store.commit('setUserDefaultDiemaker', null);

                const {status, code} = mutation.payload;

                if (!status) {
                    this.$store.commit('setRegisterCostEstimationCalled', false);

                    if (!this.currentContext) {
                        this.$store.commit('setDiemaker', null);
                    }
                }

                const url = location.hash.replace('#', '');
                if (url !== '/' && !url.includes('diemakerid')) {
                    if (status === 401) {
                        this.$store.commit('clearResizeParams');
                        this.$store.commit('clearLayoutParams');
                        this.$store.commit('clearCostEstimationParams');
                        this.$store.commit('productCostEstimation/reset');
                        this.$store.commit('productLayout/reset');
                        this.$store.commit('productMakeOrder/reset');
                        this.$store.commit('clearOrderRequestParams');
                        this.$store.commit('changePreviewOfCurrentProduct', {});
                        this.$router.push({
                            name: Routes.UserLogin,
                            query: {redirectTo: location.hash.replace('#', '')}
                        });
                    } else if (status === 403) {
                        this.$store.dispatch('notification/showError', {text: this.$i18n.t(`errors.${code}`), timeout: 0});
                    } else {
                        this.$router.push({name: Routes.UserLogin});
                    }
                }
            }
        });
    }

    get styles() {
        if (this.diemaker && this.diemaker.theme) {
            const styles = {
                '--header-bg-color': this.diemaker.theme?.headerFooterBkgColor || '#1B2958',
                '--header-text-color': this.diemaker.theme?.headerFooterTextColor || '#ffffff',
                '--button-bg-color': this.diemaker.theme?.buttonBkgColor || '#4494e5',
                '--button-text-color': this.diemaker.theme?.buttonTextColor || '#ffffff',
                '--link-color': this.diemaker.theme?.linkColor || '#4494e5',
                '--header-nav-hover-bg': this.diemaker.theme?.headerFooterBkgColor ?
                    UtilService.lightenDarkenColor(this.diemaker.theme.headerFooterBkgColor, 20) : '#2A418C',
                '--header-border-bottom-color': 'transparent',
                '--header-box-shadow': 'none'
            };

            if (this.diemaker?.theme?.borderShadowType === ThemeBorderShadowType.Border) {
                styles['--header-border-bottom-color'] = this.diemaker?.theme.borderShadowColor;
            } else if (this.diemaker?.theme?.borderShadowType === ThemeBorderShadowType.Shadow) {
                styles['--header-box-shadow'] = `0 5px 10px ${this.diemaker?.theme.borderShadowColor}`;
            }

            return styles;
        } else {
            return {
                '--header-bg-color': '#1B2958',
                '--header-text-color': '#ffffff',
                '--button-bg-color': '#4494e5',
                '--button-text-color': '#ffffff',
                '--link-color': '#4494e5',
                '--header-nav-hover-bg': '#2A418C',
                '--header-border-bottom-color': 'transparent',
                '--header-box-shadow': 'none'
            };
        }
    }

    get diemaker() {
        return this.$store.getters.diemaker;
    }

    get currentContext() {
        return this.$store.getters.getDiemakerContext;
    }

    get language(): string {
        return this.$store.getters['language'];
    }

    get isWorkAs(): boolean {
        return this.$store.getters['auth/isAuthenticated'] && this.$store.getters['auth/customerWorkAsId'];
    }

    @Watch('language')
    onLangChange(lang: string) {
        loadLanguageAsync(lang);
    }
}
