import { render, staticRenderFns } from "./product-preview-drawing-thumbnail.component.vue?vue&type=template&id=437f13e8&"
import script from "./product-preview-drawing-thumbnail.component.ts?vue&type=script&lang=ts&"
export * from "./product-preview-drawing-thumbnail.component.ts?vue&type=script&lang=ts&"
import style0 from "./product-preview-drawing-thumbnail.component.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VImg})
