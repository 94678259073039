import CustomEdit from '@shared/modules/products/components/product-resize-params/custom-edit/custom-edit.component';
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';

@Component
export default class ParamEditDialogComponent extends Vue {
    @Prop() returnValue!: any;

    dialogOpen = false;
    inputElement: CustomEdit | null = null;
    initialReturnValue = '';

    openDialog() {
        this.dialogOpen = true;

        this.$nextTick(() => {
            this.setInputElement();
            this.initialReturnValue = this.returnValue;
        });
    }

    cancel() {
        this.$emit('update:return-value', this.initialReturnValue);
        this.initialReturnValue = '';
        this.closeDialog();
    }

    closeDialog() {
        this.dialogOpen = false;
    }

    save() {
        this.$emit('save');
        this.closeDialog();
    }

    get isValid() {
        return this.inputElement?.valid;
    }

    private setInputElement() {
        if (this.$slots.input && this.$slots.input[0]) {
            this.inputElement = this.$slots.input[0].componentInstance as CustomEdit;
        }
    }
}
