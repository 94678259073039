import { render, staticRenderFns } from "./contact-person.component.vue?vue&type=template&id=3dd35bc2&scoped=true&"
import script from "./contact-person.component.ts?vue&type=script&lang=ts&"
export * from "./contact-person.component.ts?vue&type=script&lang=ts&"
import style0 from "./contact-person.component.scss?vue&type=style&index=0&id=3dd35bc2&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3dd35bc2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCol,VDialog,VForm,VRow})
