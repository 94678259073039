import { render, staticRenderFns } from "./main.component.vue?vue&type=template&id=89b80a8e&scoped=true&"
import script from "./main.component.ts?vue&type=script&lang=ts&"
export * from "./main.component.ts?vue&type=script&lang=ts&"
import style0 from "./main.component.scss?vue&type=style&index=0&id=89b80a8e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89b80a8e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VMain } from 'vuetify/lib/components/VMain';
installComponents(component, {VFadeTransition,VMain})
