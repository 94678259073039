import { render, staticRenderFns } from "./product-rules.component.vue?vue&type=template&id=b8500e2c&"
import script from "./product-rules.component.ts?vue&type=script&lang=ts&"
export * from "./product-rules.component.ts?vue&type=script&lang=ts&"
import style0 from "./product-rules.component.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VForm } from 'vuetify/lib/components/VForm';
installComponents(component, {VCheckbox,VForm})
