import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Product } from '@shared/modules/products/models/product.model';
import ProductPreviewDrawing from '@shared/modules/products/components/product-preview-drawing/product-preview-drawing.component.vue';
import { DrawingThumbnailItem } from '@shared/modules/products/components/drawing-thumbnail-list/drawing-thumbnail-item.interface';

@Component({
  components: {
    ProductPreviewDrawing
  }
})
export default class DrawingSelectedThumbnail extends Vue {
  @Prop() currentProduct!: Product;
  @Prop() heightOfPreviewWindow!: number;
  @Prop() showResize!: boolean;
  @Prop() selectedThumbnail!: DrawingThumbnailItem;
}
