import Vue from 'vue';

const navigationKeys = [
    'Backspace',
    'Delete',
    'Tab',
    'Escape',
    'Enter',
    'Home',
    'End',
    'ArrowLeft',
    'ArrowRight',
    'Clear',
    'Copy',
    'Paste'
];

function isValidDecimal(string: string): boolean {
    return string.split('.').length <= 2;
}

Vue.directive('numeric', {
    inserted: (el: HTMLInputElement | any, binding: any) => {
        const {positive, decimal} = binding.value;
        let decimalCounter = 0;

        el.addEventListener('keydown', (e: KeyboardEvent) => {
            if (
                navigationKeys.indexOf(e.key) > -1 || // Allow: navigation keys: backspace, delete, arrows etc.
                (e.key === 'a' && e.ctrlKey === true) || // Allow: Ctrl+A
                (e.key === 'c' && e.ctrlKey === true) || // Allow: Ctrl+C
                (e.key === 'v' && e.ctrlKey === true) || // Allow: Ctrl+V
                (e.key === 'x' && e.ctrlKey === true) || // Allow: Ctrl+X
                (e.key === 'a' && e.metaKey === true) || // Allow: Cmd+A (Mac)
                (e.key === 'c' && e.metaKey === true) || // Allow: Cmd+C (Mac)
                (e.key === 'v' && e.metaKey === true) || // Allow: Cmd+V (Mac)
                (e.key === 'x' && e.metaKey === true) || // Allow: Cmd+X (Mac)
                (decimal && e.key === '.' && decimalCounter < 1) || // Allow: only one decimal point
                (!positive && e.key === '-')
            ) {
                // let it happen, don't do anything
                return;
            }
            // Ensure that it is a number and stop the keypress
            if (e.key === ' ' || isNaN(Number(e.key))) {
                e.preventDefault();
            }
        });
        el.addEventListener('keyup', (event: any) => {
            if (!decimal) {
                return;
            } else {
                decimalCounter = event.target?.value.split('.').length - 1;
            }
        });

        el.addEventListener('paste', (event: ClipboardEvent) => {
            const pastedInput = event.clipboardData?.getData('text/plain');
            let pasted = false;

            if (pastedInput) {
                if (!decimal) {
                    pasted = document.execCommand(
                        'insertText',
                        false,
                        pastedInput.replace(/[^0-9]/g, '')
                    );
                } else if (isValidDecimal(pastedInput)) {
                    pasted = document.execCommand(
                        'insertText',
                        false,
                        pastedInput.replace(/[^0-9.]/g, '')
                    );
                }
                if (pasted) {
                    event.preventDefault();
                } else {
                    if (navigator.clipboard) {
                        navigator.clipboard.writeText(pastedInput);
                        document.execCommand('paste');
                    }
                }
            }
        });

        el.addEventListener('drop', (event: DragEvent) => {
            const textData = event.dataTransfer?.getData('text');
            el.focus();

            if (textData) {
                let pasted = false;
                if (!decimal) {
                    pasted = document.execCommand(
                        'insertText',
                        false,
                        textData.replace(/[^0-9]/g, '')
                    );
                } else if (isValidDecimal(textData)) {
                    pasted = document.execCommand(
                        'insertText',
                        false,
                        textData.replace(/[^0-9.]/g, '')
                    );
                }
                if (pasted) {
                    event.preventDefault();
                } else {
                    if (navigator.clipboard) {
                        navigator.clipboard.writeText(textData);
                        document.execCommand('paste');
                    }
                }
            }

        });

    }
});
