import { Component, Inject, Prop, Vue, Watch } from 'vue-property-decorator';
import { BreadcrumbHistoryItem } from './breadcrumb-history-item.interface';

@Component
export default class Breadcrumbs extends Vue {

  @Prop() prevPage?: any;
  @Prop({default: false}) customBreadcrumbs!: boolean;

  @Prop({
    default: () => {
      return [
        {
          text: 'Home',
          disabled: false,
          to: {
            path: '/'
          }
        }
      ];
    }
  })
  items!: BreadcrumbHistoryItem[];

  mounted() {
    if (this.prevPage) {
      this.items[0].to = this.prevPage;
    }
  }

  get currentProduct(): string {
    return this.$store.getters.getPreviewOfCurrentProduct?.name;
  }

  @Watch('prevPage')
  onPrevPageChange(prevPage: any) {
    if (prevPage) {
      this.items[0].to = prevPage;
    }
  }
}
