import { Component, Vue } from 'vue-property-decorator';

import CustomImageInput from '@shared/components/custom-image-input/custom-image-input.component.vue';
import LoadingButton from '@shared/components/loading-button/loading-button.component.vue';
import { Company } from '../../models/company.model';
import CustomInput from '@shared/components/custom-input/custom-input.component.vue';
import { CompanyService } from '../../services/company.service';
import { Validators } from '@shared/validation/validators';

@Component({
    components: {
        CustomInput,
        CustomImageInput,
        LoadingButton
    }
})
export default class CompanyDetails extends Vue {
    readonly Validators = Validators;

    file: File | null = null;
    companyName = '';
    website = '';

    company: Company | null = null;

    async created() {
        this.company = await CompanyService.getDetails();
        this.companyName = this.company.name || '';
        this.website = this.company.website || '';
    }

    async updateDetails(stopLoading: FunctionConstructor) {
        if (this.validate()) {

            const company = this.company as Company;

            company.name = this.companyName;
            company.website = this.website;
            company.logo = this.file as File;

            await this.$store.dispatch('updateCompanyDetails', company);
            stopLoading();
        } else {
            stopLoading();
        }
    }

    private validate(): boolean {
        return (this.$refs.form as any).validate();
    }
}
