import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import CustomerTableDefaultIndicator from '@shared/modules/products/components/customer-table-default-indicator/customer-table-default-indicator.component.vue';
import { DiecutterTableItem } from '../../../../../projects/customer/src/modules/profile/models/diecutter-table-item.model';
import DiecutterModal from '@shared/modules/products/components/customer-diecutters/diecutter-modal/diecutter-modal.component.vue';
import { confirmModalShowAction } from '@shared/components/confirm-modal/confirm-modal.store';
import { ConfirmModalData } from '@shared/components/confirm-modal/confirm-modal-data.model';
import { Units } from '../../../diemaker/enums/units-enum';

@Component({
    components: {
        CustomerTableDefaultIndicator,
        DiecutterModal
    }
})
export default class CustomerDiecutters extends Vue {
    @Prop({default: []}) data!: DiecutterTableItem[];
    @Prop({default: false}) rotary!: boolean;
    @Prop() unit!: Units;

    showModal = false;
    selectedDiecutter: DiecutterTableItem | null = null;

    readonly headers = [
        {text: this.$i18n.t('dieCutters.list.myDiecutters'), align: 'start', value: 'selectedForCustomer', width: '50px'},
        {text: this.$i18n.t('dieCutters.list.default'), align: 'start', value: 'default', width: '50px'},
        {text: this.$i18n.t('dieCutters.list.name'), value: 'name'},
        {text: this.$i18n.t('dieCutters.list.action'), value: 'actions'},
    ];

    setSelected(item: DiecutterTableItem) {
        this.$emit('selectedChange', item);
    }

    changeStatus(item: DiecutterTableItem) {
        this.$emit('statusChange', item);
    }

    saved() {
        this.$emit('saved');
    }

    setDefault(item: DiecutterTableItem) {
        item.default = !item.default;
        this.$emit('defaultChange', item);
    }

    editDiecutter(item: DiecutterTableItem) {
        this.selectedDiecutter = item;
        this.showModal = true;
    }

    closeModal() {
        this.showModal = false;
        this.selectedDiecutter = null;
    }

    deleteDiecutter(item: DiecutterTableItem) {
        this.$store.dispatch(confirmModalShowAction, {
            confirmButton: this.$i18n.t('buttons.delete'),
            onConfirm: () => {
                this.$emit('delete', item);
            }
        } as ConfirmModalData);
    }

}
