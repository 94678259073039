import { i18n } from '@shared/plugins/i18n';

export class OrderListItem {

    createdDate: string;
    id: string;
    title: string;
    estimatedCost: string;
    uuid: string;
    diemakerName: string;

    constructor({created, title, uuid, diemakerName, name, totalCost}: any = {}) {
        this.uuid = uuid;
        this.createdDate = created;
        this.id = name;
        this.title = title || i18n.t('baseOrder.noName');
        this.diemakerName = diemakerName;
        this.estimatedCost = totalCost?.toFixed(2);
    }
}
