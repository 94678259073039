import { Config } from '../../config';
import { OrderListItem } from './models/order-list-item.model';
import { RequestService } from '@shared/request/request.service';
import { CustomerOrder } from './models/customer-order.model';

export class OrderService {
    getOrders(page: number, itemsPerPage: number, params: any): Promise<{orders: OrderListItem[]; totalCount: number, pageSize: number, startPage: number }> {
        return RequestService.get(`api/v1/customer/orders/${page - 1}/${itemsPerPage}`, params, {apiPath: Config.apiPortal})
            .then(resp => {
                return {
                    orders: resp.orders.map(((p: any) => new OrderListItem(p))),
                    totalCount: resp.totalSize,
                    pageSize: resp.pageSize,
                    startPage: resp.startPage
                };
            });
    }

    getOrder(id: string): Promise<CustomerOrder> {
        return RequestService.get(`api/v1/order/${id}`, null, {apiPath: Config.apiPortal})
            .then(resp => new CustomerOrder(resp));
    }
}
