import { render, staticRenderFns } from "./tos.component.vue?vue&type=template&id=521d7980&scoped=true&"
import script from "./tos.component.ts?vue&type=script&lang=ts&"
export * from "./tos.component.ts?vue&type=script&lang=ts&"
import style0 from "./tos.component.scss?vue&type=style&index=0&id=521d7980&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "521d7980",
  null
  
)

export default component.exports