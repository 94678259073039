import { BaseOrder } from '@shared/modules/orders/models/base-order.model';
import { Diemaker } from '@shared/modules/diemaker/models/diemaker.model';

export class CustomerOrder extends BaseOrder {
    diemaker: any;

    constructor({diemaker, ...params}: any) {
        super(params);

        this.diemaker = new Diemaker(diemaker);
    }
}
